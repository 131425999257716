import React from "react";
import { Card, Badge } from "react-bootstrap";

const UserProfile = ({ user }) => {
  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title>Profil de {user.name}</Card.Title>
        <Card.Text>
          <strong>Score:</strong> {user.score} <br />
          <strong>Niveau:</strong> {user.level} <br />
          <strong>Badges:</strong>{" "}
          {user.badges.map((badge, index) => (
            <Badge key={index} bg="secondary" className="me-1">
              {badge}
            </Badge>
          ))}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default UserProfile;