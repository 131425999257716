import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import LandingNavbar from "./LandingNavbar";
import toast, { Toaster } from "react-hot-toast";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import Chat from "./Chat";
import PronosticForm from "./Battle/PronosticForm";
import BattleLeaderboard from "./Battle/BattleLeaderboard";
import BattleChallenge from "./Battle/BattleChallenge";
import UserProfile from "./Battle/UserProfile";
import HeroSection from "./HeroSection";
import UpdateBanner from "./UpdateBanner";

const LandingPage = ({ onAnalyticsClick }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const bannerImages = [
    "/images/image1.jpg",
    "/images/image2.jpg",
    "/images/image3.jpg",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % bannerImages.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ticketData, setTicketData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [subscriptionData, setSubscriptionData] = useState({
    subscribeName: "",
    subscribeEmail: "",
  });

  const handleTicketChange = (e) => {
    const { name, value } = e.target;
    setTicketData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubscriptionChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionData((prev) => ({ ...prev, [name]: value }));
  };

  const showToast = (message, type = "success") => {
    toast[type](message, {
      duration: 5000,
      position: "top-right",
    });
  };

  const handleTicketSubmit = async (e) => {
    e?.preventDefault(); // Empêche la soumission traditionnelle du formulaire

    if (isSubmitting) return;

    if (!ticketData.name || !ticketData.email || !ticketData.message) {
      showToast("Veuillez remplir tous les champs", "error");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://matchsynce.com:5001/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "ticket",
          name: ticketData.name,
          email: ticketData.email,
          message: ticketData.message,
        }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      await response.json();

      showToast("Message envoyé avec succès!", "success");

      setTimeout(() => {
        window.location.reload(true);
      }, 5000);

      setTicketData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Erreur:", error);
      showToast("Erreur lors de l'envoi du message", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubscriptionSubmit = async (e) => {
    e?.preventDefault();

    if (isSubmitting) return;

    if (!subscriptionData.subscribeName || !subscriptionData.subscribeEmail) {
      showToast("Veuillez remplir tous les champs", "error");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://matchsynce.com:5001/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "subscription",
          name: subscriptionData.subscribeName,
          email: subscriptionData.subscribeEmail,
        }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      await response.json();
      showToast("Inscription réussie!", "success");

      setTimeout(() => {
        window.location.reload(true);
      }, 5000);

      setSubscriptionData({
        subscribeName: "",
        subscribeEmail: "",
      });
    } catch (error) {
      console.error("Erreur:", error);
      showToast("Erreur lors de l'inscription", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFeedbackSubmit = async (e) => {
    e?.preventDefault();

    if (isSubmitting) return;

    if (!feedbackData.name || !feedbackData.email || !feedbackData.message) {
      showToast("Veuillez remplir tous les champs", "error");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://matchsynce.com:5001/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "feedback",
          name: feedbackData.name,
          email: feedbackData.email,
          message: feedbackData.message,
        }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      await response.json();

      // Afficher le toast et attendre 5 secondes avant le rafraîchissement
      await new Promise((resolve) => {
        showToast("Merci pour ton avis !", "success");
        setTimeout(resolve, 5000);
      });

      window.location.reload(true);

      setFeedbackData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Erreur:", error);
      showToast("Erreur lors de l'envoi du feedback", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const benefitsData = [
    {
      title: "Décisions Éclairées",
      description:
        "Prenez des décisions basées sur des données concrètes plutôt que sur l'intuition seule",
      image: "/images/1.webp",
      icon: "📊",
    },
    {
      title: "Analyse Approfondie",
      description:
        "Comprenez les tendances historiques et les patterns de performance des équipes",
      image: "/images/2.webp",
      icon: "🔍",
    },
    {
      title: "Gestion des Risques",
      description:
        "Optimisez vos choix grâce à une évaluation précise des probabilités",
      image: "/images/3.webp",
      icon: "⚖️",
    },
  ];

  const messagingFeatures = [
    {
      title: "Chat Général",
      description: "Échangez avec toute la communauté dans un espace commun",
      details: [
        { text: "Discussions en temps réel avec tous les membres", icon: "🗣️" },
        { text: "Partage d'analyses et de stratégies", icon: "📊" },
        { text: "Historique des conversations accessible", icon: "📜" },
      ],
      icon: "💬",
    },
    {
      title: "Messages Privés",
      description: "Communiquez en privé avec d'autres membres",
      details: [
        { text: "Conversations confidentielles", icon: "🔒" },
        { text: "Échange de conseils stratégiques", icon: "💡" },
        { text: "Messages instantanés", icon: "⚡" },
        { text: "Historique des conversations accessible", icon: "📝" },
      ],
      icon: "✉️",
    },
    {
      title: "Fonctionnalités Live",
      description: "Restez connecté en temps réel",
      details: [
        { text: "Indicateurs de présence en ligne", icon: "🟢" },
        { text: "Messages vocaux et notes audio", icon: "🎤" },
        {
          text: "Partage d'images, de captures d'écran et de vidéo",
          icon: "📸",
        },
        { text: "Collection d'emoji sportifs et autres", icon: "🎯" },
      ],
      icon: "🔴",
    },
  ];

  const [showChat, setShowChat] = useState(false);
  const [showBattle, setShowBattle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChatOpen = () => {
    setShowChat(true);
    setIsLoading(true);
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleChatClose = () => {
    setShowChat(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  //Battle

  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    id: 1,
    name: "John Doe",
    score: 100,
    level: 5,
    badges: ["Champion"],
  });

  useEffect(() => {
    // Simuler des données de matchs et utilisateurs
    setMatches([
      { id: 1, home_team: "Team A", away_team: "Team B", date: "2023-10-15" },
      { id: 2, home_team: "Team C", away_team: "Team D", date: "2023-10-16" },
    ]);
    setUsers([
      { id: 1, name: "John Doe", score: 100, level: 5 },
      { id: 2, name: "Jane Doe", score: 80, level: 4 },
    ]);
  }, []);

  const handleSubmitPronostic = (pronostic) => {
    console.log("Pronostic soumis:", pronostic);
  };

  const handleChallenge = (userId) => {
    console.log("Défi lancé contre l'utilisateur:", userId);
  };

  // Votre fonction de gestion du clic
  const handleAnalyticsClick = () => {
    // Votre logique ici
  };

  const handleBattleClose = () => {
    setShowBattle(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  const handleSubmitPronostics = (predictions) => {
    console.log("Pronostics soumis:", predictions);
  };

  return (
    <div className="overflow-x-hidden">
      <LandingNavbar onAnalyticsClick={onAnalyticsClick} />
      <Toaster />
      {/* Hero Section with Carousel */}
      {/* <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active" style={{ minHeight: "100vh" }}>
            <div
              style={{
                backgroundImage: `url(${bannerImages[currentSlide]})`,
                minHeight: "100vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div
                className="position-absolute top-0 start-0 w-100 h-100 bg-dark"
                style={{ opacity: 0.5 }}
              ></div>
              <div className="position-absolute w-100 top-50 start-50 translate-middle text-center text-white px-3">
                
                <h1 className="display-6 fw-bold mb-4">
                  Prédictions de Football avec MatchSynce
                </h1>
                <p className="lead mb-4">
                  Analyses statistiques avancées et prédictions précises pour
                  vos paris sportifs
                </p>
                <button
                  className="btn btn-lg btn-success w-sm-75 py-2"
                  onClick={onAnalyticsClick}
                >
                  Maximisez vos chances
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <HeroSection
          bannerImages={bannerImages}
          currentSlide={currentSlide}
          onAnalyticsClick={onAnalyticsClick}
        />
      </div>

      {/* About MatchSynce Section */}
      <section id="about" className="py-5">
        <div className="container">
          <h2 className="text-center mb-5 px-3">Découvrez MatchSynce</h2>
          <div className="row align-items-center gy-4">
            <div className="col-lg-6 card-hover-zoom">
              <img
                src="/images/all_matchsynce.png"
                alt="MatchSynce interface"
                className="img-fluid rounded shadow mx-auto d-block"
              />
            </div>
            <div className="col-lg-6 px-4">
              <h3 className="mb-4 text-center text-lg-start mt-lg-0 mt-3">
                Une approche innovante des pronostics
              </h3>
              <p className="text-center text-lg-start">
                MatchSynce utilise des algorithmes avancés pour analyser les
                données historiques, les statistiques des équipes et les
                tendances actuelles pour fournir des prédictions précises.
              </p>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  ✓ Analyses statistiques approfondies
                </li>
                <li className="list-group-item">
                  ✓ Prédictions générées par des algorithmes avancés
                </li>
                <li className="list-group-item">
                  ✓ Interface intuitive et conviviale
                </li>
                <li className="list-group-item">
                  ✓ Performances améliorées grâce à des mises à jour régulières
                </li>
                <li className="list-group-item">
                  ✓ Optimisez vos décisions avec des insights de qualité
                  professionnelle
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section Résumé IA */}
      <section id="ia-summary" className="py-5 bg-light">
        <div className="container">
          <div className="row align-items-center mb-5 text-lg-start text-center">
            {/* Colonne de gauche - Texte */}
            <div className="col-lg-6">
              <h1 className="display-6 fw-bold mb-3">
                Découvrez la Puissance de Notre IA : analyses, stratégies et
                pronostics pour des paris sportifs !
              </h1>
              <p className="lead mb-4">
                Découvrez <b className="text-danger fw-bold">MatchSynceIA</b> ,
                l’Intelligence Artificielle qui révolutionne vos paris sportifs
                ! Accédez à toutes les informations indispensables pour analyser
                et prédire les matchs de football. Discutez directement avec{" "}
                <b className="text-danger fw-bold">MatchSynceIA</b> : posez-lui
                toutes vos questions sur les rencontres à venir, obtenez des
                analyses détaillées, des pronostics précis, des probabilités.
                Maximisez vos chances de succès grâce à des outils innovants
                conçus pour vous aider à prendre des décisions éclairées. Ne
                pariez plus au hasard, pariez avec{" "}
                <b className="text-danger fw-bold">MatchSynceIA</b> !
              </p>
            </div>

            {/* Colonne de droite - Image */}
            <div className="col-lg-6 text-center">
              <img
                src="/images/matchsynceia.png"
                alt="MatchSynceIA"
                className="img-fluid"
                style={{ maxWidth: "400px" }}
              />
            </div>
          </div>

          <div className="row g-4">
            {/* Carte 1 : Analyse Tactique */}
            <div className="col-lg-3 col-md-6">
              <div className="card h-100 border-0 shadow-sm hover-shadow transition-shadow card-hover-zoom">
                <div className="card-body text-center">
                  <span className="display-4 mb-3">📊</span>
                  <h3 className="card-title h4">Analyse Avancée</h3>
                  <p className="card-text text-muted">
                    Accédez à des analyses poussées des équipes, des
                    performances récentes et des facteurs clés pouvant
                    influencer chaque match.
                  </p>
                </div>
              </div>
            </div>

            {/* Carte 2 : Prédictions Performances */}
            <div className="col-lg-3 col-md-6">
              <div className="card h-100 border-0 shadow-sm hover-shadow transition-shadow card-hover-zoom">
                <div className="card-body text-center">
                  <span className="display-4 mb-3">🔮</span>
                  <h3 className="card-title h4">Prédictions Performances</h3>
                  <p className="card-text text-muted">
                    Obtenez des prédictions détaillées sur les performances des
                    joueurs, les statistiques clés et les résultats probables.
                  </p>
                </div>
              </div>
            </div>

            {/* Carte 3 : Projection Score Exact */}
            <div className="col-lg-3 col-md-6">
              <div className="card h-100 border-0 shadow-sm hover-shadow transition-shadow card-hover-zoom">
                <div className="card-body text-center">
                  <span className="display-4 mb-3">🎯</span>
                  <h3 className="card-title h4">Projection Score Exact</h3>
                  <p className="card-text text-muted">
                    Découvrez une projection précise du score final basée sur
                    les données historiques et les performances récentes.
                  </p>
                </div>
              </div>
            </div>

            {/* Carte 4 : Étude Complète du Match */}
            <div className="col-lg-3 col-md-6">
              <div className="card h-100 border-0 shadow-sm hover-shadow transition-shadow card-hover-zoom">
                <div className="card-body text-center">
                  <span className="display-4 mb-3">🧠</span>
                  <h3 className="card-title h4">Étude Complète du Match</h3>
                  <p className="card-text text-muted">
                    Plongez dans une analyse approfondie incluant les forces,
                    les faiblesses et les opportunités pour chaque équipe.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Nouvelles fonctionnalités */}
          <div className="row mt-5 pt-4 border-top">
            <div className="col-lg-6 text-lg-start text-center">
              <div className="card mb-4 border-0 shadow-sm card-hover-zoom">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="bg-danger p-3 rounded-circle text-white me-3">
                      <i className="fas fa-chess fa-2x"></i>
                    </div>
                    <h3>Conseils Stratégiques Personnalisés</h3>
                  </div>
                  <p className="text-muted">
                    À la fin de chaque prédiction, notre IA vous propose des
                    conseils stratégiques adaptés pour optimiser vos paris.
                    Découvrez comment exploiter les failles des équipes,
                    identifier les opportunités de value betting et construire
                    des paris combinés intelligents.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 text-lg-start text-center">
              <div className="card border-0 shadow-sm card-hover-zoom">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="bg-danger p-3 rounded-circle text-white me-3">
                      <i className="fas fa-comments fa-2x"></i>
                    </div>
                    <h3>Conversation IA Interactive</h3>
                  </div>
                  <p className="text-muted">
                    Posez toutes vos questions sur les paris sportifs à notre
                    IA. Que ce soit sur les stratégies générales, les événements
                    sportifs à venir ou des analyses plus pointues, MatchSynce
                    IA vous répond avec précision grâce à un système de
                    questions en cascade pour approfondir votre compréhension.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Call to Action */}
          <div className="text-center mt-4 p-4 bg-success text-white rounded shadow">
            <h2 className="mb-3">Prêt à Transformer Vos Paris Sportifs ?</h2>
            <p className="mb-4">
              Découvrez la puissance de notre IA et transformez votre façon de
              parier. Obtenez des analyses précises, des prédictions fiables et
              prenez l’avantage dès aujourd’hui !
            </p>
            <button
              className="btn btn-danger btn-lg text-white fw-bold px-4 card-hover-zoom"
              onClick={onAnalyticsClick}
            >
              Découvrir MatchSynceIA
            </button>
          </div>
        </div>
      </section>

      {/* Section Partenaires */}
      <section id="partners" className="py-5 bg-white">
        <div className="container">
          <h2 className="text-center mb-4">
            Nos pronostics sont applicables sur tous les sites de paris sportifs
          </h2>

          <div className="partners-slider pt-5">
            <div className="partners-track">
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#0033cc" }}>
                  1xBet
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#ff0000" }}>
                  Winamax
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#003366" }}>
                  Betclic
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#ff6600" }}>
                  Unibet
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#CA0D1B" }}>
                  ZEbet
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#005f86" }}>
                  Parions Sport
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#0033cc" }}>
                  Bwin
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#00B274" }}>
                  Bet365
                </span>
              </div>
              <div className="partner-logo">
                <span
                  className="partner-text"
                  style={{
                    color: "#000000",
                    backgroundColor: "#FFFFFF",
                    padding: "5px 10px",
                  }}
                >
                  DraftKings
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#0055FF" }}>
                  FanDuel
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "black" }}>
                  Pinnacle
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#FF6600" }}>
                  888sport
                </span>
              </div>

              {/* Contenu dupliqué */}

              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#0033cc" }}>
                  1xBet
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#ff0000" }}>
                  Winamax
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#003366" }}>
                  Betclic
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#ff6600" }}>
                  Unibet
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#CA0D1B" }}>
                  ZEbet
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#005f86" }}>
                  Parions Sport
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#0033cc" }}>
                  Bwin
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#00B274" }}>
                  Bet365
                </span>
              </div>
              <div className="partner-logo">
                <span
                  className="partner-text"
                  style={{
                    color: "#000000",
                    backgroundColor: "#FFFFFF",
                    padding: "5px 10px",
                  }}
                >
                  DraftKings
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#0055FF" }}>
                  FanDuel
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "black" }}>
                  Pinnacle
                </span>
              </div>
              <div className="partner-logo">
                <span className="partner-text" style={{ color: "#FF6600" }}>
                  888sport
                </span>
              </div>
            </div>
          </div>
        </div>

        <style jsx>{`
          .partners-slider {
            overflow: hidden;
            position: relative;
            padding: 20px 0;
            width: 100%;
          }

          .partners-slider::before,
          .partners-slider::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100px;
            z-index: 2;
            pointer-events: none;
          }

          .partners-slider::before {
            left: 0;
            background: linear-gradient(to right, white, transparent);
          }

          .partners-slider::after {
            right: 0;
            background: linear-gradient(to left, white, transparent);
          }

          .partners-track {
            display: flex;
            animation: slide 30s linear infinite;
            width: max-content;
          }

          .partner-logo {
            padding: 0 60px;
            flex-shrink: 0;
            transition: transform 0.3s ease;
          }

          .partner-logo:hover {
            transform: scale(1.1);
          }

          .partner-text {
            font-size: 34px;
            font-weight: bold;
            text-transform: uppercase;
            font-family: "Arial", sans-serif;
            transition: opacity 0.3s ease;
          }

          @keyframes slide {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }

          @media (max-width: 768px) {
            .partner-text {
              font-size: 30px;
            }
          }
        `}</style>
      </section>

      {/* Nouvelle Section Messagerie */}
      <section id="messaging" className="py-5 bg-light">
        <div className="container">
          <div className="text-center mb-5">
            <img
              src="/matchsynce_chat.png"
              alt="Communication communautaire"
              className="img-fluid mb-4"
              style={{
                maxWidth: "120px",
                height: "auto",
                margin: "0 auto",
                display: "block",
              }}
            />
            <h2 className="text-center mb-2">
              Une Communauté Connectée en Temps Réel
            </h2>
            <p className="text-center text-muted mb-5">
              Échangez, partagez et développez votre réseau avec notre système
              de messagerie enrichi
            </p>
          </div>

          <div className="row g-4">
            {messagingFeatures.map((feature, index) => (
              <div key={index} className="col-lg-4">
                <div className="card h-100 border-0 shadow-sm hover-shadow transition-shadow card-hover-zoom">
                  <div className="card-body">
                    <div className="text-center mb-3">
                      <span className="display-4">{feature.icon}</span>
                    </div>
                    <h3 className="card-title h4 text-center mb-3">
                      {feature.title}
                    </h3>
                    <p className="card-text text-muted text-center mb-4">
                      {feature.description}
                    </p>
                    <ul className="space-y-3 list-group list-group-flush text-center">
                      {feature.details.map((detail, idx) => (
                        <li
                          key={idx}
                          className="flex items-start list-group-item space-x-3"
                        >
                          <span className="text-xl flex-shrink-0 me-2">
                            {detail.icon}
                          </span>
                          <span className="text-gray-700">{detail.text}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <div className="bg-white p-4 rounded-3 text-center text-lg-start card-hover-zoom">
                <h4 className="mb-3">Communication en Direct</h4>
                <p className="text-muted">
                  Notre système de messagerie vous permet de rester connecté
                  avec la communauté MatchSynce. Échangez des analyses, partagez
                  vos stratégies et développez votre réseau, le tout en temps
                  réel.
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <i className="bi bi-lightning-fill text-warning me-2"></i>.
                    Messages instantanés en temps réel
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-shield-check text-primary me-2"></i>.
                    Confidentialité et sécurité des échanges garanties
                  </li>
                  <li>
                    <i className="bi bi-people-fill text-success me-2"></i>.
                    Construisez votre réseau de contacts
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <div className="bg-white p-4 rounded-3 text-center text-lg-start card-hover-zoom">
                <h4 className="mb-3">Fonctionnalités Avancées</h4>
                <p className="text-muted">
                  Profitez d'une expérience de messagerie complète avec des
                  fonctionnalités modernes pour une communication efficace.
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <i className="bi bi-chat-dots-fill text-info me-2"></i>.
                    Partage de médias (images, vidéo)
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-chat-dots-fill text-info me-2"></i>.
                    Partage de messages vocaux et notes audio
                  </li>
                  <li>
                    <i className="bi bi-search text-primary me-2"></i>.
                    Recherche avancée dans les conversations
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-center mt-5 mb-4">
            <p className="h5 text-muted mt-3">
              Commencez à échanger avec des passionnés comme vous
            </p>
            <button
              className="btn btn-success btn-md px-3 py-3 mt-2 card-hover-zoom"
              onClick={handleChatOpen}
            >
              <span className="me-2">🚀</span>
              Rejoignez la communauté MatchSynce
            </button>
          </div>
        </div>
      </section>

      {/* Section MatchSynce Battle */}
      <section id="battle" className="py-5">
        <div className="container">
          <h2 className="text-center mb-5 px-3">
            Eh oui, encore plus avec MatchSynce Battle
          </h2>
          <div className="row align-items-center gy-4">
            <div className="col-lg-6 px-4 text-center text-lg-start">
              <h3 className="mb-4">
                Sois le meilleur parieur de toute la communauté et montre ton
                expertise !
              </h3>
              <p>
                Avec MatchSynce Battle, tu peux faire des pronostics, voir ceux
                de la communauté, et te mesurer aux meilleurs parieurs. Deviens
                le champion de la prédiction !
              </p>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  ✓ Fais des pronostics sur les matchs à venir
                </li>
                <li className="list-group-item">
                  ✓ Consulte les pronostics de toute la communauté
                </li>
                <li className="list-group-item">
                  ✓ Monte dans le classement des meilleurs parieurs
                </li>
                <li className="list-group-item">
                  ✓ Défie tes amis et montre qui est le meilleur
                </li>
                <li className="list-group-item">
                  ✓ Gagne des badges et des récompenses pour tes performances
                </li>
              </ul>
            </div>
            <div className="col-lg-6 card-hover-zoom">
              <img
                src="/images/matchsynce_battle.png"
                alt="MatchSynce Battle interface"
                className="img-fluid rounded shadow mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Benefits of Analysis Section */}
      <section id="features" className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-2">
            La Puissance des Analyses Sportives
          </h2>
          <p className="text-center text-muted mb-5">
            Découvrez comment l'analyse des données transforme le monde du sport
          </p>

          <div className="row g-4 justify-content-center">
            {benefitsData.map((benefit, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <div className="card h-100 border-0 shadow-sm hover-shadow transition-shadow card-hover-zoom">
                  <div className="position-relative">
                    <img
                      src={benefit.image}
                      className="card-img-top"
                      alt={benefit.title}
                      style={{ height: "200px", objectFit: "cover" }}
                    />
                    <div className="position-absolute top-0 end-0 p-2">
                      <span className="badge bg-primary fs-4">
                        {benefit.icon}
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title h4">{benefit.title}</h3>
                    <p className="card-text text-muted">
                      {benefit.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-5 pt-4">
            <div className="row align-items-center">
              <div className="col-md-6 card-hover-zoom">
                <img
                  src="/images/digital-marketing.jpg"
                  alt="Bénéfices des analyses sportives"
                  className="img-fluid rounded-3 shadow"
                />
              </div>
              <div className="col-md-6 mt-lg-0 mt-5 ">
                <h3 className="mb-4 text-center text-lg-start">
                  Pourquoi l'Analyse est Cruciale ?
                </h3>
                <div className="d-flex align-items-start mb-3">
                  <div className="bg-success text-white rounded-circle p-2 me-3">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <div>
                    <h4 className="h5">Objectivité Maximale</h4>
                    <p className="text-muted">
                      Éliminez les biais émotionnels avec des données concrètes
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-3">
                  <div className="bg-success text-white rounded-circle p-2 me-3">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <div>
                    <h4 className="h5">Performance Historique</h4>
                    <p className="text-muted">
                      Identifiez les patterns de performance sur le long terme
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="bg-success text-white rounded-circle p-2 me-3">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <div>
                    <h4 className="h5">Stratégie Optimisée</h4>
                    <p className="text-muted">
                      Développez une approche basée sur des métriques fiables
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Android Installation Section */}
      <section id="installation" className="py-5">
        <div className="container">
          <h2 className="text-center mb-5 px-3">
            Souhaitez-vous installer cette application sur votre Android ?
          </h2>
          <div className="row gy-4">
            <div className="col-md-4 text-center card-hover-zoom ">
              <div className="px-3">
                <img
                  src="/images/a1.png"
                  alt="Android étape 1"
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <h4>1. Ouvrez Chrome</h4>
                <p>Accédez à MatchSynce via votre navigateur Chrome</p>
              </div>
            </div>
            <div className="col-md-4 text-center card-hover-zoom">
              <div className="px-3">
                <img
                  src="/images/a2.webp"
                  alt="Android étape 2"
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <h4>2. Menu Options</h4>
                <p>Cliquez sur les trois points en haut à droite</p>
              </div>
            </div>
            <div className="col-md-4 text-center card-hover-zoom">
              <div className="px-3">
                <img
                  src="/images/a3.webp"
                  alt="Android étape 3"
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <h4>3. Ajoutez à l'écran d'accueil</h4>
                <p>Sélectionnez "Ajouter à l'écran d'accueil"</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* iOS Installation Section */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5 px-3">
            Souhaitez-vous installer cette application sur votre iPhone ?
          </h2>
          <div className="row gy-4">
            <div className="col-md-4 text-center card-hover-zoom">
              <div className="px-3">
                <img
                  src="/images/b1.png"
                  alt="iOS étape 1"
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <h4>1. Ouvrez Safari</h4>
                <p>Accédez à MatchSynce via votre navigateur Safari</p>
              </div>
            </div>
            <div className="col-md-4 text-center card-hover-zoom">
              <div className="px-3">
                <img
                  src="/images/b2.png"
                  alt="iOS étape 2"
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <h4>2. Menu Partage</h4>
                <p>Appuyez sur l'icône de partage en bas</p>
              </div>
            </div>
            <div className="col-md-4 text-center card-hover-zoom">
              <div className="px-3">
                <img
                  src="/images/b3.webp"
                  alt="iOS étape 3"
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: "80px", width: "auto" }}
                />
                <h4>3. Sur l'écran d'accueil</h4>
                <p>Choisissez "Sur l'écran d'accueil"</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section Contact */}
      <section id="contact" className="py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 card-hover-zoom">
              <div className="p-4 bg-white rounded shadow ">
                <h3 className="mb-3 text-center text-lg-start">
                  Besoin d'aide ? Ouvrez un ticket
                </h3>
                <p className="text-muted mb-4 text-center text-lg-start">
                  Notre équipe de support vous répondra rapidement par e-mail.
                </p>
                <form onSubmit={handleTicketSubmit} className="ticket-form">
                  <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Votre Nom</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={ticketData.name}
                        onChange={handleTicketChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Votre Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={ticketData.email}
                        onChange={handleTicketChange}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Votre Message</label>
                    <textarea
                      name="message"
                      className="form-control"
                      rows="4"
                      value={ticketData.message}
                      onChange={handleTicketChange}
                      disabled={isSubmitting}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="text-center my-5 p-3 p-lg-0">
        <h2 className="text-center mb-2">
          Nous aimerions avoir ton retour et te tenir informé
        </h2>
        <p className="text-center text-muted mb-4">
          Partage ton avis pour améliorer nos services et abonne-toi pour
          recevoir nos dernières nouvelles.
        </p>

        <div className="text-center my-lg-5 card-hover-zoom">
          <img
            src="/images/contact.jpg"
            alt="Image description"
            style={{
              maxWidth: "20%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="img-fluid d-none d-md-block"
          />

          <img
            src="/images/contact.jpg"
            alt="Image description"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            className="img-fluid d-block d-md-none"
          />
        </div>
      </div>

      {/* Section Feedback & Abonnement */}
      <section id="feedback" className="py-lg-4">
        <div className="container">
          <div className="row">
            {/* Section Feedback */}
            <div className="col-md-6 mb-lg-0 mb-3 card-hover-zoom">
              <div className="p-4 bg-white rounded shadow h-100">
                <h2 className="text-center mb-3">Ton avis !</h2>
                <p className="text-center text-muted mb-3">
                  Ton opinion compte pour nous. Partage tes idées et aide-nous à
                  améliorer MatchSynce.
                </p>
                <form onSubmit={handleFeedbackSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Votre Nom</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={feedbackData.name}
                      onChange={handleFeedbackChange}
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Votre Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={feedbackData.email}
                      onChange={handleFeedbackChange}
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Votre Avis</label>
                    <textarea
                      name="message"
                      className="form-control"
                      rows="4"
                      value={feedbackData.message}
                      onChange={handleFeedbackChange}
                      disabled={isSubmitting}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Envoi en cours..." : "Envoyer mon avis"}
                  </button>
                </form>
              </div>
            </div>

            {/* Section Abonnement (Souscription) */}
            <div className="col-md-6 d-flex align-items-center justify-content-center card-hover-zoom">
              <div
                className="p-4 bg-white rounded shadow w-100"
                style={{ maxWidth: "500px" }}
              >
                <h2 className="text-center mb-3">
                  Abonne-toi à nos mises à jour
                </h2>
                <p className="text-center text-muted mb-4">
                  Reste informé des dernières actualités et conseils. Aucun
                  spam, promis.
                </p>
                <form onSubmit={handleSubscriptionSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Votre Nom</label>
                    <input
                      type="text"
                      name="subscribeName"
                      className="form-control"
                      value={subscriptionData.subscribeName}
                      onChange={handleSubscriptionChange}
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Votre Email</label>
                    <input
                      type="email"
                      name="subscribeEmail"
                      className="form-control"
                      value={subscriptionData.subscribeEmail}
                      onChange={handleSubscriptionChange}
                      disabled={isSubmitting}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Inscription en cours..." : "S'abonner"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Chat Modal */}
      {showChat && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "95vw", // Largeur maximale du modal
              width: "95vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Communauté MatchSynce</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleChatClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src="/matchsynce_chat.png"
                      alt="MatchSynce Logo"
                      className="zoom-animation"
                      style={{ width: "180px", height: "130px" }}
                    />
                  </div>
                ) : (
                  <Chat style={{ height: "100%" }} /> // Le composant Chat prend toute la hauteur disponible
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .zoom-animation {
            animation: zoomInOut 1.5s ease-in-out;
          }

          @keyframes zoomInOut {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            50% {
              transform: scale(1.2);
              opacity: 1;
            }
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }

          .transition-all {
            transition: all 0.3s ease-in-out;
          }

          .slide-in-top-container {
            perspective: 1000px;
            overflow: hidden;
          }

          .slide-in-top-animation {
            animation: slideInFromTop 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) forwards;
            will-change: transform, opacity;
          }

          @keyframes slideInFromTop {
            0% {
              transform: translateY(-50px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }

          /* Variante avec légère rotation pour plus de dynamisme */
          .slide-in-top-rotation {
            animation: slideInFromTopRotate 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) forwards;
            will-change: transform, opacity;
          }

          @keyframes slideInFromTopRotate {
            0% {
              transform: translateY(-50px) rotate(-2deg);
              opacity: 0;
            }
            30% {
              transform: translateY(5px) rotate(1deg);
              opacity: 1;
            }
            60% {
              transform: translateY(-3px) rotate(-0.5deg);
              opacity: 1;
            }
            100% {
              transform: translateY(0) rotate(0);
              opacity: 1;
            }
          }

          /* Animation séquentielle pour plusieurs éléments */
          .sequence-item {
            opacity: 0;
            transform: translateY(-30px);
          }

          .sequence-item:nth-child(1) { animation: sequenceIn 0.5s forwards 0.1s; }
          .sequence-item:nth-child(2) { animation: sequenceIn 0.5s forwards 0.3s; }
          .sequence-item:nth-child(3) { animation: sequenceIn 0.5s forwards 0.5s; }
          .sequence-item:nth-child(4) { animation: sequenceIn 0.5s forwards 0.7s; }
          .sequence-item:nth-child(5) { animation: sequenceIn 0.5s forwards 0.9s; }

          @keyframes sequenceIn {
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .card-hover-zoom {
            transition: transform 0.3s ease-in-out;
          }

          .card-hover-zoom:hover {
            transform: scale(1.05); /* Ajustez la valeur pour contrôler le niveau de zoom */
          }
          
        `}
      </style>

      {showBattle && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "93vw", // Largeur maximale du modal
              width: "93vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">MatchSynce Battle</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleBattleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src="/matchsynce_battle.png"
                      alt="MatchSynce Logo"
                      className="zoom-animation"
                      style={{ width: "130px", height: "180px" }}
                    />
                  </div>
                ) : (
                  <Container fluid className="h-100 p-0">
                    <Row className="h-100">
                      <Col className="h-100">
                        <PronosticForm
                          matches={matches}
                          onSubmit={handleSubmitPronostics}
                          style={{ height: "100%" }} // Prendre toute la hauteur disponible
                        />
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <UpdateBanner /> */}
    </div>
  );
};

export default LandingPage;
