import React, { useState } from 'react';
import { Card as BootstrapCard, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ArrowLeft, Search, Calendar, Clock, Zap } from 'lucide-react';

const MatchSelectionPage = ({ matches, onMatchSelect, onBack }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedLeague, setSelectedLeague] = useState('');

  const matchesArray = Array.isArray(matches) ? matches : [];

  const ligues = [
    { code: "champions_league", nom: "Champions League", flag: "🇪🇺" },
    { code: "europa_league", nom: "Europa League", flag: "🇪🇺" },
    { code: "conference_league", nom: "Conference League", flag: "🇪🇺" },
    { code: "liga", nom: "Liga Espagnole", flag: "🇪🇸" },
    { code: "bundesliga", nom: "Bundesliga", flag: "🇩🇪" },
    { code: "premier_league", nom: "Premier League", flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿" },
    { code: "serie_a", nom: "Serie A", flag: "🇮🇹" },
    { code: "ligue_1", nom: "Ligue 1", flag: "🇫🇷" },
  ];

  const filteredMatches = matchesArray.filter((match) => {
    const matchesSearchTerm = match.home_team?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            match.away_team?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDate = selectedDate ? match.date.split('T')[0] === selectedDate : true;
    const matchesLeague = selectedLeague ? match.league === selectedLeague : true;
    return matchesSearchTerm && matchesDate && matchesLeague;
  });

  function formatLeagueName(league) {
    switch (league) {
      case "bundesliga":
        return "🇩🇪 Bundesliga";
      case "liga":
        return "🇪🇸 Liga";
      case "premier_league":
        return "🏴󠁧󠁢󠁥󠁮󠁧󠁿 Premier League";
      case "serie_a":
        return "🇮🇹 Série A";
      case "ligue_1":
        return "🇫🇷 Ligue 1";
      case "champions_league":
        return "🇪🇺 Champions League";
      case "europa_league":
        return "🇪🇺 Europa League";
      case "conference_league":
        return "🇪🇺 Conference League";
      default:
        return league;
    }
  }

  return (
    <div style={{ maxHeight: "calc(100vh - 190px)", minHeight: "300px", overflowY: "auto" }}>
      <Container fluid className=" px-2">
        <Row className="justify-content-center">
          <Col md={12} lg={12}>
            <div className="d-lg-flex justify-content-between align-items-center mb-4">
              <Button variant="outline-secondary" size="sm" onClick={onBack} className="d-flex align-items-center">
                <ArrowLeft size={18} className="me-2" />
                Retour
              </Button>
              <h2 className="mb-0 text-danger fw-bold mt-lg-0 mt-3 text-lg-start text-center">Sélectionnez un match et laissez l’IA faire le reste !</h2>
              <div className="invisible">
                <Button variant="outline-light" size="sm">Invisible</Button>
              </div>
            </div>
            <div className="bg-light p-4 rounded-3 shadow-sm mb-4">
              <Row>
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <Search size={20} className="me-2 text-muted" />
                    <Form.Control
                      type="text"
                      placeholder="Rechercher une équipe..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="border-0 shadow-none"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <Form.Select
                    value={selectedLeague}
                    onChange={(e) => setSelectedLeague(e.target.value)}
                    className="border-0 shadow-none"
                  >
                    <option value="">Tous les championnats</option>
                    {ligues.map((ligue) => (
                      <option key={ligue.code} value={ligue.code}>
                        {formatLeagueName(ligue.code)}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
            <Row>
              {filteredMatches.map((match) => (
                <Col key={match.uniqueId} xs={12} sm={6} md={4} className="mb-4">
                  <BootstrapCard
                    className="h-100 shadow-sm border-0 match-card"
                    onClick={() => onMatchSelect(match)}
                    style={{
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = 'translateY(-5px)';
                      e.currentTarget.style.boxShadow = '0 10px 20px rgba(0,0,0,0.1)';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = 'translateY(0)';
                      e.currentTarget.style.boxShadow = '0 5px 15px rgba(0,0,0,0.05)';
                    }}
                  >
                    <BootstrapCard.Header className="text-center bg-danger text-white">
                      <h5 className="mb-0 fw-bold">Match à venir</h5>
                    </BootstrapCard.Header>
                    <BootstrapCard.Body className="d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <span className="team-badge text-truncate fw-bold" style={{ maxWidth: '40%' }}>
                          {match.home_team}
                        </span>
                        <span className="vs-badge bg-dark text-white px-2 py-1 rounded fw-bold">
                          VS
                        </span>
                        <span className="team-badge text-truncate fw-bold" style={{ maxWidth: '40%' }}>
                          {match.away_team}
                        </span>
                      </div>
                      <div className="match-details text-center mb-3">
                        <div className="match-time d-flex align-items-center justify-content-center">
                          <Clock size={16} className="me-2 text-muted" />
                          <span className="text-muted">
                            {new Date(match.date).toLocaleString()}
                          </span>
                        </div>
                        <div className="league-name mt-2">
                          {formatLeagueName(match.league)}
                        </div>
                      </div>
                    </BootstrapCard.Body>
                    <BootstrapCard.Footer className="text-center bg-light">
                      <small className="text-muted d-flex align-items-center justify-content-center">
                        <Zap size={14} className="me-2 text-warning" />
                        Cliquez pour voir les détails et parier
                      </small>
                    </BootstrapCard.Footer>
                  </BootstrapCard>
                </Col>
              ))}
            </Row>
            {filteredMatches.length === 0 && (
              <Row className="justify-content-center">
                <Col md={6} className="text-center">
                  <BootstrapCard className="shadow-sm border-0">
                    <BootstrapCard.Body>
                      <p className="mb-0 text-muted">Aucun match ne correspond à votre recherche.</p>
                    </BootstrapCard.Body>
                  </BootstrapCard>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MatchSelectionPage;