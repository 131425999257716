import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { 
  Trophy, Target, Flag, ShieldAlert, Goal, ArrowUpRight, Clock, Plus, Minus
} from 'lucide-react';

const FootballIcons = {
  goal: "⚽",
  trophy: "🏆",
  fire: "🔥",
  chart: "📊",
  stats: "📈",
  clock: "⏱️",
  flag: "🚩",
  team: "👥",
};

const MatchDetailsComponent = ({ 
  match, 
  expandedCards = [], 
  onToggleCard = () => {} 
}) => {
  const matchId = `${match.home_team}-${match.away_team}-${match.date}-${match.expectedGoals}`.replace(/[^a-zA-Z0-9-]/g, '');
  const isExpanded = Array.isArray(expandedCards) && expandedCards.includes(matchId);

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleCard(matchId);
  };

  return (
    <Col md={4} className="mb-5 mb-lg-4">
      <div style={{ 
        position: 'relative',
        minHeight: isExpanded ? '500px' : '220px', // Réduit la hauteur minimale
        transition: 'min-height 0.2s ease-in-out' // Animation fluide
      }}>
        <div style={{
          position: isExpanded ? 'absolute' : 'static',
          width: '100%',
          zIndex: isExpanded ? 1000 : 1,
        }}
        >
          <Card 
            style={{ 
              width: '100%',
              backgroundColor: 'white',
              boxShadow: isExpanded ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
            }} 
            data-match-id={matchId}
          >
            <Card.Header className="d-flex justify-content-between align-items-center py-2">
              <span>{match.name}</span>
              <button 
                onClick={handleToggle} 
                className="btn btn-link p-0 text-dark"
                style={{ textDecoration: 'none' }}
                aria-expanded={isExpanded}
                data-match-toggle={matchId}
              >
                {isExpanded ? <Minus size={20} /> : <Plus size={20} />}
              </button>
            </Card.Header>
            <Card.Body className="py-2">
              <div className="mb-2">
                {FootballIcons.clock} <strong>Date:</strong> {match.date}
              </div>
              <div className="mb-2">
                {FootballIcons.goal} <strong>Buts Totaux Attendus:</strong> {match.expectedGoals}
              </div>
              <div className="mb-2">
                {FootballIcons.trophy} <strong>Résultat Probable:</strong> {match.matchResult}
              </div>
              <div className="mb-2">
                {FootballIcons.flag} <strong>Mi-Temps Probable:</strong> {match.halfTimePrediction}
              </div>
              <div className="mb-2">
                {FootballIcons.team} <strong>Double chance:</strong> {match.matchResult}
              </div>

              {isExpanded && (
                <div className="expanded-content" data-match-content={matchId}>
                  <hr className="my-2" />
                  
                  <div className="row">
                    <div className="col-6">
                      <h6 className="text-primary mb-2">{match.home_team}</h6>
                      <div className="mb-1">
                        <ArrowUpRight size={16} className="me-2" />
                        <strong>Position:</strong> {match.home_position || 'N/A'}e
                      </div>
                      <div className="mb-1">
                        <Trophy size={16} className="me-2" />
                        <strong>Points:</strong> {match.home_points || 'N/A'}pts
                      </div>
                      <div className="mb-1">
                        <Goal size={16} className="me-2" />
                        <strong>Moyenne Buts:</strong> {match.home_goals || 'N/A'}
                      </div>
                      <div>
                        <ShieldAlert size={16} className="me-2" />
                        <strong>Encaissés:</strong> {match.home_conceded || 'N/A'}
                      </div>
                    </div>
                    <div className="col-6">
                      <h6 className="text-primary mb-2">{match.away_team}</h6>
                      <div className="mb-1">
                        <ArrowUpRight size={16} className="me-2" />
                        <strong>Position:</strong> {match.away_position || 'N/A'}e
                      </div>
                      <div className="mb-1">
                        <Trophy size={16} className="me-2" />
                        <strong>Points:</strong> {match.away_points || 'N/A'}pts
                      </div>
                      <div className="mb-1">
                        <Goal size={16} className="me-2" />
                        <strong>Moyenne Buts:</strong> {match.away_goals || 'N/A'}
                      </div>
                      <div>
                        <ShieldAlert size={16} className="me-2" />
                        <strong>Encaissés:</strong> {match.away_conceded || 'N/A'}
                      </div>
                    </div>
                  </div>
                  
                  <hr className="my-2" />
                  
                  <div>
                    <Target size={16} className="me-2" />
                    <strong>Probabilités Avancées:</strong>
                    <div className="mt-2">
                      <div className="mb-1">
                        <strong>Probabilité +2.5 Buts:</strong> {match.over_2_5_prob ? `${match.over_2_5_prob}%` : 'N/A'} {" "} {match.over_2_5_prob > 50 ? '(Oui)' : '(Non)'}
                      </div>
                      <div>
                        <strong>Probabilité But des Deux Équipes:</strong> {match.btts_prob ? `${match.btts_prob}%` : 'N/A'} {" "} {match.btts_prob > 50 ? '(Oui)' : '(Non)'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Col>
  );
};

export default MatchDetailsComponent;