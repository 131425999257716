import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ArrowLeft, Trophy, Shield } from "lucide-react";
import InteractiveCharts from "./InteractiveCharts";
import SpiralChart from "./SpiralChart";
import {
  completeMatchStudyVersions,
  exactScoreProjectionVersions,
  performancePredictionVersions,
  tacticalAnalysisVersions,
} from "./IAAnalysisVersions";
import {
  sportsBettingKnowledge,
  getRandomQuestions,
} from "./Utils/sportsBettingKnowledge";

const IAChatPage = ({ match, option, onBack, onRestart, onClose }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCharts, setShowCharts] = useState(false);
  const [chartText, setChartText] = useState("");
  const [chartIndex, setChartIndex] = useState(0);
  const [adviceText, setAdviceText] = useState("");
  const [adviceIndex, setAdviceIndex] = useState(0);
  const [randomQuestions, setRandomQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedSubQuestions, setSelectedSubQuestions] = useState([]);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [isAnswering, setIsAnswering] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Gère le clignotement toutes les 500ms
    const blinkInterval = setInterval(() => setVisible((v) => !v), 600);
    
    // Arrête le clignotement après 4 secondes
    const timer = setTimeout(() => {
      clearInterval(blinkInterval);
      setLoading(false);
    }, 4000);
    
    return () => {
      clearInterval(blinkInterval);
      clearTimeout(timer);
    };
}, []);

  const adviceTextRef = useRef(null);

  // Sélectionner 5 questions aléatoires au chargement
  useEffect(() => {
    const questions = getRandomQuestions(sportsBettingKnowledge, 5);
    setRandomQuestions(questions);
  }, []);

  const displayAnswerWithAnimation = (answer, elementRef) => {
    if (!elementRef.current) {
      console.error("L'élément DOM n'est pas défini.");
      return;
    }

    let index = 0;
    const interval = setInterval(() => {
      if (index < answer.length && elementRef.current) {
        elementRef.current.textContent += answer[index];
        index++;
      } else {
        clearInterval(interval);
        console.log(
          "Animation terminée, mise à jour de animationFinished à true"
        );
        setAnimationFinished(true); // Marquer que l'animation est terminée
      }
    }, 50); // Vitesse d'écriture
  };

  // Afficher la réponse avec animation
  const handleQuestionClick = (question) => {
    if (isAnswering) return; // Désactiver les clics pendant l'animation

    // Ajouter la question au chat
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { type: "question", content: question.question, isAnimated: false },
    ]);

    // Ajouter une réponse vide au chat pour démarrer l'animation
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { type: "answer", content: "", isAnimated: true },
    ]);

    // Désactiver les clics pendant l'animation
    setIsAnswering(true);

    // Si cette question a des sous-questions, les mettre à jour
    if (question.subQuestions) {
      setSelectedSubQuestions(question.subQuestions);
      setSelectedQuestion(question);
    }

    // Animer la réponse lettre par lettre
    const answer = question.answer;
    let index = 0;
    const interval = setInterval(() => {
      if (index < answer.length) {
        setChatMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              content: answer.substring(0, index + 1),
            },
          ];
        });
        index++;
      } else {
        clearInterval(interval);
        // Marquer la réponse comme entièrement animée
        setChatMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, -1),
            { ...lastMessage, isAnimated: false },
          ];
        });
        // Réactiver les clics après l'animation
        setIsAnswering(false);
      }
    }, 50); // Vitesse d'écriture
  };

  const formatTextWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line
          .split("**")
          .map((part, partIndex) =>
            partIndex % 2 === 1 ? <strong key={partIndex}>{part}</strong> : part
          )}
        <br />
      </React.Fragment>
    ));
  };

  // Log pour vérifier l'état de animationFinished
  useEffect(() => {
    console.log("animationFinished a changé :", animationFinished);
  }, [animationFinished]);

  // Log pour vérifier que adviceTextRef est bien lié
  useEffect(() => {
    console.log("adviceTextRef.current :", adviceTextRef.current);
  }, []);

  useEffect(() => {
    console.log("adviceTextRef après rendu :", adviceTextRef.current);
  }, []);

  const versions = [
    // Version 1 : Style direct et informatif
    `Analyse des Buts : Ce graphique vous montre les buts marqués et encaissés par ${match.home_team} et ${match.away_team} pendant le match. Voici ce que vous devez savoir :\n\n1. Buts Marqués : Les barres bleues indiquent combien de buts chaque équipe a marqué. Plus la barre est haute, plus l'équipe a été efficace devant le but.\n2. Buts Encaissés : Les barres rouges montrent combien de buts chaque équipe a encaissés. Une barre haute ici signifie que la défense a eu des difficultés.\n3. Moments Décisifs : Les pics sur le graphique correspondent aux moments où les buts ont été marqués, révélant les périodes clés du match.\n\nConclusion : Grâce à ce graphique, vous pouvez voir comment les buts ont façonné le match et identifier les forces et faiblesses de chaque équipe.`,

    // Version 2 : Style narratif
    `Analyse des Buts : Plongez dans l'analyse des buts marqués et encaissés lors du match entre ${match.home_team} et ${match.away_team}. Voici ce que révèle le graphique :\n\n1. Buts Marqués : Les barres bleues racontent l'histoire de l'efficacité offensive de chaque équipe. Une barre plus haute signifie plus de buts et une attaque plus redoutable.\n2. Buts Encaissés : Les barres rouges exposent les failles défensives. Une barre élevée ici montre une défense qui a eu du mal à contenir l'adversaire.\n3. Moments Clés : Les pics sur le graphique marquent les instants où le match a basculé, avec des buts décisifs.\n\nConclusion : Ce graphique interactif vous aide à comprendre comment les buts ont influencé le déroulement du match.`,

    // Version 3 : Style technique
    `Analyse des Buts : Le graphique ci-dessous analyse les buts marqués et encaissés par ${match.home_team} et ${match.away_team}. Décryptage :\n\n1. Buts Marqués : Les barres bleues quantifient l'efficacité offensive. Une barre haute indique une meilleure conversion des occasions en buts.\n2. Buts Encaissés : Les barres rouges mesurent les défaillances défensives. Une barre haute révèle une défense vulnérable.\n3. Moments Déterminants : Les pics sur le graphique identifient les moments où les buts ont été marqués, mettant en lumière les phases critiques du match.\n\nConclusion : Cette visualisation vous permet d'analyser l'impact des buts sur le résultat final.`,

    // Version 4 : Style engageant
    `Analyse des Buts : Découvrez les buts marqués et encaissés par ${match.home_team} et ${match.away_team} dans ce match passionnant. Voici ce que vous allez voir :\n\n1. Buts Marqués : Les barres bleues montrent qui a été le plus efficace devant le but. Plus c'est haut, plus c'est impressionnant !\n2. Buts Encaissés : Les barres rouges révèlent les difficultés défensives. Une barre haute ? C'est le signe d'une défense en difficulté.\n3. Moments Forts : Les pics sur le graphique vous montrent quand les buts ont été marqués, pour revivre les moments clés.\n\nConclusion : Ce graphique interactif vous donne les clés pour comprendre comment les buts ont changé la donne.`,

    // Version 5 : Style concis
    `Analyse des Buts : Voici les buts marqués et encaissés par ${match.home_team} et ${match.away_team} :\n\n1. Buts Marqués : Barres bleues = efficacité offensive.\n2. Buts Encaissés : Barres rouges = faiblesses défensives.\n3. Moments Clés : Pics = moments des buts.\n\nConclusion : Ce graphique montre comment les buts ont influencé le match.`,

    // Version 6 : Style détaillé
    `Analyse des Buts : Ce graphique détaille les buts marqués et encaissés par ${match.home_team} et ${match.away_team}. Analyse :\n\n1. Buts Marqués : Les barres bleues représentent le nombre de buts. Une barre haute signifie une attaque performante.\n2. Buts Encaissés : Les barres rouges indiquent les buts encaissés. Une barre haute montre une défense défaillante.\n3. Moments Décisifs : Les pics correspondent aux moments où les buts ont été marqués, révélant les tournants du match.\n\nConclusion : Ce graphique vous aide à comprendre l'impact des buts sur le résultat.`,

    // Version 7 : Style dynamique
    `Analyse des Buts : Explorez les buts marqués et encaissés par ${match.home_team} et ${match.away_team} dans ce match. Voici ce que vous découvrirez :\n\n1. Buts Marqués : Les barres bleues montrent l'efficacité offensive. Plus c'est haut, plus c'est efficace !\n2. Buts Encaissés : Les barres rouges exposent les faiblesses défensives. Une barre haute = des problèmes en défense.\n3. Moments Forts : Les pics sur le graphique indiquent quand les buts ont été marqués, pour revivre l'action.\n\nConclusion : Ce graphique interactif vous plonge au cœur des moments décisifs du match.`,

    // Version 8 : Style analytique
    `Analyse des Buts : Le graphique ci-dessous analyse les buts marqués et encaissés par ${match.home_team} et ${match.away_team}. Observations :\n\n1. Buts Marqués : Les barres bleues mesurent l'efficacité offensive. Une barre haute = une attaque redoutable.\n2. Buts Encaissés : Les barres rouges évaluent les faiblesses défensives. Une barre haute = une défense en difficulté.\n3. Moments Clés : Les pics identifient les moments où les buts ont été marqués, révélant les phases critiques.\n\nConclusion : Ce graphique vous permet d'analyser l'impact des buts sur le match.`,

    // Version 9 : Style visuel
    `Analyse des Buts : Ce graphique illustre les buts marqués et encaissés par ${match.home_team} et ${match.away_team}. Voici ce que vous voyez :\n\n1. Buts Marqués : Les barres bleues = nombre de buts. Plus c'est haut, plus c'est efficace.\n2. Buts Encaissés : Les barres rouges = buts encaissés. Une barre haute = défense en difficulté.\n3. Moments Clés : Les pics = moments des buts.\n\nConclusion : Ce graphique vous montre comment les buts ont influencé le match.`,

    // Version 10 : Style interactif
    `Analyse des Buts : Découvrez les buts marqués et encaissés par ${match.home_team} et ${match.away_team} dans ce match. Voici ce que vous allez explorer :\n\n1. Buts Marqués : Les barres bleues montrent l'efficacité offensive. Plus c'est haut, plus c'est impressionnant !\n2. Buts Encaissés : Les barres rouges révèlent les faiblesses défensives. Une barre haute ? C'est le signe d'une défense en difficulté.\n3. Moments Forts : Les pics sur le graphique vous montrent quand les buts ont été marqués, pour revivre les moments clés.\n\nConclusion : Ce graphique interactif vous donne les clés pour comprendre comment les buts ont changé la donne.`,
  ];

  const adviceVersions = [
    // Version 1
    `Analysez les performances récentes : Avant de parier, examinez les cinq derniers matchs des deux équipes pour identifier des tendances.\n2. Considérez les blessures et suspensions : Les absences de joueurs clés peuvent grandement influencer le résultat.\n3. Utilisez les statistiques de possession : Une équipe avec une possession élevée a souvent plus de chances de marquer.\n\nConclusion : En suivant ces conseils, vous pouvez améliorer vos chances de succès dans vos paris.`,
    // Version 2
    `Surveillez les conditions météorologiques : Un temps pluvieux ou venteux peut affecter le style de jeu des équipes.\n2. Évaluez la motivation des équipes : Les équipes en lutte pour le titre ou contre la relégation peuvent performer différemment.\n3. Ne négligez pas les statistiques de tirs cadrés : Une équipe avec peu de tirs mais beaucoup de tirs cadrés est souvent plus dangereuse.\n\nConclusion : Une analyse approfondie peut vous aider à prendre des décisions plus éclairées.`,
    // Version 3
    `Comparez les classements : Une équipe en tête du classement a souvent un avantage psychologique.\n2. Analysez les confrontations directes : Les résultats précédents entre les deux équipes peuvent révéler des tendances.\n3. Tenez compte des facteurs externes : Les derbys ou les rivalités locales peuvent intensifier les performances.\n\nConclusion : Une approche stratégique peut maximiser vos chances de gagner.`,
    // Version 4
    `Analysez les performances à domicile et à l'extérieur : Certaines équipes sont plus fortes à domicile, tandis que d'autres excellent à l'extérieur.\n2. Tenez compte des statistiques de corners : Les équipes qui gagnent beaucoup de corners créent souvent plus d'occasions de but.\n3. Surveillez les changements tactiques : Un entraîneur qui change de formation ou de stratégie peut surprendre l'adversaire.\n\nConclusion : Une analyse approfondie des performances et des tactiques peut vous donner un avantage significatif.`,
    // Version 5
    `Évaluez la forme physique des joueurs : Une équipe en pleine forme physique a plus de chances de performer à son meilleur niveau.\n2. Considérez les statistiques de passes réussies : Une équipe avec un taux de passes élevé contrôle souvent le jeu.\n3. Ne sous-estimez pas l'impact des remplaçants : Les joueurs qui entrent en cours de match peuvent changer la donne.\n\nConclusion : En prenant en compte ces éléments, vous pouvez faire des paris plus éclairés.`,
    // Version 6
    `Analysez les performances des gardiens : Un gardien en forme peut sauver son équipe dans les moments critiques.\n2. Surveillez les statistiques de duels aériens : Les équipes qui dominent dans les duels aériens sont souvent plus dangereuses sur les corners et les coups francs.\n3. Tenez compte des conditions du terrain : Un terrain en mauvais état peut avantager certaines équipes.\n\nConclusion : Une analyse détaillée des aspects techniques peut vous aider à prendre de meilleures décisions.`,
    // Version 7
    `Observez les tendances de score : Certaines équipes ont tendance à marquer tôt, tandis que d'autres sont plus fortes en fin de match.\n2. Analysez les statistiques de fautes : Une équipe qui commet beaucoup de fautes peut être vulnérable aux coups de pied arrêtés.\n3. Considérez les enjeux du match : Les matchs à enjeux élevés (finales, barrages) peuvent produire des résultats imprévisibles.\n\nConclusion : En comprenant les tendances et les enjeux, vous pouvez ajuster vos paris en conséquence.`,
    // Version 8
    `Évaluez les performances des joueurs clés : Les stars de l'équipe peuvent souvent faire la différence dans les matchs serrés.\n2. Analysez les statistiques de possession dans le dernier tiers : Une équipe qui domine dans le dernier tiers du terrain crée plus d'occasions de but.\n3. Tenez compte des facteurs psychologiques : La pression ou la confiance peut influencer les performances des joueurs.\n\nConclusion : Une approche holistique incluant les aspects techniques et psychologiques peut maximiser vos chances de succès.`,
    // Version 9
    `Surveillez les statistiques de tirs hors cadre : Une équipe qui tire souvent mais mal peut être moins efficace qu'il n'y paraît.\n2. Analysez les performances en seconde mi-temps : Certaines équipes sont plus fortes après la pause.\n3. Considérez les statistiques de passes clés : Les passes décisives sont souvent un indicateur de créativité offensive.\n\nConclusion : En vous concentrant sur ces détails, vous pouvez identifier des opportunités de paris plus rentables.`,
    // Version 10
    `Évaluez les performances contre des équipes similaires : Comment l'équipe performe-t-elle face à des adversaires de même niveau ?\n2. Analysez les statistiques de pressing : Une équipe qui presse haut récupère souvent le ballon dans des zones dangereuses.\n3. Tenez compte des statistiques de transitions défensives : Une équipe qui se replie rapidement après une perte de balle est souvent plus solide défensivement.\n\nConclusion : Une analyse approfondie des styles de jeu et des tactiques peut vous donner un avantage concurrentiel.`,
  ];

  // Sélection aléatoire UNE FOIS au premier rendu
  const [randomVersion] = useState(
    () => versions[Math.floor(Math.random() * versions.length)]
  );
  const [randomAdvice] = useState(
    () => adviceVersions[Math.floor(Math.random() * adviceVersions.length)]
  );

  const [randomCompleteMatchStudyVersion] = useState(
    () =>
      completeMatchStudyVersions[
        Math.floor(Math.random() * completeMatchStudyVersions.length)
      ]
  );

  const [randomExactScoreVersion] = useState(
    () =>
      exactScoreProjectionVersions[
        Math.floor(Math.random() * exactScoreProjectionVersions.length)
      ]
  );

  const [randomPerformanceScoreVersion] = useState(
    () =>
      performancePredictionVersions[
        Math.floor(Math.random() * performancePredictionVersions.length)
      ]
  );

  const [randomTacticalAnalysisVersion] = useState(
    () =>
      tacticalAnalysisVersions[
        Math.floor(Math.random() * tacticalAnalysisVersions.length)
      ]
  );

  const generateAnalysis = (match, option) => {
    switch (option) {
      case "Analyse Avancée":
        return randomPerformanceScoreVersion(match);
      case "Prédictions Performances":
        return randomTacticalAnalysisVersion(match);
      case "Projection Score Exact":
        return randomExactScoreVersion(match);
      case "Étude Complète du Match":
        return randomCompleteMatchStudyVersion(match);
      default:
        return "Aucune analyse disponible pour cette option.";
    }
  };

  const analysisResult = generateAnalysis(match, option);

  useEffect(() => {
    if (currentIndex < analysisResult.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prevText) => prevText + analysisResult[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 50); // Vitesse d'écriture

      return () => clearTimeout(timeout);
    } else {
      // Une fois le texte complètement affiché, activez l'affichage des graphiques
      setShowCharts(true);
    }
  }, [currentIndex, analysisResult]);

  // Animation d'écriture pour le texte explicatif des graphiques
  useEffect(() => {
    if (chartIndex < randomVersion.length) {
      const timeout = setTimeout(() => {
        setChartText((prevText) => prevText + randomVersion[chartIndex]);
        setChartIndex((prevIndex) => prevIndex + 1);
      }, 40); // Vitesse d'écriture plus rapide

      return () => clearTimeout(timeout);
    }
  }, [chartIndex, randomVersion]);

  // Animation d'écriture pour les conseils
  useEffect(() => {
    if (adviceIndex < randomAdvice.length) {
      const timeout = setTimeout(() => {
        setAdviceText((prevText) => prevText + randomAdvice[adviceIndex]);
        setAdviceIndex((prevIndex) => prevIndex + 1);
      }, 50); // Vitesse d'écriture plus rapide

      return () => clearTimeout(timeout);
    } else {
      setAnimationFinished(true);
    }
  }, [adviceIndex, randomAdvice]);

  return (
    <div
      style={{
        maxHeight: "calc(100vh - 190px)",
        minHeight: "300px",
        overflowY: "auto",
      }}
    >
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <Col
            md={12}
            className="d-lg-flex justify-content-between align-items-center"
          >
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={onBack}
              className="d-flex align-items-center"
            >
              <ArrowLeft size={18} className="me-2" />
              Retour
            </Button>
            <h2 className="mb-0 text-danger fw-bold mt-lg-0 mt-3 text-lg-start text-center">
              Analyse terminée : découvrez les prédictions de l’IA !
            </h2>
            <div className="d-flex gap-2 mt-lg-0 mt-3 justify-content-between align-items-center">
              <Button variant="outline-danger" size="sm" onClick={onRestart}>
                Recommencer
              </Button>
              <Button variant="danger" size="sm" onClick={onClose}>
                Fermer l'IA
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          {/* Colonne de l'analyse IA */}
          <Col md={8}>
            <div
              className="shadow-sm border-0 p-4"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              <div>
                {loading ? (
                  <p style={{
                    opacity: visible ? 1 : 0,
                    transition: 'opacity 0.2s ease-in-out'
                  }} className="h4">
                    {visible &&
                      "Un instant, je rassemble les données pour analyse..."}
                  </p>
                ) : (
                  <>
                    <h4 className="mb-3">Voici le résultat de l’analyse :</h4>
                    <p>{displayedText}</p>
                  </>
                )}
              </div>

              {showCharts && (
                <div className="fade-in">
                  {/* Section des conseils pour les parieurs */}
                  <div className="mt-4">
                    <h5 className="text-danger">
                    Découvrez nos secrets pour des prédictions infaillibles
                    </h5>
                    <p
                      id="adviceText"
                      ref={adviceTextRef}
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {adviceText} <br />
                    </p>
                  </div>

                  <h5 className="mt-4 text-danger">
                  Transformez vos données en décisions gagnantes avec nos visualisations révolutionnaires
                  </h5>
                  <p style={{ whiteSpace: "pre-line" }}>{randomVersion}</p>
                  <InteractiveCharts match={match} />
                </div>
              )}

              <div
                className="border-0 mt-5"
                style={{ backgroundColor: "#fff", borderRadius: "1px" }}
              >
                <div
                  style={{
                    maxHeight: "500px",
                    overflowY: "auto",
                    marginBottom: "15px",
                  }}
                >
                  {chatMessages.map((message, index) => (
                    <div
                      key={index}
                      style={{
                        textAlign:
                          message.type === "question" ? "right" : "left",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          padding: "8px 12px",
                          borderRadius:
                            message.type === "question"
                              ? "12px 12px 0 12px"
                              : "12px 12px 12px 0",
                          backgroundColor:
                            message.type === "question" ? "#dc3545" : "#f8f9fa",
                          color: message.type === "question" ? "#fff" : "#000",
                        }}
                      >
                        {formatTextWithLineBreaks(message.content)}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Section des questions aléatoires */}
                {animationFinished && (
                  <>
                    <h6 className="mt-5 mb-3">
                    Boostez vos paris sportifs avec des stratégies gagnantes. Cliquez pour en savoir plus !
                    </h6>

                    {/* Section des sous-questions */}
                    {selectedSubQuestions.length > 0 && (
                      <>
                        <h6 className="mt-5 mb-3">
                          Envie d’aller encore plus loin ? Explorez ces
                          sous-questions pour affiner vos prédictions !
                        </h6>
                        {selectedSubQuestions.map((subQuestion, index) => (
                          <Button
                            key={index}
                            variant="outline-secondary"
                            size="sm"
                            className="mb-2 me-2"
                            onClick={() => handleQuestionClick(subQuestion)}
                            disabled={isAnswering} // Désactiver pendant l'animation
                          >
                            {subQuestion.question}
                          </Button>
                        ))}
                      </>
                    )}
                    
                    {randomQuestions.map((question, index) => (
                      <Button
                        key={index}
                        variant="outline-danger"
                        size="sm"
                        className="mb-2 me-2"
                        onClick={() => handleQuestionClick(question)}
                        disabled={isAnswering} // Désactiver pendant l'animation
                      >
                        {question.question}
                      </Button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Col>

          {/* Colonne du résumé */}
          <Col md={4}>
            <div
              className="shadow-sm border-0 p-4"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              <h4 className="mb-3">Résumé</h4>
              <p>
                <strong>Match :</strong> {match.home_team} vs {match.away_team}
              </p>
              <p>
                <strong>Date :</strong> {match.date.split("T")[0]}
              </p>
              <p>
                <strong>Option choisie :</strong> {option}
              </p>
            </div>

            {/* Nouvelle carte pour la comparaison des points */}
            <div
              className="shadow-sm border-0 mt-3"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              {showCharts && (
                <div className="fade-in">
                  <SpiralChart
                    homeTeam={match.home_team}
                    awayTeam={match.away_team}
                    homePoints={match.home_points}
                    awayPoints={match.away_points}
                    homePosition={match.home_position}
                    awayPosition={match.away_position}
                  />
                </div>
              )}{" "}
            </div>
          </Col>
        </Row>

        <style>
          {`
          .blinking-text {
            opacity: ${visible ? 1 : 0};
            transition: opacity 0.2s ease-in-out;
          }

          .fade-in {
                opacity: 0;
                animation: fadeIn 0.5s ease-in forwards;
            }

            @keyframes fadeIn {
                to {
                    opacity: 1;
                }
            }
        `}
        </style>
      </Container>
    </div>
  );
};

export default IAChatPage;
