import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Heart,
  Mail,
  Github,
  Twitter,
  Linkedin,
  PhoneCall,
  Gitlab,
} from "lucide-react";

const Footer = ({ onShowTerms }) => {
  const currentYear = new Date().getFullYear();

  const decryptName = () => {
    const encrypted = "Q2hhcmxpZSBBSE9HTEE=";
    return atob(encrypted);
  };

  return (
    <footer className="bg-light mt-5 py-4 border-top">
      <Container>
        <Row className="justify-content-between text-center text-md-start">
          {/* À propos */}
          <Col md={6} className="mb-4 mb-md-0">
            <h3 className="h6 mb-3 text-success">À propos de MatchSynce</h3>
            <p className="text-muted">
              Une application innovante d'analyse et de prédiction de matchs de
              football, utilisant des algorithmes avancés pour fournir des
              insights précis. Grâce à MatchSynceIA, profitez d’une intelligence
              artificielle qui analyse les matchs en temps réel, découvrez une
              messagerie dédiée pour échanger et débattre avec d'autres
              passionnés, et plongez dans MatchSynce Battle : un espace où vous
              pouvez pronostiquer sur les matchs, défier d'autres passionnés et
              prouver que vous êtes le meilleur.
            </p>
          </Col>

          {/* Contact */}
          <Col md={4} className="d-flex justify-content-center">
            <div>
              <h3 className="h5 mb-3 text-success">Contacts</h3>
              <div className="d-flex gap-4 justify-content-center justify-content-md-start">
                <a
                  href="mailto:ahoglacharlie1@gmail.com"
                  className="text-secondary hover-opacity"
                >
                  <Mail size={24} />
                </a>
                <a
                  href="https://github.com/Two2001"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary hover-opacity"
                >
                  <Github size={24} />
                </a>
                <a
                  href="https://gitlab.com/Two2001"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary hover-opacity"
                >
                  <Gitlab size={24} />
                </a>
                <a
                  href="https://www.linkedin.com/in/charlie-ahogla-5434421b8/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary hover-opacity"
                >
                  <Linkedin size={24} />
                </a>
                <a
                  href="tel:+22994630674"
                  className="text-secondary hover-opacity"
                >
                  <PhoneCall size={24} />
                </a>
              </div>
            </div>
          </Col>

          <Col
            md={2}
            className="text-center d-flex flex-column align-items-center mt-4 mt-md-0"
          >
            <img
              src="/matchsynce.png"
              alt="Logo de votre application"
              width="180"
              height="50"
              className="mb-3 mb-lg-2"
            />

            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onShowTerms();
              }}
              className="text-secondary text-decoration-none mt-2"
            >
              <b>Termes et Conditions</b>
            </a>
          </Col>
        </Row>

        {/* Copyright */}
        <Row className="mt-4 pt-4 border-top">
          <Col className="text-center">
            <p className="small text-muted mb-3">
              Avertissement : Le pari sportif est une activité à risque.
              Pratiquez de manière responsable, limitez vos mises et ne jouez
              pas au-delà de vos moyens. Une pratique excessive peut entraîner
              dépendance, difficultés financières et problèmes personnels.
            </p>
            <p className="text-muted mb-0 fw-bold">
            Version 0.2 - Bêta
            </p>
            <p className="mb-2 d-flex align-items-center justify-content-center">
              Développé avec <Heart className="text-danger mx-2" size={16} />{" "}
              par <b className="ms-2">{decryptName()}</b>
            </p>
            <p className="text-muted mb-0">
              © {currentYear} MatchSynce. Tous droits réservés.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
