import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ArrowLeft } from "lucide-react";

const OptionSelectionPage = ({ match, onOptionSelect, onBack }) => {
  const options = [
    {
      id: 1,
      title: "Analyse Avancée",
      icon: "📊",
      description:
        "Accédez à des analyses poussées des équipes, des performances récentes et des facteurs clés pouvant influencer chaque match.",
    },
    {
      id: 2,
      title: "Prédictions Performances",
      icon: "🔮",
      description:
        "Obtenez des prédictions détaillées sur les performances des joueurs, les statistiques clés et les résultats probables.",
    },
    {
      id: 3,
      title: "Projection Score Exact",
      icon: "🎯",
      description:
        "Découvrez une projection précise du score final basée sur les données historiques et les performances récentes.",
    },
    {
      id: 4,
      title: "Étude Complète du Match",
      icon: "🧠",
      description:
        "Plongez dans une analyse approfondie incluant les forces, les faiblesses et les opportunités pour chaque équipe.",
    },
  ];

  return (
    <div style={{
      maxHeight: "calc(100vh - 190px)",
      minHeight: "300px",
      overflowY: "auto",
    }}>
      <Container className="mt-lg-5 mt-2">
        <Row className="justify-content-center mb-4">
          <Col
            md={12}
            className="d-lg-flex justify-content-between align-items-center"
          >
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={onBack}
              className="d-flex align-items-center"
            >
              <ArrowLeft size={18} className="me-2" />
              Retour
            </Button>
            <h2 className="mb-0 text-danger fw-bold mt-lg-0 mt-3 text-lg-start text-center">
              Choisissez une option pour aller plus loin !
            </h2>
            <div className="invisible">
              <Button variant="outline-light" size="sm">
                Invisible
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          {options.map((option) => (
            <Col key={option.id} xs={12} sm={6} md={3} className="mb-4">
              <div
                className="h-100 shadow-sm border-0 option-card p-3"
                onClick={() => onOptionSelect(option.title)}
                style={{
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 20px rgba(0,0,0,0.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 5px 15px rgba(0,0,0,0.05)";
                }}
              >
                <div className="text-center mb-3">
                  <span className="fs-1">{option.icon}</span>
                </div>
                <h5 className="text-center mb-3 fw-bold">{option.title}</h5>
                <p className="text-center text-muted">{option.description}</p>
                <div className="text-center mt-3">
                  <small className="text-muted">
                    Cliquez pour sélectionner
                  </small>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default OptionSelectionPage;
