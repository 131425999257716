import React, { useState, useEffect } from "react";
import {
  Container,
  Tabs,
  Tab,
  Card,
  Row,
  Col,
  Badge,
  Spinner,
  Button,
  Alert,
  Modal,
  Form,
} from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import {
  Trophy,
  Target,
  ChartLine,
  Home,
  ArrowBigLeft,
  RotateCw,
  Eye,
  EyeOff,
} from "lucide-react";
import MatchDetailsComponent from "./MatchDetailsComponent";
import MatchResultsComponent from "./MatchResultsComponent";
import LeagueStandings from "./LeagueStandings";
import Footer from "./Footer";
import TeamSearch from "./TeamSearch";
import ResultsPending from "./ResultsPending";
import { OfflineHandler } from "./OfflineHandler";
import LandingPage from "./LandingPage";
import TermsAndConditions from "./TermsAndConditions";
import MatchNotAvailable from "./MatchNotAvailable";

import io from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import MatchPreview from "./MatchPreview";
import LandingNavbar from "./LandingNavbar";
import LandingNavbarApp from "./LandingNavbarApp";
import MatchScoreComponent from "./MatchScoreComponent";
import PlayerStats from "./PlayerStats";
import WelcomePage from "./MatchSynceIA/WelcomePage";
import DateSelectionPage from "./MatchSynceIA/DateSelectionPage";
import MatchSelectionPage from "./MatchSynceIA/MatchSelectionPage";
import OptionSelectionPage from "./MatchSynceIA/OptionSelectionPage";
import IAChatPage from "./MatchSynceIA/IAChatPage";
import HomePage from "./MatchSynceIA/HomePage";

const socket = io("https://matchsynce.com:5001");

const FootballIcons = {
  goal: "⚽",
  trophy: "🏆",
  fire: "🔥",
  chart: "📊",
  stats: "📈",
  clock: "⏱️",
  flag: "🚩",
  team: "👥",
};

// Styles pour les animations
const fadeTransition = {
  enter: "transition-opacity duration-500 ease-in-out",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-opacity duration-500 ease-in-out",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

const ProfessionalLoader = ({ children, delay = 3000 }) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  if (showLoader) {
    return (
      <div className="fade show">
        <Container className="d-flex flex-column justify-content-center align-items-center vh-100 position-relative">
          <div className="text-center" style={{ lineHeight: 0 }}>
            <img
              src="/matchsynce.png"
              alt="Logo de votre application"
              width="370"
              className="d-block"
              style={{
                height: "auto",
                marginBottom: "30px",
              }}
            />

            <div>
              <Spinner
                animation="border"
                variant="success"
                style={{
                  width: "4rem",
                  height: "4rem",
                  marginTop: 0,
                }}
              />
              <h4 className="text-success" style={{ marginTop: "10px" }}>
                Chargement des données... {FootballIcons.goal}
              </h4>
              <p className="text-muted" style={{ marginTop: "5px" }}>
                Veuillez patienter
              </p>
            </div>
          </div>

          <div
            className="position-absolute bottom-0 text-center w-100 pb-2 text-muted"
            style={{ fontSize: "0.9rem" }}
          >
            Version 0.1 - Bêta
          </div>
        </Container>
      </div>
    );
  }

  return <div className="fade show">{children}</div>;
};

function scalePercentage(rawPercentage) {
  const maxAllowedPercentage = 10000;
  const scaledPercentage = Math.min(
    (rawPercentage / Math.max(rawPercentage, 100)) * 100,
    maxAllowedPercentage
  );

  const getProgressBar = (percent) => {
    percent = Math.max(0, percent);

    const greenPart = Math.min(percent, 100);
    const redPart = Math.max(0, Math.min(percent - 100, 100));

    return {
      text: `${
        greenPart > 0 ? "🟢" + "▓".repeat(Math.floor(greenPart / 5)) : ""
      }${redPart > 0 ? "🔴" + "▓".repeat(Math.floor(redPart / 5)) : ""}`,
      value: scaledPercentage.toFixed(2),
    };
  };

  return {
    raw: rawPercentage.toFixed(2),
    scaled: scaledPercentage.toFixed(2),
    progressBar: getProgressBar(rawPercentage),
  };
}

function RefreshButton({ onRefresh }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <Button
      variant="outline-primary"
      onClick={onRefresh}
      disabled={!isOnline}
      className="mb-3"
    >
      {isOnline ? "🔄 Rafraîchir les données" : "⚠️ Hors-ligne"}
    </Button>
  );
}

function MainContentWrapper({ onReturnHome }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fade show">
      <MainContent onReturnHome={onReturnHome} />
    </div>
  );
}

const MainContent = ({ onReturnHome, onAnalyticsClick }) => {
  const [data, setData] = React.useState({ match_overview: [] }); // Initialiser avec une structure vide
  const [predictionAnalysis, setPredictionAnalysis] = React.useState(null);
  const [league, setLeague] = React.useState("liga");
  const [fileExists, setFileExists] = useState(false);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [showGraph, setShowGraph] = useState(true);
  const [activeTab, setActiveTab] = useState("apercu-matchs");
  const [activeTab1, setActiveTab1] = useState("apercu-matchs");

  const [showWeeklyCombinedModal, setShowWeeklyCombinedModal] = useState(false);
  const [numberOfMatches, setNumberOfMatches] = useState(5); // Nombre de matchs par défaut
  const [combinedEvents, setCombinedEvents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCards, setExpandedCards] = useState([]);
  const [showBarChart, setShowBarChart] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const [currentPage, setCurrentPage] = useState("matchSelection");
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showWelcome, setShowWelcome] = useState(false);

  const handleToggleCard = (matchId) => {
    setExpandedCards((prev) => {
      if (prev.includes(matchId)) {
        return prev.filter((id) => id !== matchId);
      } else {
        return [...prev, matchId];
      }
    });
  };

  const ligues = [
    { code: "champions_league", nom: "Champions League", flag: "🇪🇺" },
    { code: "europa_league", nom: "Europa League", flag: "🇪🇺" },
    { code: "conference_league", nom: "Conference League", flag: "🇪🇺" },
    { code: "liga", nom: "Liga Espagnole", flag: "🇪🇸" },
    { code: "bundesliga", nom: "Bundesliga", flag: "🇩🇪" },
    { code: "premier_league", nom: "Premier League", flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿" },
    { code: "serie_a", nom: "Serie A", flag: "🇮🇹" },
    { code: "ligue_1", nom: "Ligue 1", flag: "🇫🇷" },
  ];

  function formatLeagueName(league) {
    switch (league) {
      case "bundesliga":
        return "🇩🇪 Bundesliga";
      case "liga":
        return "🇪🇸 Liga";
      case "premier_league":
        return "🏴󠁧󠁢󠁥󠁮󠁧󠁿 Premier League";
      case "serie_a":
        return "🇮🇹 Série A";
      case "ligue_1":
        return "🇫🇷 Ligue 1";
      case "champions_league":
        return "🇪🇺 Champions League";
      case "europa_league":
        return "🇪🇺 Europa League";
      case "conference_league":
        return "🇪🇺 Conference League";
      default:
        return league;
    }
  }

  const shortenTeamName = (name) => {
    if (window.innerWidth <= 768 && name.length > 10) {
      return name.substring(0, 10) + "..."; // Tronquer le nom à 10 caractères
    }
    return name;
  };

  // React.useEffect(() => {
  //   const checkFileExistence = async () => {
  //     try {
  //       const response = await fetch("prediction_analysis.json");
  //       setFileExists(response.ok);
  //     } catch (error) {
  //       setFileExists(false);
  //     }
  //   };

  //   checkFileExistence();

  //   Promise.all([
  //     fetch(`/${league}_predictions_viz.json`),
  //     fetch(`prediction_analysis.json`),
  //   ])
  //     .then(async ([matchesRes, analysisRes]) => {
  //       const matchesData = await matchesRes.json();
  //       const analysisData = await analysisRes.json();

  //       setData(matchesData);
  //       setPredictionAnalysis(analysisData);
  //       // Initialiser les matchs filtrés avec les nouveaux matchs
  //       const processedMatches = processMatchData(
  //         matchesData.match_overview || []
  //       );
  //       setFilteredMatches(processedMatches);
  //     })
  //     .catch((error) => console.error("Error loading data:", error));
  // }, [league]);

  React.useEffect(() => {
    const checkFileExistence = async () => {
      try {
        // Vérifier d'abord le cache
        const cachedAnalysis = localStorage.getItem("prediction_analysis");
        if (cachedAnalysis) {
          setFileExists(true);
          return;
        }

        // Si pas dans le cache, vérifier le réseau
        const response = await fetch("prediction_analysis.json");
        setFileExists(response.ok);
      } catch (error) {
        // Vérifier une dernière fois le cache en cas d'erreur réseau
        const cachedAnalysis = localStorage.getItem("prediction_analysis");
        setFileExists(!!cachedAnalysis);
      }
    };

    checkFileExistence();

    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Vérifier si les données du localStorage doivent être supprimées
        const lastUpdate = localStorage.getItem("lastDataUpdate");
        if (lastUpdate) {
          const lastUpdateTime = new Date(lastUpdate).getTime();
          const now = new Date().getTime();
          const timeDiff = now - lastUpdateTime;

          // Si plus de 24h (24 * 60 * 60 * 1000 millisecondes), on efface le cache
          if (timeDiff > 24 * 60 * 60 * 1000) {
            localStorage.removeItem(`${league}_data`);
            localStorage.removeItem("prediction_analysis");
            localStorage.removeItem("lastDataUpdate");
            console.log("Les données du cache ont été supprimées après 24h.");
          }
        }

        // Essayer de charger les données depuis le réseau
        const [matchesRes, analysisRes] = await Promise.all([
          fetch(`/${league}_predictions_viz.json`),
          fetch(`prediction_analysis.json`),
        ]);

        const matchesData = await matchesRes.json();
        const analysisData = await analysisRes.json();

        // Vérification si les fichiers sont vides
        const isMatchesDataEmpty =
          !matchesData || Object.keys(matchesData).length === 0;
        const isAnalysisDataEmpty =
          !analysisData || Object.keys(analysisData).length === 0;

        // Stockage local si les données existent
        if (!isMatchesDataEmpty) {
          localStorage.setItem(`${league}_data`, JSON.stringify(matchesData));
        }
        if (!isAnalysisDataEmpty) {
          localStorage.setItem(
            "prediction_analysis",
            JSON.stringify(analysisData)
          );
        }
        localStorage.setItem("lastDataUpdate", new Date().toLocaleString());

        setData(isMatchesDataEmpty ? "ResultPending" : matchesData);
        setPredictionAnalysis(
          isAnalysisDataEmpty ? "ResultPending" : analysisData
        );
      } catch (error) {
        console.error(
          "Erreur réseau, tentative de charger depuis le cache:",
          error
        );

        const cachedMatches = localStorage.getItem(`${league}_data`);
        const cachedAnalysis = localStorage.getItem("prediction_analysis");

        if (cachedMatches) {
          setData(JSON.parse(cachedMatches));
        } else {
          setData("ResultPending");
        }

        if (cachedAnalysis) {
          setPredictionAnalysis(JSON.parse(cachedAnalysis));
        } else {
          setPredictionAnalysis("ResultPending");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [league]);

  const processMatchData = (matchOverview) => {
    return matchOverview.map((match, index) => {
      const homeRawProbability = match.win_probability;
      const awayRawProbability = 100 - match.win_probability;

      const homeScaled = scalePercentage(homeRawProbability);
      const awayScaled = scalePercentage(awayRawProbability);

      let matchResult, halfTimePrediction;
      if (homeRawProbability > 50) {
        matchResult = `${match.home_team} `;
        halfTimePrediction = `${match.home_team} (probable)`;
      } else if (awayRawProbability > 50) {
        matchResult = `${match.away_team} `;
        halfTimePrediction = `${match.away_team} (probable)`;
      } else {
        matchResult = "Nul (équilibré)";
        halfTimePrediction = "Indécis";
      }

      return {
        ...match,
        uniqueId:
          `match-${index}-${match.home_team}-${match.away_team}-${match.date}-${match.expectedGoals}`.replace(
            /[^a-zA-Z0-9-]/g,
            ""
          ),
        name: `${match.home_team} vs ${match.away_team}`,
        expectedGoals: match.expected_goals,
        winProbabilities: {
          home: {
            raw: homeScaled.raw,
            scaled: homeScaled.scaled,
            bar: homeScaled.progressBar.text,
          },
          away: {
            raw: awayScaled.raw,
            scaled: awayScaled.scaled,
            bar: awayScaled.progressBar.text,
          },
        },
        date: match.date
          ? new Date(match.date).toLocaleString("fr-FR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : "Date non spécifiée",
        matchResult,
        halfTimePrediction,
        home_team: match.home_team,
        away_team: match.away_team,
        home_position: match.home_position,
        home_points: match.home_points,
        home_form: match.home_form,
        home_goals: match.home_goals,
        home_conceded: match.home_conceded,
        away_position: match.away_position,
        away_points: match.away_points,
        away_form: match.away_form,
        away_goals: match.away_goals,
        away_conceded: match.away_conceded,
        win_probability: match.win_probability,
        expected_goals: match.expected_goals,
        over_2_5_prob: match.over_2_5_prob,
        btts_prob: match.btts_prob,
      };
    });
  };

  // Traiter les données actuelles
  const aperçuMatchs = processMatchData(data.match_overview || []);

  // useEffect(() => {
  //   if (aperçuMatchs.length > 0) {
  //     const events = createCombinedBet(aperçuMatchs);
  //     setCombinedEvents(events);
  //   }
  // }, [aperçuMatchs]);

  if (data === "ResultPending" || predictionAnalysis === "ResultPending") {
    return <MatchNotAvailable onReload={() => setLeague("liga")} />;
  }

  // Ajoutez une gestion d'erreur et de chargement
  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        <Alert.Heading>Erreur</Alert.Heading>
        <p>{error}</p>
      </Alert>
    );
  }

  if (!predictionAnalysis) {
    return <div>Chargement des données... {FootballIcons.goal}</div>;
  }

  const getTopMatches = (matches, count = 5) => {
    return matches
      .sort((a, b) => b.win_probability - a.win_probability)
      .slice(0, count);
  };

  const getMostProbableEvent = (match) => {
    const events = [
      {
        type: "1X2",
        probability: match.win_probability,
        description: `Victoire de ${match.home_team}`,
      },
      {
        type: "Double Chance",
        probability: match.win_probability > 50 ? 90 : 50, // Exemple simplifié
        description: `Double chance pour ${match.home_team} ou match nul`,
      },
      {
        type: "Over/Under 2.5",
        probability: match.over_2_5_prob,
        description: `Over 2.5 buts`,
      },
      {
        type: "BTTS",
        probability: match.btts_prob,
        description: `Les deux équipes marquent`,
      },
    ];

    return events.sort((a, b) => b.probability - a.probability)[0];
  };

  const createCombinedBet = (matches) => {
    return matches.map((match) => {
      const event = getMostProbableEvent(match);
      return {
        match: `${match.home_team} vs ${match.away_team}`,
        event: event.type,
        date: match.date,
        probability: event.probability,
        description: event.description,
        league: match.league,
      };
    });
  };

  //"Combiné de la semaine"

  //console.log("combinedEvents: ", combinedEvents)
  const getMatchesByLeague = (leagueCode) => {
    if (leagueCode === "all") {
      // Retourne tous les événements si aucune ligue n'est sélectionnée
      return combinedEvents;
    }
    // Filtre les événements par ligue
    return combinedEvents.filter((event) => event.league === leagueCode);
  };

  const filterMatchesByCount = (matches, count) => {
    return matches
      .sort((a, b) => b.win_probability - a.win_probability)
      .slice(0, count);
  };

  // Utilisez useEffect pour générer les événements une fois que les matchs sont chargés
  //  useEffect(() => {
  //   if (aperçuMatchs.length > 0) {
  //     const events = createCombinedBet(aperçuMatchs);
  //     setCombinedEvents(events);
  //   }
  // }, [aperçuMatchs]);

  return (
    <>
      <ProfessionalLoader show={isLoading}>
        <OfflineHandler>
          {!isLoading && (
            <>
              <LandingNavbarApp />
              <Container className="mt-5 pt-4 bg-light">
                <Row className="mb-4 align-items-center">
                  <Col>
                    <Card className="shadow-lg">
                      <Card.Header className="d-flex flex-column flex-md-row justify-content-between align-items-center bg-success text-white p-3">
                        <div className="d-flex align-items-center col-lg-8 mb-3 mb-md-0">
                          <Button
                            variant="link"
                            className="text-info me-lg-3"
                            onClick={onReturnHome}
                          >
                            <ArrowBigLeft size={32} />
                          </Button>
                          <h2 className="mb-0 fw-bold fs-4 fs-md-2 me-2">
                            Prédictions de Matchs
                          </h2>
                          <Trophy
                            className="text-warning d-none d-lg-block"
                            size={30}
                          />
                          <Target
                            className="ms-2 text-white d-none d-lg-block"
                            size={30}
                          />
                        </div>
                        <div className="text-center w-100">
                          <small className="text-white-100 d-block">
                            Analyse Statistique & Pronostics Sportifs
                          </small>
                        </div>
                      </Card.Header>
                    </Card>
                  </Col>
                  <Col md="auto">
                    {/* <Row className="mb-4 align-items-center"> */}
                    <Row className="mb-4">
                      <Col>
                        {/* <Button
                          variant="primary"
                          className="mb-3"
                          onClick={() => setShowWeeklyCombinedModal(true)}
                        >
                          Combiné de la semaine
                        </Button> */}
                        <select
                          className="form-select bg-primary text-light"
                          value={league}
                          onChange={(e) => setLeague(e.target.value)}
                        >
                          {ligues.map((l) => (
                            <option
                              key={l.code}
                              value={l.code}
                              className="bg-light text-dark"
                            >
                              {l.flag} {l.nom}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <p className="col-lg-10 text-lg-end text-center text-danger smooth-blink">
                    <b className="me-3">
                      Note : Les paris sportifs sont personnels et relèvent de
                      la responsabilité individuelle.
                    </b>
                  </p>

                  <style>
                    {`
                .smooth-blink {
                  animation: smoothBlink 2s ease-in-out infinite;
                  position: relative;
                }
                
                .smooth-blink::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                }
                
                @keyframes smoothBlink {
                  0% { opacity: 1; }
                  50% { opacity: 0.4; }
                  100% { opacity: 1; }
                }
                
                
              `}
                  </style>
                </Row>

                <div className="d-lg-flex justify-content-between align-items-center">
                  <h3 className="bg-light text-dark">
                    {formatLeagueName(league)}
                  </h3>
                  {activeTab === "apercu-matchs" && (
                    <TeamSearch
                      matchList={aperçuMatchs}
                      onFilterChange={(filtered) => {
                        setFilteredMatches(filtered);
                        setShowGraph(filtered.length === aperçuMatchs.length);
                      }}
                    />
                  )}
                </div>

                <style>
                  {`
            .nav-pills {
              flex-wrap: nowrap !important;
              overflow-x: auto !important;
              overflow-y: hidden;
              -webkit-overflow-scrolling: touch;
              -ms-overflow-style: -ms-autohiding-scrollbar;
              white-space: nowrap;
              scrollbar-width: none;
            }
            .nav-pills::-webkit-scrollbar {
              display: none;
            }
            .nav-item {
              float: none !important;
              display: inline-block !important;
            }
          `}
                </style>

                <Tabs
                  defaultActiveKey="performance-equipes"
                  className="mb-3 mt-3 nav-pills"
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Tab
                    eventKey="performance-equipes"
                    title={`${FootballIcons.stats} Performance des Équipes`}
                  >
                    <LeagueStandings league={league} />
                  </Tab>

                  <Tab
                    eventKey="players-stats"
                    title={`${FootballIcons.stats} Statistiques des joueurs`}
                  >
                    <PlayerStats league={league} />
                  </Tab>

                  <Tab
                    eventKey="apercu-matchs"
                    title={`${FootballIcons.flag} Analyses et Pronostics`}
                  >
                    <Card className="shadow-sm">
                      <Card.Body>
                        {!showGraph && (
                          <div className="text-center mb-4">
                            <Button
                              variant="success"
                              onClick={() => setShowGraph(true)}
                              className="d-inline-flex align-items-center gap-2"
                            >
                              <Eye size={20} />
                              Afficher le graphique
                            </Button>
                          </div>
                        )}
                        {showGraph && (
                          <div className="text-center mb-4 d-flex justify-content-center gap-2">
                            {/* Icône pour pivoter le graphique */}
                            {/* <Button
                              variant="link"
                              className=" m-2"
                              onClick={() => setIsRotated(!isRotated)}
                              style={{ zIndex: 1 }}
                            >
                              <RotateCw size={20} /> 
                            </Button> */}
                            <Button
                              variant="success"
                              onClick={() => setShowGraph(false)}
                              className="d-inline-flex align-items-center gap-2 fs-6 fs-lg-0"
                            >
                              <EyeOff size={20} />
                              Cacher le graphique
                            </Button>
                            <Button
                              variant="secondary"
                              onClick={() => setShowBarChart(!showBarChart)}
                              className="d-inline-flex align-items-center gap-2 fs-6 fs-lg-0"
                            >
                              <ChartLine size={20} />
                              {showBarChart
                                ? "Graphique en courbe"
                                : "Sous un autre angle"}
                            </Button>
                          </div>
                        )}
                        {showGraph &&
                          (showBarChart ? (
                            <ResponsiveContainer width="100%" height={500}>
                              <BarChart data={aperçuMatchs}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="name"
                                  angle={-45}
                                  textAnchor="end"
                                  interval={0}
                                  height={200}
                                />
                                <YAxis domain={[0, 100]} />
                                <Tooltip
                                  formatter={(value, name) => {
                                    switch (name) {
                                      case "expectedGoals":
                                        return [`${value}`, "Buts Attendus"];
                                      case "winProbabilities.home":
                                        return [
                                          `${value}%`,
                                          "Prob. Victoire Domicile",
                                        ];
                                      case "winProbabilities.away":
                                        return [
                                          `${value}%`,
                                          "Prob. Victoire Extérieur",
                                        ];
                                      default:
                                        return [`${value}`, ""];
                                    }
                                  }}
                                />
                                <Legend
                                  payload={[
                                    {
                                      value: "Buts Attendus",
                                      type: "rect",
                                      color: "#8a2be2",
                                    },
                                    {
                                      value: "Prob. Victoire Domicile",
                                      type: "rect",
                                      color: "#32cd32",
                                    },
                                    {
                                      value: "Prob. Victoire Extérieur",
                                      type: "rect",
                                      color: "#ff4500",
                                    },
                                  ]}
                                />
                                <Bar
                                  dataKey="expectedGoals"
                                  fill="#8a2be2"
                                  name="expectedGoals"
                                />
                                <Bar
                                  dataKey="winProbabilities.home.raw"
                                  fill="#32cd32"
                                  name="winProbabilities.home"
                                />
                                <Bar
                                  dataKey="winProbabilities.away.raw"
                                  fill="#ff4500"
                                  name="winProbabilities.away"
                                />
                              </BarChart>
                            </ResponsiveContainer>
                          ) : (
                            <ResponsiveContainer width="100%" height={500}>
                              <LineChart data={aperçuMatchs}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="name"
                                  angle={-45}
                                  textAnchor="end"
                                  interval={0}
                                  height={200}
                                />
                                <YAxis domain={[0, 100]} />
                                <Tooltip
                                  formatter={(value, name) => {
                                    switch (name) {
                                      case "expectedGoals":
                                        return [`${value}`, "Buts Attendus"];
                                      case "winProbabilities.home":
                                        return [
                                          `${value}%`,
                                          "Prob. Victoire Domicile",
                                        ];
                                      case "winProbabilities.away":
                                        return [
                                          `${value}%`,
                                          "Prob. Victoire Extérieur",
                                        ];
                                      default:
                                        return [`${value}`, ""];
                                    }
                                  }}
                                />
                                <Legend
                                  payload={[
                                    {
                                      value: "Buts Attendus",
                                      type: "line",
                                      color: "#8a2be2",
                                    },
                                    {
                                      value: "Prob. Victoire Domicile",
                                      type: "line",
                                      color: "#32cd32",
                                    },
                                    {
                                      value: "Prob. Victoire Extérieur",
                                      type: "line",
                                      color: "#ff4500",
                                    },
                                  ]}
                                />
                                <Line
                                  type="monotone"
                                  dataKey="expectedGoals"
                                  stroke="#8a2be2"
                                  activeDot={{ r: 8 }}
                                  name="expectedGoals"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="winProbabilities.home.raw"
                                  stroke="#32cd32"
                                  name="winProbabilities.home"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="winProbabilities.away.raw"
                                  stroke="#ff4500"
                                  name="winProbabilities.away"
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          ))}
                        <Card.Text className="mt-3">
                          <h4 className="text-success">
                            {FootballIcons.goal} Détails et Analyses Complets
                            des Matchs
                          </h4>
                          <Tabs
                            defaultActiveKey="textual-analysis"
                            className="mb-3 mt-3 nav-pills"
                            onSelect={(k) => setActiveTab1(k)}
                          >
                            <Tab
                              eventKey="textual-analysis"
                              title={`${FootballIcons.stats} Tendances des Matchs`}
                            >
                              <Row>
                                {(filteredMatches.length > 0
                                  ? filteredMatches
                                  : aperçuMatchs
                                ).map((match) => (
                                  <MatchPreview
                                    key={match.uniqueId}
                                    match={{ ...match, id: match.uniqueId }}
                                    expandedCards={expandedCards}
                                    onToggleCard={handleToggleCard}
                                    matchData={match}
                                  />
                                ))}
                              </Row>
                            </Tab>
                            <Tab
                              eventKey="prediction"
                              title={`${FootballIcons.flag} Prédiction & Statistiques Clés`}
                            >
                              <Row>
                                {(filteredMatches.length > 0
                                  ? filteredMatches
                                  : aperçuMatchs
                                ).map((match) => (
                                  <MatchDetailsComponent
                                    key={match.uniqueId}
                                    match={{ ...match, id: match.uniqueId }}
                                    expandedCards={expandedCards}
                                    onToggleCard={handleToggleCard}
                                    result={predictionAnalysis.analyses.find(
                                      (a) => a.match === match.name
                                    )}
                                  />
                                ))}
                              </Row>
                            </Tab>
                            <Tab
                              eventKey="score"
                              title={`${FootballIcons.flag} Prédiction Score Exacte`}
                            >
                              <Row>
                                {(filteredMatches.length > 0
                                  ? filteredMatches
                                  : aperçuMatchs
                                ).map((match) => (
                                  <MatchScoreComponent
                                    key={match.uniqueId}
                                    match={{ ...match, id: match.uniqueId }}
                                    expandedCards={expandedCards}
                                    onToggleCard={handleToggleCard}
                                    result={predictionAnalysis.analyses.find(
                                      (a) => a.match === match.name
                                    )}
                                  />
                                ))}
                              </Row>
                            </Tab>

                            <Tab
                              eventKey="combined-bet"
                              title={`${FootballIcons.trophy} Combinée`}
                            >
                              <Card className="shadow-lg border-0">
                                <Card.Body className="p-4">
                                  <h4 className="text-success mb-4">
                                    {FootballIcons.goal} Pari Combiné pour{" "}
                                    {formatLeagueName(league)}
                                  </h4>
                                  <Row>
                                    {createCombinedBet(
                                      getTopMatches(aperçuMatchs)
                                    ).map((bet, index) => (
                                      <Col key={index} md={6} className="mb-4">
                                        <Card className="h-100 shadow-sm border-0">
                                          <Card.Body className="d-flex flex-column justify-content-between">
                                            <div>
                                              <Card.Title className="fw-bold text-primary">
                                                {bet.match}
                                              </Card.Title>
                                              <span>{bet.date}</span>
                                              <Card.Text className="text-muted">
                                                <span className="badge bg-success me-2">
                                                  {bet.probability}%
                                                </span>
                                                <span className="badge bg-secondary me-2">
                                                  {bet.event}
                                                </span>
                                                <br />
                                                <small>{bet.description}</small>
                                              </Card.Text>
                                            </div>
                                            {/* <Button
                                              variant="outline-primary"
                                              size="sm"
                                              onClick={() =>
                                                handleCustomizeBet(bet)
                                              }
                                            >
                                              Personnaliser ce pari
                                            </Button> */}
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    ))}
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Tab>
                          </Tabs>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Tab>
                  {/* {fileExists || localStorage.getItem("prediction_analysis") ? (
                  <Tab
                    eventKey="resultats"
                    title={`${FootballIcons.trophy} Résultats et Analyses`}
                  >
                    <Card className="shadow-sm">
                      <Card.Header>
                        <h4 className="text-success mb-0">
                          {FootballIcons.chart} Analyse des Prédictions vs
                          Résultats
                        </h4>
                      </Card.Header>
                      <Card.Body>
                        {predictionAnalysis &&
                        predictionAnalysis.analyses &&
                        predictionAnalysis.analyses.length > 0 ? (
                          <Row>
                            {aperçuMatchs.map((match) => (
                              <MatchResultsComponent
                                key={match.name}
                                match={match}
                                result={predictionAnalysis.analyses.find(
                                  (a) => a.match === match.name
                                )}
                              />
                            ))}
                          </Row>
                        ) : (
                          <ResultsPending />
                        )}
                      </Card.Body>
                    </Card>
                  </Tab>
                ) : (
                  <Tab
                    eventKey="resultats-non-disponibles"
                    title={`${FootballIcons.trophy} Résultats (Non disponibles)`}
                    disabled
                  >
                    <p>Les résultats ne sont pas encore disponibles.</p>
                  </Tab>
                )} */}
                </Tabs>
              </Container>
            </>
          )}
        </OfflineHandler>
      </ProfessionalLoader>
    </>
  );
};

function VisualisateurPrevisions() {
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleShowAnalytics = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setShowAnalytics(true);
      setIsAnimating(false);
    }, 300);
  };

  const handleReturnHome = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setShowAnalytics(false);
      setIsAnimating(false);
    }, 300);
  };

  const showToast = (message, type = "success") => {
    toast[type](message, {
      duration: 5000,
      position: "top-right",
    });
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      showToast(`${data.title} - ${data.message}`, "success");
    });

    return () => {
      socket.off("notification");
    };
  }, []);

  if (showTerms) {
    return (
      <div>
        <TermsAndConditions />
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            className="mb-2"
            onClick={() => setShowTerms(false)}
          >
            Revenir sur l'acceuil
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`transition-container ${isAnimating ? "fade" : "fade show"}`}
    >
      {!showAnalytics ? (
        <div className="fade show">
          <LandingPage onAnalyticsClick={handleShowAnalytics} />
        </div>
      ) : (
        <MainContentWrapper onReturnHome={handleReturnHome} />
      )}
      {/* Passez la fonction au Footer */}
      <Footer onShowTerms={() => setShowTerms(true)} />

      <style>
        {`
          .transition-container {
            transition: opacity 0.3s ease-in-out;
          }
          .fade {
            opacity: 0;
          }
          .fade.show {
            opacity: 1;
          }
        `}
      </style>
    </div>
  );
}

export default VisualisateurPrevisions;
