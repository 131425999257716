import React, { useState, useEffect, useRef } from "react";
import {
  Send,
  User,
  Smile,
  MessageSquare,
  Shield,
  Clock,
  X,
  Info,
  ArrowLeft,
  MoreVertical,
  Search,
  LogOut,
  Image,
  Download,
  Mic,
  Paperclip,
  Video,
  ArrowDown,
  Italic,
} from "lucide-react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Dropdown } from "bootstrap";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const Chat = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [ws, setWs] = useState(null);
  const [token, setToken] = useState(null);
  const [isModerator, setIsModerator] = useState(false);
  const [moderationQueue, setModerationQueue] = useState([]);
  const messagesEndRef = useRef(null);
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const chatContainerRef = useRef(null);
  const [privateMessages, setPrivateMessages] = useState({});
  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const emojiPickerRef = useRef(null);

  // Fonction pour obtenir l'URL du serveur
  const getServerUrl = () => {
    const hostname = window.location.hostname;
    const port = import.meta.env?.VITE_SERVER_PORT || 5003;
    return hostname === "matchsynce.com"
      ? `https://matchsynce.com:${port}`
      : `https://${hostname}:${port}`;
  };

  const handleLogout = () => {
    localStorage.removeItem("chatToken");
    localStorage.removeItem("chatUsername");
    localStorage.removeItem("chatIsModerator");
    setToken(null);
    setUsername("");
    setIsModerator(false);
    setIsRegistered(false);
    setMessages([]);
    setPrivateMessages({});
    if (ws) {
      ws.close();
    }
  };

  const connectWebSocket = () => {
    if (!token) return;

    const serverUrl = getServerUrl();
    const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const wsUrl = `${wsProtocol}//${new URL(serverUrl).host}/ws?token=${token}`;

    const wsConnection = new WebSocket(wsUrl);
    setConnectionStatus("connecting");

    wsConnection.onopen = () => {
      // console.log("Connected to WebSocket");
      setConnectionStatus("connected");
    };

    wsConnection.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handleWebSocketMessage(data);
    };

    wsConnection.onclose = (e) => {
      // console.log("Disconnected from WebSocket:", e.reason);
      setConnectionStatus("disconnected");
      // Tentative de reconnexion après 5 secondes
      setTimeout(() => {
        if (token) {
          connectWebSocket();
        }
      }, 5000);
    };

    wsConnection.onerror = (error) => {
      // console.error("WebSocket error:", error);
      setConnectionStatus("error");
    };

    setWs(wsConnection);
    return wsConnection;
  };

  useEffect(() => {
    let wsConnection;
    if (token) {
      wsConnection = connectWebSocket();
    }

    return () => {
      if (wsConnection) {
        wsConnection.close();
      }
    };
  }, [token]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, selectedUser]);

  // Ajouter dans le composant Chat
  useEffect(() => {
    // Vérifier si un token existe dans le localStorage au chargement
    const savedToken = localStorage.getItem("chatToken");
    const savedUsername = localStorage.getItem("chatUsername");
    const savedIsModerator = localStorage.getItem("chatIsModerator") === "true";

    if (savedToken && savedUsername) {
      setToken(savedToken);
      setUsername(savedUsername);
      setIsModerator(savedIsModerator);
      setIsRegistered(true);
    }
  }, []);

  // Modifier la fonction handleAuth
  const handleAuth = async (e) => {
    e.preventDefault();
    setError("");

    const endpoint = isLogin ? "login" : "register";
    const serverUrl = getServerUrl();

    try {
      const response = await fetch(`${serverUrl}/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Sauvegarder dans localStorage
        localStorage.setItem("chatToken", data.token);
        localStorage.setItem("chatUsername", data.user.username);
        localStorage.setItem(
          "chatIsModerator",
          (
            data.user.role === "moderator" || data.user.role === "admin"
          ).toString()
        );

        setToken(data.token);
        setUsername(data.user.username);
        setIsModerator(
          data.user.role === "moderator" || data.user.role === "admin"
        );
        setIsRegistered(true);
      } else {
        setError(data.message || "Une erreur est survenue");
      }
    } catch (error) {
      // console.error("Auth error:", error);
      setError("Erreur de connexion au serveur");
    }
  };

  // Ajouter cet état pour suivre tous les contacts avec qui on a échangé
  const [recentContacts, setRecentContacts] = useState([]);

  // Ajouter dans useEffect après avoir chargé l'historique des messages privés
  useEffect(() => {
    // Après avoir reçu l'historique des messages privés
    if (Object.keys(privateMessages).length > 0) {
      // Extraire tous les IDs uniques des conversations
      const contactIds = Object.keys(privateMessages).map(Number);

      // Créer une liste de contacts à partir des utilisateurs en ligne et de l'historique
      const updatedContacts = contactIds
        .map((id) => {
          // Chercher l'utilisateur parmi les utilisateurs en ligne
          const onlineUser = onlineUsers.find((user) => user.id === id);
          if (onlineUser) return { ...onlineUser, isOnline: true };

          // Si pas en ligne, créer un contact à partir des infos des messages
          const messages = privateMessages[id];
          if (messages && messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            const decodedToken = decodeToken(token);
            const userId = decodedToken.id;
            const username =
              lastMessage.senderId === userId
                ? lastMessage.recipient?.username
                : lastMessage.sender?.username;

            return {
              id,
              username: username || `Utilisateur #${id}`,
              isOnline: false,
            };
          }
          return null;
        })
        .filter(Boolean);

      setRecentContacts(updatedContacts);
    }
  }, [privateMessages, onlineUsers, token]);

  useEffect(() => {
    // Initialiser tous les dropdowns
    const dropdownElementList = [].slice.call(
      document.querySelectorAll(".dropdown-toggle")
    );
    dropdownElementList.map(function (dropdownToggleEl) {
      return new Dropdown(dropdownToggleEl);
    });
  }, []);

  //conversation privée

  // useEffect(() => {
  //   const fetchConversations = async () => {
  //     const response = await fetch("/conversations", {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
  //       },
  //     });
  //     const data = await response.json();
  //     setConversations(data);
  //   };

  //   if (token) {
  //     fetchConversations();
  //   }
  // }, [token]);

  // Helper function to format message time in MatchSynce style
  const formatMatchSynceTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const decodeToken = (token) => {
    try {
      // Extraire le payload (la partie du milieu)
      const payload = token.split(".")[1];

      // Décoder la base64 et convertir en objet
      const decodedPayload = JSON.parse(atob(payload));

      return decodedPayload;
    } catch (error) {
      // console.error("Erreur lors du décodage du token:", error);
      return null;
    }
  };

  const checkContentType = (content) => {
    if (content?.startsWith("data:")) {
      const mimeType = content.split(",")[0].split(":")[1].split(";")[0];

      if (mimeType.startsWith("image/")) {
        return "image";
      } else if (mimeType.startsWith("audio/")) {
        return "audio";
      } else if (mimeType.startsWith("video/")) {
        return "video";
      } else if (mimeType.startsWith("text/")) {
        return "text";
      }
    }
    return "text";
  };

  const getMessagePreview = (content) => {
    const type = checkContentType(content);

    switch (type) {
      case "image":
        return "Image";
      case "audio":
        return "Audio";
      case "video":
        return "Vidéo";
      case "text":
      default:
        return content?.substring(0, 30) || "Démarrer une conversation...";
    }
  };

  const renderMatchSynceStyleMessages = () => {
    const decodedToken = decodeToken(token);
    const userId = decodedToken.id;

    // Récupérer les messages en fonction de la conversation active
    const currentMessages = activeConversation
      ? privateMessages[activeConversation.id] || [] // Messages privés avec l'utilisateur sélectionné
      : messages; // Messages du chat général

    // Trier les messages par date (du plus ancien au plus récent)
    const sortedMessages = currentMessages.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    let lastDate = null;

    return sortedMessages.map((msg, index) => {
      const isCurrentUser = msg.senderId === userId;

      // if (msg.is_deleted) {
      //   return (
      //     <div key={msg.id || index} className="text-muted">
      //       Message supprimé
      //     </div>
      //   );
      // }

      // Afficher l'en-tête de date si nécessaire
      const msgDate = new Date(msg.createdAt).toLocaleDateString();
      const showDateHeader = lastDate !== msgDate;
      if (showDateHeader) {
        lastDate = msgDate;
      }

      // Déterminer le nom de l'expéditeur
      let senderName = isCurrentUser
        ? username
        : activeConversation
        ? activeConversation.username
        : msg.sender?.username || "Unknown user";

      // Vérifier si le message est supprimé
      const isDeleted = msg.is_deleted;

      //console.error("msg:", msg);

      const checkContentType = (content) => {
        if (content?.startsWith("data:")) {
          const mimeType = content.split(",")[0].split(":")[1].split(";")[0];

          if (mimeType.startsWith("image/")) {
            return "image";
          } else if (mimeType.startsWith("audio/")) {
            return "audio";
          } else if (mimeType.startsWith("video/")) {
            return "video";
          } else if (mimeType.startsWith("text/")) {
            return "text";
          }
        }
        return "text";
      };

      return (
        <React.Fragment key={msg.id || index}>
          {showDateHeader && (
            <div className="d-flex justify-content-center my-3">
              <div className="bg-light rounded-pill px-3 py-1 small text-secondary">
                {new Date(msg.createdAt).toLocaleDateString([], {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
            </div>
          )}

          <div
            className={`d-flex mb-2 ${
              isCurrentUser ? "justify-content-end" : "justify-content-start"
            }`}
          >
            {!isCurrentUser && (
              <div className="me-2 align-self-end mb-1">
                <div className="bg-secondary bg-opacity-10 rounded-circle p-1">
                  <User size={24} className="text-secondary" />
                </div>
              </div>
            )}

            {/* {isDeleted ? (
              <p className="p-2 mb-1 text-muted" style={{
                maxWidth: "75%",
                fontFamily: "italic",
                borderRadius: isCurrentUser
                  ? "15px 15px 0 15px"
                  : "15px 15px 15px 0",
                boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                position: "relative",
                backgroundColor: isCurrentUser ? "#e3f2fd" : "#f0f0f0",
                color: isCurrentUser ? "#000000" : "#333333",
              }}>Message supprimé</p>
            ) : (
             
              <> */}
            <div
              className="p-2"
              style={{
                maxWidth: "75%",
                borderRadius: isCurrentUser
                  ? "15px 15px 0 15px"
                  : "15px 15px 15px 0",
                boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                position: "relative",
                backgroundColor: isCurrentUser ? "#e3f2fd" : "#f0f0f0",
                color: isCurrentUser ? "#000000" : "#333333",
              }}
            >
              {!isCurrentUser && (
                <div className="fw-medium small mb-1 text-primary">
                  {senderName}
                </div>
              )}

              {msg.type === "image" ? (
                <div>
                  <img
                    src={msg.content}
                    alt="Image envoyée"
                    style={{ maxWidth: "100%", borderRadius: "10px" }}
                  />
                  <button
                    className="btn btn-link text-muted p-0"
                    onClick={() => downloadImage(msg.content)}
                  >
                    <Download size={16} />
                  </button>
                </div>
              ) : msg.type === "audio" ? (
                <div>
                  <audio controls>
                    <source src={msg.content} type="audio/wav" />
                    Votre navigateur ne supporte pas l'élément audio.
                  </audio>
                  <button
                    className="btn btn-link text-muted p-0"
                    onClick={() => downloadAudio(msg.content)}
                  >
                    <Download size={16} />
                  </button>
                </div>
              ) : (
                <div>
                  {checkContentType(msg.content) === "image" ? (
                    <div>
                      <img
                        src={msg.content}
                        alt="Image envoyée"
                        style={{ maxWidth: "100%", borderRadius: "10px" }}
                      />
                      <button
                        className="btn btn-link text-muted p-0"
                        onClick={() => downloadImage(msg.content)}
                      >
                        <Download size={16} />
                      </button>
                    </div>
                  ) : checkContentType(msg.content) === "audio" ? (
                    <div>
                      <audio controls>
                        <source src={msg.content} type="audio/wav" />
                        Votre navigateur ne supporte pas l'élément audio.
                      </audio>
                      <button
                        className="btn btn-link text-muted p-0"
                        onClick={() => downloadAudio(msg.content)}
                      >
                        <Download size={16} />
                      </button>
                    </div>
                  ) : checkContentType(msg.content) === "video" ? (
                    <div>
                      <div>
                        <video
                          controls
                          style={{ maxWidth: "100%", borderRadius: "10px" }}
                        >
                          <source src={msg.content} type="video/mp4" />
                          Votre navigateur ne supporte pas l'élément vidéo.
                        </video>
                        <button
                          className="btn btn-link text-muted p-0"
                          onClick={() => downloadVideo(msg.content)}
                        >
                          <Download size={16} />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap", fontSize: "0.95rem" }}
                    >
                      {msg.content}
                    </p>
                  )}
                </div>
              )}

              <div
                className="d-flex align-items-center justify-content-end gap-1"
                style={{ fontSize: "0.7rem" }}
              >
                <span className="text-muted">
                  {formatMatchSynceTime(msg.createdAt)}
                </span>

                {/* Bouton de suppression */}
                {!isDeleted && (isCurrentUser || isModerator) && (
                  <button
                    className="btn btn-link text-danger p-0"
                    onClick={() => handleDeleteMessage(msg.id)}
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
            </div>

            {/* Modal de confirmation de suppression */}
            <div
              className="modal fade"
              id="deleteConfirmationModal"
              tabIndex="-1"
              aria-labelledby="deleteConfirmationModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog ">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="deleteConfirmationModalLabel"
                    >
                      Confirmer la suppression
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Êtes-vous sûr de vouloir supprimer ce message ?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={confirmDeleteMessage}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* </>
            )} */}
          </div>
        </React.Fragment>
      );
    });
  };

  const handleWebSocketMessage = (data) => {
    switch (data.type) {
      case "chat":
        setMessages((prev) => [...prev, data.message]);
        break;
      case "private":
        const decodedToken = decodeToken(token);
        const userId = decodedToken.id;
        const otherUserId =
          data.message.senderId === userId
            ? data.message.recipientId
            : data.message.senderId;

        setPrivateMessages((prev) => {
          const currentMessages = prev[otherUserId] || [];
          // Vérifier si le message n'existe pas déjà pour éviter les doublons
          if (!currentMessages.some((msg) => msg.id === data.message.id)) {
            return {
              ...prev,
              [otherUserId]: [...currentMessages, data.message],
            };
          }
          return prev;
        });
        break;
      case "users":
        setOnlineUsers(
          data.users.filter(
            (user, index, self) =>
              index === self.findIndex((u) => u.id === user.id)
          )
        );
        break;
      case "moderation_needed":
        if (isModerator) {
          setModerationQueue((prev) => [...prev, data.message]);
        }
        break;
      case "moderation":
        if (data.action === "delete") {
          setMessages((prev) =>
            prev.filter((msg) => msg.id !== data.messageId)
          );
          setModerationQueue((prev) =>
            prev.filter((msg) => msg.id !== data.messageId)
          );
        }
        break;
      case "history":
        const chatMessages = [];
        const userPrivateMessages = {};

        if (data.messages && Array.isArray(data.messages)) {
          data.messages.forEach((msg) => {
            if (msg.type === "private") {
              const decodedToken = decodeToken(token);
              const userId = decodedToken.id;
              const otherUserId =
                msg.senderId === userId ? msg.recipientId : msg.senderId;

              if (!userPrivateMessages[otherUserId]) {
                userPrivateMessages[otherUserId] = [];
              }

              // Éviter les doublons
              if (
                !userPrivateMessages[otherUserId].some((m) => m.id === msg.id)
              ) {
                userPrivateMessages[otherUserId].push(msg);
              }
            } else {
              if (!chatMessages.some((m) => m.id === msg.id)) {
                chatMessages.push(msg);
              }
            }
          });
        }

        setMessages(chatMessages);
        setPrivateMessages(userPrivateMessages);
        break;
      case "image":
        if (data.message.recipientId) {
          // Si c'est une image privée
          const decodedToken = decodeToken(token);
          const userId = decodedToken.id;
          const otherUserId =
            data.message.senderId === userId
              ? data.message.recipientId
              : data.message.senderId;

          setPrivateMessages((prev) => {
            const currentMessages = prev[otherUserId] || [];
            if (!currentMessages.some((msg) => msg.id === data.message.id)) {
              return {
                ...prev,
                [otherUserId]: [...currentMessages, data.message],
              };
            }
            return prev;
          });
        } else {
          // Si c'est une image dans le chat général
          setMessages((prevMessages) => [...prevMessages, data.message]);
        }
        break;
      case "audio":
        if (data.message.recipientId) {
          // Si c'est un audio privé
          const decodedToken = decodeToken(token);
          const userId = decodedToken.id;
          const otherUserId =
            data.message.senderId === userId
              ? data.message.recipientId
              : data.message.senderId;

          setPrivateMessages((prev) => {
            const currentMessages = prev[otherUserId] || [];
            if (!currentMessages.some((msg) => msg.id === data.message.id)) {
              return {
                ...prev,
                [otherUserId]: [...currentMessages, data.message],
              };
            }
            return prev;
          });
        } else {
          // Si c'est un audio dans le chat général
          setMessages((prevMessages) => [...prevMessages, data.message]);
        }
        break;
      case "message_deleted":
        // Mettre à jour l'état local des messages
        setMessages((prev) =>
          prev.map((msg) =>
            msg.id === data.messageId ? { ...msg, is_deleted: true } : msg
          )
        );

        // Mettre à jour l'état local des messages privés
        setPrivateMessages((prev) => {
          const updatedPrivateMessages = { ...prev };
          Object.keys(updatedPrivateMessages).forEach((key) => {
            updatedPrivateMessages[key] = updatedPrivateMessages[key].map(
              (msg) =>
                msg.id === data.messageId ? { ...msg, is_deleted: true } : msg
            );
          });
          return updatedPrivateMessages;
        });
        break;
      default:
      // console.log("Unhandled message type:", data.type);
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (isRecording) return; // Ne pas envoyer de message texte si un enregistrement est en cours

    if (!message.trim() || !ws || connectionStatus !== "connected") return;

    const decodedToken = decodeToken(token);
    const userId = decodedToken.id;

    const messageData = {
      type: activeConversation ? "private" : "chat",
      content: message.trim(),
      recipientId: activeConversation?.id,
      conversationId: activeConversation?.id,
    };

    ws.send(JSON.stringify(messageData));
    setMessage("");
    setShowEmoji(false);
  };

  //supprimé un message

  const [messageToDelete, setMessageToDelete] = useState(null);
  const handleDeleteMessage = (messageId) => {
    // Stocker l'ID du message à supprimer
    setMessageToDelete(messageId);

    // Ouvrir la modal de confirmation
    const modal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    modal.show();
  };

  const confirmDeleteMessage = async () => {
    if (!messageToDelete) return;

    try {
      const endpoint = "delete-message";
      const serverUrl = getServerUrl();
      const response = await fetch(`${serverUrl}/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ messageId: messageToDelete }),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du message");
      }

      // Mettre à jour l'état local des messages
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === messageToDelete ? { ...msg, is_deleted: true } : msg
        )
      );

      // Mettre à jour l'état local des messages privés
      setPrivateMessages((prev) => {
        const updatedPrivateMessages = { ...prev };
        Object.keys(updatedPrivateMessages).forEach((key) => {
          updatedPrivateMessages[key] = updatedPrivateMessages[key].map((msg) =>
            msg.id === messageToDelete ? { ...msg, is_deleted: true } : msg
          );
        });
        return updatedPrivateMessages;
      });

      // Fermer la modal
      const modal = bootstrap.Modal.getInstance(
        document.getElementById("deleteConfirmationModal")
      );
      modal.hide();
    } catch (error) {
      console.error("Erreur lors de la suppression du message:", error);
      alert("Une erreur s'est produite lors de la suppression du message");
    } finally {
      // Réinitialiser l'ID du message à supprimer
      setMessageToDelete(null);
    }
  };
  // const handleDeleteMessage = async (messageId) => {
  //   try {
  //     const endpoint = "delete-message";
  //     const serverUrl = getServerUrl();
  //     const response = await fetch(`${serverUrl}/${endpoint}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ messageId }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Erreur lors de la suppression du message");
  //     }

  //     // Mettre à jour l'état local des messages
  //     setMessages((prev) =>
  //       prev.map((msg) =>
  //         msg.id === messageId ? { ...msg, is_deleted: true } : msg
  //       )
  //     );

  //     // Mettre à jour l'état local des messages privés
  //     setPrivateMessages((prev) => {
  //       const updatedPrivateMessages = { ...prev };
  //       Object.keys(updatedPrivateMessages).forEach((key) => {
  //         updatedPrivateMessages[key] = updatedPrivateMessages[key].map((msg) =>
  //           msg.id === messageId ? { ...msg, is_deleted: true } : msg
  //         );
  //       });
  //       return updatedPrivateMessages;
  //     });
  //   } catch (error) {
  //     console.error("Erreur lors de la suppression du message:", error);
  //     alert("Une erreur s'est produite lors de la suppression du message");
  //   }
  // };

  useEffect(() => {
    if (showEmoji) {
      // Monter le sélecteur d'émojis
    } else {
      // Démonter le sélecteur d'émojis
    }
  }, [showEmoji]);

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  };

  useOutsideClick(emojiPickerRef, () => {
    if (showEmoji) setShowEmoji(false);
  });

  const handleEmojiSelect = (emoji) => {
    setMessage((prev) => prev + emoji.native);
  };

  const handleModeration = (messageId, action) => {
    if (!ws || !isModerator || connectionStatus !== "connected") return;

    ws.send(
      JSON.stringify({
        type: "moderation",
        action,
        messageId,
      })
    );

    if (action === "delete") {
      setModerationQueue((prev) => prev.filter((msg) => msg.id !== messageId));
    }
  };

  const getLastMessage = (userId) => {
    const userMessages = privateMessages[userId] || [];
    if (userMessages.length === 0) return null;

    const lastMsg = userMessages[userMessages.length - 1];

    // Assurons-nous que le message a toutes les informations nécessaires
    if (!lastMsg.sender && lastMsg.senderId) {
      // Essayer de trouver l'utilisateur dans la liste des utilisateurs en ligne
      const sender = onlineUsers.find((u) => u.id === lastMsg.senderId);
      if (sender) {
        lastMsg.sender = { username: sender.username };
      }
    }

    if (!lastMsg.recipient && lastMsg.recipientId) {
      const recipient = onlineUsers.find((u) => u.id === lastMsg.recipientId);
      if (recipient) {
        lastMsg.recipient = { username: recipient.username };
      }
    }

    return lastMsg;
  };

  // const formatTime = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  // };

  // console.log("Token: ", token);

  /**
   * @type {Object|null}
   */
  const decodedToken = decodeToken(token);
  const userId = decodedToken?.id;
  const filteredUsers = onlineUsers.filter(
    (user) =>
      user.id !== userId &&
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //gestion image

  const handleImageUpload = async (e) => {
    const endpoint = "upload-image";
    const serverUrl = getServerUrl();
    const file = e.target.files[0];
    if (!file) {
      alert("Aucun fichier sélectionné.");
      return;
    }

    // Vérifiez le type de fichier
    if (!file.type.startsWith("image/")) {
      alert("Veuillez sélectionner un fichier image valide.");
      return;
    }

    // Vérifiez la taille du fichier (par exemple, 5 Mo maximum)
    if (file.size > 5 * 1024 * 1024) {
      alert("La taille du fichier ne doit pas dépasser 5 Mo.");
      return;
    }

    // Créez un objet FormData pour envoyer le fichier
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(`${serverUrl}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
        method: "POST",
        body: formData,
      });

      // Vérifier que la réponse est bien du JSON
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("La réponse n'est pas du JSON");
      }

      if (!response.ok) {
        throw new Error("Échec de l'upload de l'image");
      }

      const data = await response.json();
      //console.log("Réponse du serveur :", data);
      const base64Image = data.imageUrl;

      const newMessage = {
        type: activeConversation ? "private" : "image", // Définir le type de message
        content: base64Image,
        senderId: userId,
        recipientId: activeConversation?.id, // Ajouter le recipientId pour les conversations privées
        conversationId: activeConversation?.id, // Ajouter l'ID de la conversation
        createdAt: new Date().toISOString(),
      };

      if (ws && connectionStatus === "connected") {
        ws.send(JSON.stringify(newMessage));
      }

      setShowFileDropdown(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image:", error);
      alert("Une erreur s'est produite lors de l'upload de l'image.");
    }
  };

  const downloadImage = (base64Image) => {
    const link = document.createElement("a");
    link.href = base64Image;
    link.download = "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //Gestion audio

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);

  const startRecording = async () => {
    console.log("start");
    try {
      console.log("in try");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(blob);
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Erreur lors de l'enregistrement audio:", error);
      alert("Impossible d'accéder au microphone.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      sendAudio(); // Envoyer l'audio après l'arrêt de l'enregistrement
    }
  };

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setRecordingTime(0); // Réinitialiser le temps lorsque l'enregistrement est arrêté
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isRecording]);

  const sendAudio = async () => {
    if (!audioBlob) return;
    const endpoint = "upload-audio";
    const serverUrl = getServerUrl();

    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.wav");

    // Ajouter le recipientId et isPrivate si c'est une conversation privée
    if (selectedUser) {
      formData.append("recipientId", selectedUser.id);
      formData.append("isPrivate", true);
    }

    try {
      const response = await fetch(`${serverUrl}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        const base64Audio = data.audioUrl;

        // Envoyer l'audio dans le chat
        const newMessage = {
          type: activeConversation ? "private" : "audio", // Définir le type de message
          content: base64Audio,
          senderId: userId,
          recipientId: activeConversation?.id, // Ajouter le recipientId pour les conversations privées
          conversationId: activeConversation?.id, // Ajouter l'ID de la conversation
          createdAt: new Date().toISOString(),
        };

        if (ws && connectionStatus === "connected") {
          ws.send(JSON.stringify(newMessage));
        }
      } else {
        alert("Erreur lors de l'envoi de l'audio.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'audio:", error);
      alert("Une erreur s'est produite lors de l'envoi de l'audio.");
    }
  };

  const downloadAudio = (base64Audio) => {
    const link = document.createElement("a");
    link.href = base64Audio;
    link.download = "audio.wav";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatTimeAudio = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  //Gestion Vidéo

  const [showFileDropdown, setShowFileDropdown] = useState(false);

  const handleVideoUpload = async (e) => {
    const endpoint = "upload-video";
    const serverUrl = getServerUrl();
    const file = e.target.files[0];
    if (!file) {
      alert("Aucun fichier sélectionné.");
      return;
    }

    // Vérifiez le type de fichier
    if (!file.type.startsWith("video/")) {
      alert("Veuillez sélectionner un fichier vidéo valide.");
      return;
    }

    // Vérifiez la taille du fichier (par exemple, 50 Mo maximum)
    if (file.size > 50 * 1024 * 1024) {
      alert("La taille du fichier ne doit pas dépasser 50 Mo.");
      return;
    }

    // Créez un objet FormData pour envoyer le fichier
    const formData = new FormData();
    formData.append("video", file);

    try {
      const response = await fetch(`${serverUrl}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
        method: "POST",
        body: formData,
      });

      // Vérifier que la réponse est bien du JSON
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("La réponse n'est pas du JSON");
      }

      if (!response.ok) {
        throw new Error("Échec de l'upload de la vidéo");
      }

      const data = await response.json();
      const base64Video = data.videoUrl;

      const newMessage = {
        type: activeConversation ? "private" : "video", // Définir le type de message
        content: base64Video,
        senderId: userId,
        recipientId: activeConversation?.id, // Ajouter le recipientId pour les conversations privées
        conversationId: activeConversation?.id, // Ajouter l'ID de la conversation
        createdAt: new Date().toISOString(),
      };

      // Mettre à jour l'état local des messages
      if (activeConversation) {
        setPrivateMessages((prev) => {
          const currentMessages = prev[activeConversation.id] || [];
          return {
            ...prev,
            [activeConversation.id]: [...currentMessages, newMessage],
          };
        });
      } else {
        setMessages((prev) => [...prev, newMessage]);
      }

      if (ws && connectionStatus === "connected") {
        ws.send(JSON.stringify(newMessage));
      }

      setShowFileDropdown(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de la vidéo:", error);
      alert("Une erreur s'est produite lors de l'upload de la vidéo.");
    }
  };

  const downloadVideo = (base64Video) => {
    const link = document.createElement("a");
    link.href = base64Video;
    link.download = "video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Ajouter une référence pour le dropdown
  const dropdownRef = useRef(null);

  // Gérer les clics en dehors du dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFileDropdown(false); // Fermer le dropdown
      }
    };

    // Ajouter l'écouteur d'événement
    document.addEventListener("mousedown", handleClickOutside);

    // Nettoyer l'écouteur d'événement
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fonction pour faire défiler jusqu'en bas
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  if (!isRegistered) {
    return (
      <div className="p-5 mb-5 bg-light d-flex align-items-center justify-content-center">
        <div
          className="card shadow-lg"
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <div className="card-header bg-light text-white text-center py-4">
            <img
              src="/matchsynce_chat.png"
              alt="Chat Logo"
              className="mb-2"
              style={{ height: "80px", width: "90px" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://placehold.co/90x90?text=Chat";
              }}
            />
            <h2 className="h4 mb-0 text-dark">
              {isLogin ? "Connexion" : "Inscription"}
            </h2>
          </div>
          <div className="card-body p-4">
            {error && (
              <div
                className="alert alert-danger border-start border-danger border-4"
                role="alert"
              >
                <p className="mb-0">{error}</p>
              </div>
            )}
            <form onSubmit={handleAuth}>
              <div className="mb-3">
                <label className="form-label fw-bold">Nom d'utilisateur</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <User size={18} className="text-muted" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Entrez votre nom d'utilisateur"
                    required
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label fw-bold">Mot de passe</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <Shield size={18} className="text-muted" />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Entrez votre mot de passe"
                    required
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-success w-100">
                {isLogin ? "Se connecter" : "S'inscrire"}
              </button>
            </form>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-link text-success p-0"
                onClick={() => {
                  setIsLogin(!isLogin);
                  setError("");
                  setUsername("");
                  setPassword("");
                }}
              >
                {isLogin
                  ? "Pas encore de compte ? S'inscrire"
                  : "Déjà un compte ? Se connecter"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="vh-100 d-flex flex-column bg-light">
      <div className="flex-grow-1 d-flex overflow-hidden" style={{}}>
        {/* Mobile menu button */}

        {/* Sidebar */}
        <div
          className={`${
            showMobileMenu ? "d-block" : "d-none"
          } d-md-block bg-white border-end d-flex flex-column h-100`}
          style={{ width: "390px", maxHeight: "calc(100vh - 155px)" }}
        >
          {/* Sidebar header */}
          <div className="px-3 py-3 bg-success text-white">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="h5 mb-0">Messages</h2>
              <div className="d-flex align-items-center">
                <span
                  className={`rounded-circle me-2 ${
                    connectionStatus === "connected"
                      ? "bg-success"
                      : "bg-danger"
                  }`}
                  style={{ height: "8px", width: "8px" }}
                ></span>
                <small>
                  {connectionStatus === "connected" ? "En ligne" : "Déconnecté"}
                </small>
              </div>
            </div>
          </div>

          {/* Search bar */}
          <div className="p-2 bg-light">
            <div className="position-relative">
              <input
                type="text"
                placeholder="Rechercher..."
                className="form-control rounded-pill"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ paddingLeft: "30px" }} // Décale le texte à droite
              />
              <Search
                size={18}
                className="position-absolute top-50 translate-middle-y"
                style={{ left: "10px" }} // Ajuste l'icône si nécessaire
              />
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto" style={{}}>
            {/* General chat */}
            <div
              className={`d-flex align-items-center p-3 border-bottom cursor-pointer ${
                !activeConversation ? "bg-light" : ""
              }`}
              onClick={() => {
                setActiveConversation(null);
                setShowMobileMenu(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="bg-success bg-opacity-10 rounded-circle p-2 me-3">
                <MessageSquare size={24} className="text-success" />
              </div>
              <div className="flex-grow-1 min-width-0">
                <h3 className="h6 mb-1">Chat général</h3>
                <p className="text-muted small text-truncate mb-0">
                  {messages.length > 0
                    ? messages[messages.length - 1].type === "text"
                      ? // Afficher le contenu du message texte (tronqué à 30 caractères)
                        messages[messages.length - 1].content.substring(0, 10) +
                        "..."
                      : messages[messages.length - 1].type === "image"
                      ? // Afficher "Image" pour les messages de type image
                        "Image"
                      : messages[messages.length - 1].type === "audio"
                      ? // Afficher "Audio" pour les messages de type audio
                        "Audio"
                      : // Utiliser checkContentType pour les autres types
                      messages[messages.length - 1].type === "private"
                      ? "Message"
                      : "Fichier"
                    : // Afficher ce texte si la liste des messages est vide
                      "Commencez une discussion !"}
                </p>
              </div>
              {messages.length > 0 && (
                <small className="text-muted">
                  {formatTime(messages[messages.length - 1].createdAt)}
                </small>
              )}
            </div>

            {filteredUsers.map((user) => {
              const lastMessage = getLastMessage(user.id);
              return (
                <div
                  key={user.id}
                  className={`d-flex align-items-center p-3 border-bottom ${
                    activeConversation?.id === user.id ? "bg-light" : ""
                  }`}
                  onClick={() => {
                    setActiveConversation(user);
                    setShowMobileMenu(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="position-relative">
                    <div className="bg-secondary bg-opacity-10 rounded-circle p-2 me-3">
                      <User size={24} className="text-secondary" />
                    </div>
                    {user.isOnline !== false && (
                      <span className="position-absolute bottom-0 end-0 translate-middle p-1 bg-success border border-white rounded-circle"></span>
                    )}
                    {user.isOnline === false && (
                      <span className="position-absolute bottom-0 end-0 translate-middle p-1 bg-secondary border border-white rounded-circle"></span>
                    )}
                  </div>
                  <div className="flex-grow-1 min-width-0">
                    <h3 className="h6 mb-1">{user.username}</h3>
                    <p className="text-muted small text-truncate mb-0">
                      {getMessagePreview(lastMessage?.content)}
                    </p>
                  </div>
                  {lastMessage && (
                    <small className="text-muted">
                      {formatTime(lastMessage.createdAt)}
                    </small>
                  )}
                </div>
              );
            })}

            {/* Conversations list */}
            {conversations.map((conversation) => {
              const lastMessage = getLastMessage(conversation.id);
              return (
                <div
                  key={conversation.id}
                  className={`d-flex align-items-center p-3 border-bottom ${
                    activeConversation?.id === conversation.id ? "bg-light" : ""
                  }`}
                  onClick={() => {
                    setActiveConversation(conversation);
                    setShowMobileMenu(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="position-relative">
                    <div className="bg-secondary bg-opacity-10 rounded-circle p-2 me-3">
                      <User size={24} className="text-secondary" />
                    </div>
                    {conversation.isOnline !== false && (
                      <span className="position-absolute bottom-0 end-0 translate-middle p-1 bg-success border border-white rounded-circle"></span>
                    )}
                    {conversation.isOnline === false && (
                      <span className="position-absolute bottom-0 end-0 translate-middle p-1 bg-secondary border border-white rounded-circle"></span>
                    )}
                  </div>
                  <div className="flex-grow-1 min-width-0">
                    <h3 className="h6 mb-1">{conversation.username}</h3>
                    <p className="text-muted small text-truncate mb-0">
                      {getMessagePreview(lastMessage?.content)}
                    </p>
                  </div>
                  {lastMessage && (
                    <small className="text-muted">
                      {formatTime(lastMessage.createdAt)}
                    </small>
                  )}
                </div>
              );
            })}
          </div>

          {/* User profile */}
          <div className="d-flex bg-white border-top position-sticky bottom-0 w-100">
            <div
              className="p-3 bg-white d-flex align-items-center"
              style={{ width: "390px", borderRight: "1px solid #ddd" }}
            >
              <div className="bg-secondary bg-opacity-10 rounded-circle p-2 me-3">
                <User size={20} className="text-secondary" />
              </div>
              <div className="flex-grow-1">
                <h3 className="h6 mb-0">{username}</h3>
                <small className="text-muted">
                  {isModerator ? "Modérateur" : "Utilisateur"}
                </small>
              </div>
              <div className="position-relative">
                <button
                  className="btn btn-link text-muted p-0"
                  type="button"
                  onClick={() => setShowLogoutMenu(!showLogoutMenu)}
                >
                  <MoreVertical size={20} />
                </button>
                {showLogoutMenu && (
                  <div
                    className="position-absolute end-0 bottom-100 mb-1 bg-white shadow rounded p-1"
                    style={{ zIndex: 1000, minWidth: "150px" }}
                  >
                    <button
                      className="btn btn-link text-danger w-100 text-start"
                      onClick={() => {
                        handleLogout();
                        setShowLogoutMenu(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <LogOut size={16} className="me-2" />
                        Déconnexion
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Main chat area */}
        <div
          className={`${
            !showMobileMenu || !selectedUser ? "d-block" : "d-none"
          } d-md-block flex-grow-1 d-flex flex-column bg-light`}
        >
          {/* Chat header */}

          <div className="bg-white border-bottom shadow-sm p-3 d-flex justify-content-between">
            <div className=" d-flex align-items-center">
              <button
                className="d-md-none d-block bg-white border-1 justify-content-start me-4"
                style={{
                  width: "2rem",
                }}
                onClick={() => {
                  setShowMobileMenu(true);
                  setSelectedUser(null);
                }}
              >
                <ArrowLeft size={20} className="" />
              </button>
              {activeConversation ? (
                <>
                  <div className="bg-secondary bg-opacity-10 rounded-circle p-2 me-3">
                    <User size={24} className="text-secondary" />
                  </div>
                  <div>
                    <h3 className="h6 mb-0">{activeConversation.username}</h3>
                    <small className="text-muted">En ligne</small>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-success bg-opacity-10 rounded-circle p-2 me-3">
                    <MessageSquare size={24} className="text-success" />
                  </div>
                  <div>
                    <h3 className="h6 mb-0">Chat général</h3>
                    <small className="text-muted">
                      {onlineUsers.length} utilisateurs en ligne
                    </small>
                  </div>
                </>
              )}
            </div>
            {/* Icône "Go to Bottom" */}
            <button
              onClick={scrollToBottom}
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "green",
                color: "#fff",
                border: "none",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              <ArrowDown size={20} />
            </button>
          </div>

          {/* Messages area */}
          <div
            className="flex-grow-1 overflow-auto mb-3 p-1"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23e0e0e0' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E")`,
              maxHeight: "calc(80vh - 50px)",
              minHeight: "calc(80vh - 50px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              overflowY: "scroll",
            }}
          >
            <div
              ref={chatContainerRef}
              style={{
                maxHeight: "calc(100vh - 40px)",
                minHeight: "calc(100vh - 40px)",
                overflowY: "scroll",
              }}
            >
              <div
                className=""
                style={{
                  maxHeight: "calc(30vh)",
                  minHeight: "calc(30vh)",
                }}
              ></div>
              {renderMatchSynceStyleMessages()}
            </div>
            {/* {renderTestMessages()} */}
            <div ref={messagesEndRef} />

            {/* Input area */}
            <div className="bg-white border-top p-3 position-sticky bottom-0 w-100">
              <form
                onSubmit={handleSendMessage}
                className="d-flex align-items-center"
              >
                <div className="position-relative flex-grow-1 me-2">
                  <textarea
                    className="form-control rounded-lg"
                    style={{
                      maxHeight: "100px",
                      minHeight: "40px",
                      overflowY: "auto",
                      paddingRight: "120px", // Augmentez le padding pour accommoder les boutons
                      paddingTop: "10px", // Ajoutez un padding en haut
                      lineHeight: "20px", // Définissez une hauteur de ligne
                      display: "flex", // Utilisez flex pour l'alignement
                      alignItems: "center", // Centrez verticalement le contenu
                    }}
                    rows="2"
                    placeholder={
                      selectedUser
                        ? `Message pour ${selectedUser.username}...`
                        : "Message..."
                    }
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage(e);
                      }
                    }}
                  ></textarea>

                  {/* Conteneur pour les boutons */}
                  <div className="position-absolute end-0 top-50 translate-middle-y d-flex align-items-center gap-2 pe-3">
                    {/* Bouton emoji */}
                    <div ref={dropdownRef}>
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => setShowEmoji(!showEmoji)}
                      >
                        <Smile size={20} className="text-muted" />
                      </button>

                      {showEmoji && (
                        <div className="position-absolute bottom-100 end-0 mb-2 z-3">
                          <Picker
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                            theme="light"
                            previewPosition="none"
                          />
                        </div>
                      )}
                    </div>

                    {/* Bouton dropdown pour image et vidéo */}
                    <div className="dropdown" ref={dropdownRef}>
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => setShowFileDropdown(!showFileDropdown)}
                      >
                        <Paperclip size={20} className="text-muted" />
                      </button>
                      {showFileDropdown && (
                        <div
                          className="dropdown-menu show"
                          style={{
                            top: "auto",
                            bottom: "100%",
                            marginBottom: "5px",
                            right: "0",
                            left: "auto",
                          }}
                        >
                          <button
                            className="dropdown-item d-flex align-items-center gap-2"
                            onClick={() => {
                              document.getElementById("imageInput").click();
                              setShowFileDropdown(false); // Fermer le dropdown après avoir cliqué sur "Image"
                            }}
                          >
                            <Image size={16} className="text-muted" />
                            <span>Image</span>
                          </button>
                          <button
                            className="dropdown-item d-flex align-items-center gap-2"
                            onClick={() => {
                              document.getElementById("videoInput").click();
                              setShowFileDropdown(false); // Fermer le dropdown après avoir cliqué sur "Vidéo"
                            }}
                          >
                            <Video size={16} className="text-muted" />
                            <span>Vidéo</span>
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Bouton audio */}
                    <button
                      type="button"
                      className="btn btn-link p-0"
                      onClick={startRecording}
                      disabled={isRecording}
                    >
                      <Mic size={20} className="text-muted" />
                    </button>
                    {/* Indicateur visuel et temps écoulé */}
                    {isRecording && (
                      <div className="d-flex align-items-center gap-2">
                        {/* Indicateur visuel (magnétophone) */}
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "red",
                            animation: "blink 1s infinite",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Mic size={12} className="text-white" />
                        </div>

                        {/* Temps écoulé */}
                        <small className="text-muted">
                          {formatTimeAudio(recordingTime)}
                        </small>
                      </div>
                    )}
                  </div>

                  {/* Input pour les images */}
                  <input
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(e)}
                  />

                  {/* Input pour les vidéos */}
                  <input
                    type="file"
                    id="videoInput"
                    accept="video/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleVideoUpload(e)}
                  />

                  <style>
                    {`
                      @keyframes blink {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                      }
                    `}
                  </style>
                </div>
                <button
                  type="submit"
                  className={`btn btn-${
                    connectionStatus === "connected" ? "success" : "secondary"
                  } `}
                  disabled={connectionStatus !== "connected"}
                  onClick={(e) => {
                    if (isRecording) {
                      e.preventDefault();
                      stopRecording();
                    } else {
                      handleSendMessage(e);
                    }
                  }}
                >
                  {isRecording ? (
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        animation: "blink 1s infinite",
                      }}
                    ></div>
                  ) : (
                    <Send size={20} />
                  )}
                </button>
              </form>
            </div>

            {/* Moderation queue */}
            {isModerator && moderationQueue.length > 0 && (
              <div
                className="position-fixed bottom-0 end-0 m-3"
                style={{ maxWidth: "400px", width: "100%" }}
              >
                <div className="card border-warning shadow">
                  <div className="card-header bg-warning bg-opacity-10 border-warning">
                    <h3 className="card-title h6 mb-0 d-flex align-items-center text-warning">
                      <Shield size={18} className="me-2" />
                      File de modération ({moderationQueue.length})
                    </h3>
                  </div>
                  <div
                    className="card-body p-0"
                    style={{ maxHeight: "320px", overflowY: "auto" }}
                  >
                    {moderationQueue.map((msg) => (
                      <div key={msg.id} className="p-3 border-bottom">
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center gap-2">
                              <span className="fw-medium text-dark">
                                {msg.sender?.username}
                              </span>
                              <small className="text-muted">
                                {formatTime(msg.createdAt)}
                              </small>
                            </div>
                            <p className="small my-1 text-secondary text-break">
                              {msg.content}
                            </p>
                            <div className="d-flex gap-2 mt-2">
                              <button
                                onClick={() =>
                                  handleModeration(msg.id, "approve")
                                }
                                className="btn btn-sm btn-outline-success"
                              >
                                Approuver
                              </button>
                              <button
                                onClick={() =>
                                  handleModeration(msg.id, "delete")
                                }
                                className="btn btn-sm btn-outline-danger"
                              >
                                Supprimer
                              </button>
                              <button
                                onClick={() => handleModeration(msg.id, "warn")}
                                className="btn btn-sm btn-outline-warning"
                              >
                                Avertir
                              </button>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              setModerationQueue((prev) =>
                                prev.filter((message) => message.id !== msg.id)
                              );
                            }}
                            className="btn btn-link text-muted p-0 ms-2"
                          >
                            <X size={16} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
