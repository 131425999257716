import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

const WelcomePage = ({ onStart }) => {
  return (
    <div style={{
      maxHeight: "calc(100vh - 190px)",
      minHeight: "300px",
      overflowY: "auto",
    }}>
      <Container className="d-flex flex-column justify-content-center align-items-center">
        <Row className="align-items-center">
          {/* Section Image à Gauche */}
          <Col md={6} className="text-center">
            <img
              src="/images/ia.png"
              alt="Football Analytics"
              className="img-fluid"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>

          {/* Section Texte à Droite */}
          <Col md={6} className="text-lg-start text-center">
            <h1 className="display-4 fw-bold text-danger mb-4">MatchSynceIA</h1>
            <p className="lead mb-4">
              MatchSynceIA est bien plus qu’un simple outil : c’est un module
              d’analyse avancé incontournable pour tous les passionnés de
              football et les experts en quête d’un avantage décisif. Grâce à
              une intelligence artificielle de pointe, elle offre des analyses
              de matchs en temps réel, des prédictions précises et des
              statistiques détaillées, vous permettant de plonger au cœur des
              analyses et de toujours garder une longueur d’avance.
            </p>
            <Button variant="danger" size="lg" onClick={onStart}>
              Commencer l'Analyse
            </Button>
          </Col>
        </Row>

        {/* Message Enthousiaste en Bas */}
        <Row className="mt-3 col-lg-8 mb-lg-0 mb-4">
          <Col className="text-center">
            <p className="text-muted fw-bold">
              <span>
                L’IA révolutionne l’analyse sportive, doublant vos chances de
                succès avec des prédictions précises et des insights
                stratégiques pour chaque match !
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WelcomePage;
