import { AlertTriangle } from 'lucide-react';

const MatchNotAvailable = ({ onReload }) => {
  return (
    <div className="text-center py-5">
      <div className="bg-light rounded p-5 mx-auto shadow" style={{ maxWidth: '600px' }}>
        <div className="mb-4">
          <AlertTriangle className="text-danger" size={64} />
        </div>
        
        <h3 className="fw-bold text-danger mb-4">
          Matchs non disponibles
        </h3>
        
        <p className="text-secondary mb-4">
          Aucun match n'est disponible pour le moment. Veuillez revenir plus tard ou recharger la page.
        </p>
        
        <button className="btn btn-primary" onClick={onReload}>
          🔄 Recharger
        </button>
      </div>
    </div>
  );
};

export default MatchNotAvailable;
