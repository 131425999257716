import React from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';

const DateSelectionPage = ({ matchData, onDateSelect, onBack }) => {
  const matchesByDate = matchData.reduce((acc, match) => {
    const datePart = match.date ? match.date.split('T')[0] : null;
    if (!datePart) return acc;
    if (!acc[datePart]) {
      acc[datePart] = {
        matches: [],
        count: 0
      };
    }
    acc[datePart].matches.push(match);
    acc[datePart].count += 1;
    return acc;
  }, {});

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('fr-FR', options);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-lg">
            <Card.Header className="bg-success text-white d-flex justify-content-between align-items-center">
              <Button variant="outline-light" size="sm" onClick={onBack} className="me-2">
                ← Retour
              </Button>
              <h2 className="mb-0">Sélectionnez une date</h2>
              <div className="invisible">
                <Button variant="outline-light" size="sm">Invisible</Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                {Object.keys(matchesByDate).map((date) => (
                  <Col key={date} xs={12} sm={6} md={4} lg={3} className="mb-3">
                    <Card
                      className="h-100 date-card"
                      onClick={() => onDateSelect(date, matchesByDate[date].matches)}
                      style={{
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = 'translateY(-3px)';
                        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow = '';
                      }}
                    >
                      <Card.Body className="d-flex flex-column align-items-center text-center p-3">
                        <div className="mb-2">
                          <span className="fs-4">📅</span>
                        </div>
                        <h5 className="mb-2">{formatDate(date)}</h5>
                        <div className="mt-2 mb-1">
                          <span className="badge bg-primary">
                            {matchesByDate[date].count} {matchesByDate[date].count > 1 ? 'matchs' : 'match'}
                          </span>
                        </div>
                        <div className="mt-auto small text-muted">
                          {matchesByDate[date].matches.slice(0, 2).map((match, idx) => (
                            <div key={idx} className="text-truncate">
                              {match.home_team} vs {match.away_team}
                            </div>
                          ))}
                          {matchesByDate[date].matches.length > 2 && (
                            <div>+ {matchesByDate[date].matches.length - 2} autres</div>
                          )}
                        </div>
                      </Card.Body>
                      <Card.Footer className="text-center bg-light py-2">
                        <small>Cliquez pour voir les matchs</small>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
              {Object.keys(matchesByDate).length === 0 && (
                <div className="text-center py-4">
                  <p className="mb-0">Aucun match disponible.</p>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DateSelectionPage;