import React, { useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";

const BattleChallenge = ({ users, onChallenge }) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedUser) {
      setError("Veuillez sélectionner un adversaire.");
      return;
    }
    onChallenge(selectedUser);
    setError("");
  };

  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title>Défier un Adversaire</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Choisir un adversaire</Form.Label>
            <Form.Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="">Sélectionner un utilisateur</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button type="submit" variant="primary">
            Défier
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default BattleChallenge;