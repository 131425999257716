import React, { useState, useEffect } from "react";
import "flag-icons/css/flag-icons.min.css";
import { useSpring, animated } from "react-spring";
import {
  FaFutbol,
  FaSort,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

const PlayerStats = ({ league }) => {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("all");
  const [rankingType, setRankingType] = useState("general");
  const [sortConfig, setSortConfig] = useState({
    key: "note",
    direction: "desc",
  });
  const itemsPerPage = 20;

  // Mapping de l'identifiant de ligue à son nom complet
  const leagueMapping = {
    bundesliga: "Bundesliga",
    liga: "Liga",
    premier_league: "Premier League",
    serie_a: "Serie A",
    ligue_1: "Ligue 1",
    champions_league: "Champions League",
    europa_league: "Europa League",
    conference_league: "Conference League",
  };

  useEffect(() => {
    fetch("/stats_football_categorisees.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Reset to page 1 when league or ranking type changes
  useEffect(() => {
    setCurrentPage(1);

    // Set default sorting based on ranking type
    if (rankingType === "buteurs") {
      setSortConfig({ key: "buts", direction: "desc" });
    } else if (rankingType === "passeurs") {
      setSortConfig({ key: "assists", direction: "desc" });
    } else if (rankingType === "combined") {
      // For combined ranking, we'll keep the current sort
      // but we'll use a custom sorting function
    } else {
      setSortConfig({ key: "note", direction: "desc" });
    }
  }, [league, rankingType]);

  const handleSort = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  };

  const getPlayers = () => {
    if (!data) return [];

    let players = data.donnees_brutes;

    // Filter by league first if provided
    if (league && leagueMapping[league]) {
      players = players.filter(
        (player) => player.ligue === leagueMapping[league]
      );
    }

    // Apply additional filter based on filterType
    if (filterType === "minimum") {
      players = players.filter(
        (player) => player.statistiques.matchs_joues > 5
      );
    }

    // Apply special sorting for combined ranking
    if (rankingType === "combined") {
      players.sort((a, b) => {
        const aScore =
          (a.statistiques.buts || 0) + (a.statistiques.passes_decisives || 0);
        const bScore =
          (b.statistiques.buts || 0) + (b.statistiques.passes_decisives || 0);
        return bScore - aScore;
      });
    } else {
      // Sort players by the selected stat
      players.sort((a, b) => {
        const statA = getStatValue(a, sortConfig.key);
        const statB = getStatValue(b, sortConfig.key);

        if (sortConfig.direction === "asc") {
          return statA - statB;
        } else {
          return statB - statA;
        }
      });
    }

    // Paginate players
    const startIndex = (currentPage - 1) * itemsPerPage;
    return players.slice(startIndex, startIndex + itemsPerPage);
  };

  const getStatValue = (player, key) => {
    switch (key) {
      case "note":
        return player.statistiques.note;
      case "buts":
        return player.statistiques.buts || 0;
      case "expected_goals":
        return player.statistiques.expected_goals || 0;
      case "dribbles":
        return player.statistiques.dribbles_reussis || 0;
      case "tackles":
        return player.statistiques.tacles || 0;
      case "assists":
        return player.statistiques.passes_decisives || 0;
      case "passes_accuracy":
        return player.statistiques.precision_passes || 0;
      default:
        return 0;
    }
  };

  const getTotalPages = () => {
    if (!data) return 1;

    let filteredPlayers = data.donnees_brutes;

    // Filter by league
    if (league && leagueMapping[league]) {
      filteredPlayers = filteredPlayers.filter(
        (p) => p.ligue === leagueMapping[league]
      );
    }

    // Filter by appearance
    if (filterType === "minimum") {
      filteredPlayers = filteredPlayers.filter(
        (p) => p.statistiques.matchs_joues > 5
      );
    }

    return Math.ceil(filteredPlayers.length / itemsPerPage);
  };

  const getLeagueFlagCode = (leagueName) => {
    switch (leagueName) {
      case "Premier League":
        return "gb";
      case "Liga":
        return "es";
      case "Bundesliga":
        return "de";
      case "Serie A":
        return "it";
      case "Ligue 1":
        return "fr";
      default:
        return "";
    }
  };

  const getTeamLogo = (teamName, playerLeague) => {
    // Return the flag emoji based on the league
    switch (playerLeague) {
      case "Premier League":
        return "🏴"; // England
      case "Liga":
        return "🇪🇸"; // Spain
      case "Bundesliga":
        return "🇩🇪"; // Germany
      case "Serie A":
        return "🇮🇹"; // Italy
      case "Ligue 1":
        return "🇫🇷"; // France
      default:
        return "🏳️"; // Default case if league is not recognized
    }
  };

  const formatDecimal = (value) => {
    return value ? Number(value).toFixed(2) : "";
  };

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 800 },
  });

  if (!data) {
    return <div>Chargement...</div>;
  }

  const displayedLeagueName = league
    ? leagueMapping[league] || league
    : "Toutes les ligues";

  return (
    <animated.div style={fadeIn} className="container-fluid p-4">
      {/* <h1 className="mb-4 text-center">
        Statistiques des joueurs
        {league && (
          <span className="badge bg-primary ms-2">
            {league && (
              <span className={`fi fi-${getLeagueFlagCode(leagueMapping[league])} me-1`}></span>
            )}
            {displayedLeagueName}
          </span>
        )}
      </h1> */}

      {/* Ranking Type Filters */}
      <div className="mb-3">
        <div className="btn-group w-100">
          <button
            className={`btn ${
              rankingType === "general" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setRankingType("general")}
          >
            Classement général
          </button>
          <button
            className={`btn ${
              rankingType === "buteurs" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setRankingType("buteurs")}
          >
            Meilleurs buteurs
          </button>
          <button
            className={`btn ${
              rankingType === "passeurs" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setRankingType("passeurs")}
          >
            Meilleurs passeurs
          </button>
          <button
            className={`btn ${
              rankingType === "combined" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setRankingType("combined")}
          >
            Buts + Passes
          </button>
        </div>
      </div>

      {/* Filters */}
      {/* <div className="card mb-4 shadow-sm">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="allPlayers"
                checked={filterType === 'all'}
                onChange={() => setFilterType('all')}
              />
              <label className="form-check-label" htmlFor="allPlayers">Tous les joueurs</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="minimumAppearances"
                checked={filterType === 'minimum'}
                onChange={() => setFilterType('minimum')}
              />
              <label className="form-check-label" htmlFor="minimumAppearances">Joueurs avec apparitions minimales</label>
            </div>
          </div>
          
          <div className="d-flex align-items-center">
            <span className="me-2">Accumulation</span>
            <div className="dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                Tous
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item" href="#">Tous</a></li>
                <li><a className="dropdown-item" href="#">Domicile</a></li>
                <li><a className="dropdown-item" href="#">Extérieur</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      {/* Table */}
      <div className="card shadow-sm mb-3">
        <div className="table-responsive">
          <table className="table table-striped table-hover mb-0">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  #
                </th>
                <th scope="col">Équipe</th>
                <th scope="col">Nom</th>
                <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("buts")}
                >
                  Buts {sortConfig.key === "buts" && <FaSort />}
                </th>
                {/* <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("expected_goals")}
                >
                  Buts attendus (xG){" "}
                  {sortConfig.key === "expected_goals" && <FaSort />}
                </th> */}
                {/* <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("dribbles")}
                >
                  Dribbles réussis {sortConfig.key === "dribbles" && <FaSort />}
                </th>
                <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("tackles")}
                >
                  Tacles {sortConfig.key === "tackles" && <FaSort />}
                </th> */}
                <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("assists")}
                >
                  Passes décisives {sortConfig.key === "assists" && <FaSort />}
                </th>
                {/* <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("passes_accuracy")}
                >
                  Précision passes %{" "}
                  {sortConfig.key === "passes_accuracy" && <FaSort />}
                </th> */}
                <th
                  scope="col"
                  className="text-center sortable"
                  onClick={() => handleSort("note")}
                >
                  Note moyenne {sortConfig.key === "note" && <FaSort />}
                </th>
              </tr>
            </thead>
            <tbody>
              {getPlayers().map((player, index) => {
                const position = (currentPage - 1) * itemsPerPage + index + 1;
                const teamLogo = getTeamLogo(player.equipe, player.ligue);
                // Calculate combined score for highlighting
                const combinedScore =
                  (player.statistiques.buts || 0) +
                  (player.statistiques.passes_decisives || 0);

                return (
                  <tr key={`${player.nom}-${index}`}>
                    <td className="text-center">{position}</td>
                    <td>
                      <div
                        className="team-logo d-flex justify-content-center align-items-center rounded-circle text-white"
                        style={{
                          width: "30px",
                          height: "30px",
                          fontSize: "16px",
                        }} // Augmentez la taille de la police pour les emojis
                      >
                        {getTeamLogo(player.equipe, player.ligue)}
                      </div>
                    </td>
                    <td>{player.nom}</td>
                    <td
                      className={`text-center ${
                        rankingType === "buteurs" ? "fw-bold bg-light" : ""
                      }`}
                    >
                      {player.statistiques.buts || 0}
                    </td>
                    {/* <td className="text-center">
                      {formatDecimal(player.statistiques.expected_goals)}
                    </td> */}
                    {/* <td className="text-center">
                      {player.statistiques.dribbles_reussis || 0}
                    </td>
                    <td className="text-center">
                      {player.statistiques.tacles || 0}
                    </td> */}
                    <td
                      className={`text-center ${
                        rankingType === "passeurs" ? "fw-bold bg-light" : ""
                      }`}
                    >
                      {player.statistiques.passes_decisives || 0}
                    </td>
                    {/* <td className="text-center">
                      {formatDecimal(player.statistiques.precision_passes)}
                    </td> */}
                    <td className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div
                          className="progress me-2"
                          style={{ width: "20px", height: "20px" }}
                        >
                          <div
                            className="progress-bar bg-success"
                            style={{ width: "100%", height: "100%" }}
                          ></div>
                        </div>
                        {formatDecimal(player.statistiques.note)}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-center">
        <nav>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <FaChevronLeft />
              </button>
            </li>

            {currentPage > 1 && (
              <li className="page-item">
                <button className="page-link" onClick={() => setCurrentPage(1)}>
                  1
                </button>
              </li>
            )}

            {currentPage > 3 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}

            {currentPage > 2 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  {currentPage - 1}
                </button>
              </li>
            )}

            <li className="page-item active">
              <span className="page-link">{currentPage}</span>
            </li>

            {currentPage < getTotalPages() - 1 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  {currentPage + 1}
                </button>
              </li>
            )}

            {currentPage < getTotalPages() - 2 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}

            {currentPage < getTotalPages() && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(getTotalPages())}
                >
                  {getTotalPages()}
                </button>
              </li>
            )}

            <li
              className={`page-item ${
                currentPage === getTotalPages() ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <FaChevronRight />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </animated.div>
  );
};

export default PlayerStats;
