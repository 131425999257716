import React, { useMemo } from "react";
import { Card, Col } from "react-bootstrap";
import {
  Trophy,
  Target,
  Flag,
  ShieldAlert,
  Goal,
  ArrowUpRight,
  Plus,
  Minus,
} from "lucide-react";

const FootballIcons = {
  goal: "⚽",
  trophy: "🏆",
  fire: "🔥",
  chart: "📊",
  stats: "📈",
  clock: "⏱️",
  flag: "🚩",
  team: "👥",
};

const MatchPreview = ({
  matchData,
  expandedCards = [],
  onToggleCard = () => {},
}) => {
  const {
    home_team,
    away_team,
    date,
    home_position,
    home_points,
    home_form,
    home_goals,
    home_conceded,
    away_position,
    away_points,
    away_form,
    away_goals,
    away_conceded,
  } = matchData;

  const matchId = `${home_team}-${away_team}-${date}`.replace(
    /[^a-zA-Z0-9-]/g,
    ""
  );
  const isExpanded =
    Array.isArray(expandedCards) && expandedCards.includes(matchId);

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleCard(matchId);
  };

  // Calculer l'écart de points
  const pointsDiff = Math.abs(away_points - home_points);

  const getFormDescription = (form) => {
    if (form >= 80) return "exceptionnelle";
    if (form >= 60) return "très bonne";
    if (form >= 40) return "correcte";
    return "demi-teinte";
  };

  // Créer 10 versions différentes de textes d'analyse
  const analysisTemplates = [
    // Version 1
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `${away_team} arrive dans une ${getFormDescription(away_form)} dynamique (${away_form}%), occupant actuellement le ${away_position === 1 ? "sommet du classement" : `${away_position}ème rang`} avec ${away_points} points au compteur. Leur ligne offensive fait des merveilles avec ${away_goals} buts par rencontre en moyenne, tandis que leur défense se révèle ${away_conceded < 1.2 ? "particulièrement imperméable" : "relativement stable"} en ne concédant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} par match.`,
      
      home_analysis: `En face, ${home_team}, classé ${home_position}ème avec ${home_points} points, montre une forme ${getFormDescription(home_form)} (${home_form}%). La différence de ${pointsDiff} points témoigne ${pointsDiff > 15 ? "d'un écart considérable" : "d'une certaine disparité"} entre ces deux formations. Produisant en moyenne ${home_goals} but${home_goals > 1 ? "s" : ""} par match, leur attaque demeure ${home_goals > 1.5 ? "redoutable" : "en quête d'efficacité"}, alors que leur défense encaisse ${home_conceded} but${home_conceded > 1 ? "s" : ""} par rencontre.`,
      
      conclusion: `Cette confrontation entre deux collectifs aux caractéristiques bien distinctes promet un spectacle captivant. Les amateurs de football peuvent anticiper une rencontre pleine d'intensité, où ${pointsDiff > 15 ? "la surprise pourrait bien être au rendez-vous" : "le niveau de jeu devrait être particulièrement relevé"}.`
    }),
    
    // Version 2
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `L'équipe de ${away_team} se présente avec une forme ${getFormDescription(away_form)} (${away_form}%), et occupe la ${away_position === 1 ? "position de leader" : `${away_position}ème position`} avec un total de ${away_points} points. Leur puissance offensive impressionne avec une moyenne de ${away_goals} réalisations par match, pendant que leur arrière-garde se montre ${away_conceded < 1.2 ? "quasi impénétrable" : "convenable"} en n'encaissant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `De l'autre côté, ${home_team}, positionné ${home_position}ème avec ${home_points} points, affiche un rendement ${getFormDescription(home_form)} (${home_form}%). Les ${pointsDiff} points d'écart illustrent ${pointsDiff > 15 ? "le gouffre existant" : "la différence de niveau actuelle"} entre ces deux clubs. Avec ${home_goals} but${home_goals > 1 ? "s" : ""} inscrit${home_goals > 1 ? "s" : ""} par rencontre en moyenne, leur secteur offensif reste ${home_goals > 1.5 ? "efficace" : "perfectible"}, tandis que leur défense concède ${home_conceded} but${home_conceded > 1 ? "s" : ""} par match.`,
      
      conclusion: `Ce duel entre deux équipes aux approches tactiques contrastées s'annonce comme un véritable examen de caractère. Le public peut s'attendre à une soirée rythmée et disputée, où ${pointsDiff > 15 ? "l'outsider tentera de déjouer les pronostics" : "le suspense devrait être présent jusqu'au coup de sifflet final"}.`
    }),
    
    // Version 3
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `${away_team} aborde ce match avec une dynamique ${getFormDescription(away_form)} (${away_form}%), et pointe à la ${away_position === 1 ? "tête du championnat" : `${away_position}ème place`} avec ${away_points} points. Leur arsenal offensif fait mouche régulièrement avec ${away_goals} buts de moyenne par rencontre, tandis que leur secteur défensif se révèle ${away_conceded < 1.2 ? "remarquablement solide" : "dans la moyenne"} en concédant seulement ${away_conceded} but${away_conceded > 1 ? "s" : ""} par match.`,
      
      home_analysis: `En vis-à-vis, ${home_team}, actuellement ${home_position}ème avec ${home_points} points, présente un bilan ${getFormDescription(home_form)} (${home_form}%). L'écart de ${pointsDiff} points montre ${pointsDiff > 15 ? "la distance considérable" : "le décalage"} entre les deux équipes. Trouvant le chemin des filets ${home_goals} fois par match en moyenne, leur attaque demeure ${home_goals > 1.5 ? "une force à considérer" : "en phase de développement"}, alors que leur défense voit son but violé ${home_conceded} fois par rencontre en moyenne.`,
      
      conclusion: `Cette opposition entre deux formations aux philosophies différentes s'annonce comme un moment fort de la journée. Les spectateurs peuvent anticiper une rencontre énergique et engagée, où ${pointsDiff > 15 ? "la logique du classement pourrait être bousculée" : "l'équilibre des forces promet du suspense"}.`
    }),
    
    // Version 4
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `L'équipe visiteuse, ${away_team}, traverse une période ${getFormDescription(away_form)} (${away_form}%) et se trouve ${away_position === 1 ? "en tête du classement" : `à la ${away_position}ème place`} avec ${away_points} points. Leur attaque fait parler la poudre avec une moyenne impressionnante de ${away_goals} buts par match, alors que leur défense apparaît ${away_conceded < 1.2 ? "particulièrement hermétique" : "relativement fiable"} en ne concédant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `Les locaux de ${home_team}, classés ${home_position}ème avec ${home_points} points, montrent une forme ${getFormDescription(home_form)} (${home_form}%). Les ${pointsDiff} points qui séparent les deux équipes soulignent ${pointsDiff > 15 ? "l'importante disparité" : "l'écart existant"} entre ces formations. Avec ${home_goals} but${home_goals > 1 ? "s" : ""} marqué${home_goals > 1 ? "s" : ""} par match en moyenne, leur secteur offensif s'avère ${home_goals > 1.5 ? "plutôt percutant" : "encore insuffisant"}, tandis que leur défense encaisse en moyenne ${home_conceded} but${home_conceded > 1 ? "s" : ""} par rencontre.`,
      
      conclusion: `Ce choc entre deux collectifs aux styles contrastés promet d'être un moment fort de cette journée de championnat. Les amateurs présents peuvent s'attendre à un duel tactique et passionné, où ${pointsDiff > 15 ? "la recherche de l'exploit guidera les actions" : "le spectacle promet d'être au rendez-vous"}.`
    }),
    
    // Version 5
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `${away_team} affiche une forme ${getFormDescription(away_form)} (${away_form}%) et occupe actuellement le ${away_position === 1 ? "rang de leader" : `${away_position}ème rang`} avec ${away_points} points à son actif. Leur ligne d'attaque se montre prolifique avec ${away_goals} buts inscrits par match en moyenne, pendant que leur arrière-garde se révèle ${away_conceded < 1.2 ? "extrêmement difficile à franchir" : "dans les standards du championnat"} en n'encaissant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} par partie.`,
      
      home_analysis: `De leur côté, ${home_team}, positionnés à la ${home_position}ème place avec ${home_points} points, présentent des performances ${getFormDescription(home_form)} (${home_form}%). La différence de ${pointsDiff} points met en lumière ${pointsDiff > 15 ? "l'important fossé" : "la distance actuelle"} entre ces deux formations. Avec une moyenne de ${home_goals} but${home_goals > 1 ? "s" : ""} par match, leur potentiel offensif reste ${home_goals > 1.5 ? "intéressant" : "à développer"}, tandis que leur défense voit son but franchi ${home_conceded} fois par match en moyenne.`,
      
      conclusion: `Cette confrontation entre deux équipes aux approches différentes s'annonce comme un véritable test de personnalité. Les supporters présents peuvent s'attendre à une rencontre rythmée et disputée, où ${pointsDiff > 15 ? "la volonté de créer la surprise animera les débats" : "l'engagement des deux côtés promet un spectacle de qualité"}.`
    }),
    
    // Version 6
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `L'équipe de ${away_team} se présente avec un dynamisme ${getFormDescription(away_form)} (${away_form}%), occupant la ${away_position === 1 ? "première position" : `${away_position}ème place du classement`} avec ${away_points} points. Leur efficacité offensive impressionne avec une moyenne de ${away_goals} réalisations par match, tandis que leur organisation défensive se montre ${away_conceded < 1.2 ? "remarquablement efficace" : "globalement stable"} en n'encaissant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `En face, ${home_team}, ${home_position}ème avec ${home_points} points au compteur, affiche un rendement ${getFormDescription(home_form)} (${home_form}%). L'écart de ${pointsDiff} points illustre ${pointsDiff > 15 ? "la différence significative de niveau" : "le déséquilibre relatif"} entre ces deux clubs. Avec ${home_goals} but${home_goals > 1 ? "s" : ""} inscrit${home_goals > 1 ? "s" : ""} en moyenne par rencontre, leur secteur offensif demeure ${home_goals > 1.5 ? "une force notable" : "un chantier en construction"}, tandis que leur défense concède ${home_conceded} but${home_conceded > 1 ? "s" : ""} par match.`,
      
      conclusion: `Ce duel entre deux formations aux identités tactiques distinctes promet d'être passionnant à suivre. Les observateurs peuvent anticiper une rencontre intense et disputée, où ${pointsDiff > 15 ? "la quête de l'exploit motivera les actions" : "l'équilibre des forces laisse présager un match ouvert"}.`
    }),
    
    // Version 7
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `${away_team} traverse une période ${getFormDescription(away_form)} (${away_form}%), et se trouve ${away_position === 1 ? "au sommet du classement" : `à la ${away_position}ème position`} avec ${away_points} points à son actif. Leur force de frappe offensive se traduit par une moyenne de ${away_goals} buts par match, alors que leur solidité défensive se révèle ${away_conceded < 1.2 ? "exemplaire" : "correcte"} en ne concédant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `De l'autre côté, ${home_team}, qui pointe à la ${home_position}ème place avec ${home_points} points, montre des performances ${getFormDescription(home_form)} (${home_form}%). Les ${pointsDiff} points d'écart témoignent ${pointsDiff > 15 ? "d'une différence de niveau conséquente" : "d'un certain déséquilibre"} entre ces deux équipes. Avec ${home_goals} but${home_goals > 1 ? "s" : ""} par match en moyenne, leur efficacité offensive s'avère ${home_goals > 1.5 ? "satisfaisante" : "encore insuffisante"}, tandis que leur défense encaisse ${home_conceded} but${home_conceded > 1 ? "s" : ""} par match.`,
      
      conclusion: `Cette opposition entre deux collectifs aux caractéristiques différentes promet un spectacle de qualité. Les spectateurs peuvent s'attendre à une rencontre animée et engagée, où ${pointsDiff > 15 ? "la recherche de l'exploit guidera les actions" : "l'intensité des échanges devrait garantir un beau moment de football"}.`
    }),
    
    // Version 8
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `L'équipe visiteuse, ${away_team}, affiche une forme ${getFormDescription(away_form)} (${away_form}%) et se positionne ${away_position === 1 ? "en tête du championnat" : `à la ${away_position}ème place`} avec ${away_points} points. Leur secteur offensif fait preuve d'une belle efficacité avec ${away_goals} buts par match en moyenne, tandis que leur défense apparaît ${away_conceded < 1.2 ? "particulièrement solide" : "plutôt équilibrée"} en ne concédant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `Les locaux de ${home_team}, classés ${home_position}ème avec ${home_points} points, présentent une dynamique ${getFormDescription(home_form)} (${home_form}%). L'écart de ${pointsDiff} points matérialise ${pointsDiff > 15 ? "le gouffre existant" : "la différence actuelle"} entre ces deux formations. Trouvant le chemin des filets ${home_goals} fois par match en moyenne, leur attaque se montre ${home_goals > 1.5 ? "relativement prolifique" : "encore limitée"}, alors que leur défense encaisse ${home_conceded} but${home_conceded > 1 ? "s" : ""} par match en moyenne.`,
      
      conclusion: `Ce duel entre deux équipes aux approches tactiques contrastées s'annonce comme un moment fort de cette journée. Les amateurs présents peuvent anticiper une confrontation rythmée et disputée, où ${pointsDiff > 15 ? "la volonté de créer la surprise animera les débats" : "l'engagement des deux côtés promet un spectacle de qualité"}.`
    }),
    
    // Version 9
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `${away_team} arrive avec un état de forme ${getFormDescription(away_form)} (${away_form}%), et se trouve actuellement ${away_position === 1 ? "leader du championnat" : `à la ${away_position}ème place du classement`} avec ${away_points} points. Leur efficacité offensive impressionne avec une moyenne de ${away_goals} buts par rencontre, tandis que leur assise défensive se révèle ${away_conceded < 1.2 ? "particulièrement robuste" : "dans la norme"} en ne concédant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `De leur côté, ${home_team}, positionnés ${home_position}ème avec ${home_points} points, montrent une forme ${getFormDescription(home_form)} (${home_form}%). Les ${pointsDiff} points qui séparent les deux équipes illustrent ${pointsDiff > 15 ? "l'importante disparité" : "l'écart de niveau actuel"} entre ces formations. Avec une moyenne de ${home_goals} but${home_goals > 1 ? "s" : ""} par match, leur secteur offensif reste ${home_goals > 1.5 ? "une force à considérer" : "en développement"}, tandis que leur défense voit son but franchi ${home_conceded} fois par match en moyenne.`,
      
      conclusion: `Cette confrontation entre deux collectifs aux identités de jeu différentes s'annonce comme un véritable test de caractère. Les observateurs peuvent s'attendre à une rencontre dynamique et engagée, où ${pointsDiff > 15 ? "la quête de l'exploit guidera les actions" : "le suspense devrait être présent jusqu'au coup de sifflet final"}.`
    }),
    
    // Version 10
    (away_team, away_form, away_position, away_points, away_goals, away_conceded, home_team, home_position, home_points, home_form, home_goals, home_conceded, pointsDiff) => ({
      away_analysis: `L'équipe de ${away_team} se présente dans une dynamique ${getFormDescription(away_form)} (${away_form}%), occupant la ${away_position === 1 ? "position de leader" : `${away_position}ème position`} avec ${away_points} points au compteur. Leur puissance offensive fait des ravages avec une moyenne de ${away_goals} buts par match, pendant que leur défense se montre ${away_conceded < 1.2 ? "particulièrement difficile à manœuvrer" : "globalement stable"} en n'encaissant que ${away_conceded} but${away_conceded > 1 ? "s" : ""} en moyenne.`,
      
      home_analysis: `Face à eux, ${home_team}, ${home_position}ème avec ${home_points} points, affiche un rendement ${getFormDescription(home_form)} (${home_form}%). La différence de ${pointsDiff} points souligne ${pointsDiff > 15 ? "l'écart considérable" : "la distance actuelle"} entre ces deux clubs. Avec ${home_goals} but${home_goals > 1 ? "s" : ""} inscrit${home_goals > 1 ? "s" : ""} par rencontre en moyenne, leur attaque demeure ${home_goals > 1.5 ? "un atout majeur" : "un secteur à perfectionner"}, tandis que leur défense concède ${home_conceded} but${home_conceded > 1 ? "s" : ""} par match en moyenne.`,
      
      conclusion: `Ce duel entre deux équipes aux philosophies contrastées s'annonce comme un moment fort de cette journée. Le public peut anticiper une rencontre intense et disputée, où ${pointsDiff > 15 ? "l'ambition de renverser la hiérarchie sera palpable" : "l'équilibre des forces promet un spectacle de qualité"}.`
    })
  ];

  // Sélectionner une version aléatoire pour chaque rendu
  const randomTemplate = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * analysisTemplates.length);
    return analysisTemplates[randomIndex](
      away_team, away_form, away_position, away_points, away_goals, away_conceded,
      home_team, home_position, home_points, home_form, home_goals, home_conceded,
      pointsDiff
    );
  }, [matchId]); // Dépendance stable pour éviter de changer à chaque rendu

  return (
    <Col md={4} className={`${isExpanded ? "mb-5 pb-5" : "mb-2 pb-2"} pb-lg-0 mb-lg-4`}>
      <div
        style={{
          position: "relative",
          minHeight: isExpanded ? "855px" : "220px",
          marginBottom: isExpanded ? '5rem' : '0rem',
          transition: "min-height 0.2s ease-in-out",
        }}
      >
        <div
          style={{
            position: isExpanded ? "absolute" : "static",
            width: "100%",
            zIndex: isExpanded ? 1000 : 1,
          }}
        >
          <Card
            style={{
              width: "100%",
              backgroundColor: "white",
              boxShadow: isExpanded ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
            }}
            data-match-id={matchId}
          >
            <Card.Header className="d-flex justify-content-between align-items-center py-2">
              <span>{`${home_team} vs ${away_team}`}</span>
              <button
                onClick={handleToggle}
                className="btn btn-link p-0 text-dark"
                style={{ textDecoration: "none" }}
                aria-expanded={isExpanded}
                data-match-toggle={matchId}
              >
                {isExpanded ? <Minus size={20} /> : <Plus size={20} />}
              </button>
            </Card.Header>
            <Card.Body className="py-2">
              <div className="mb-2">
                {FootballIcons.clock} <strong>Date:</strong> {date}
              </div>
              <div>
                <Target size={16} className="me-2" />
                <strong>Analyse détaillée:</strong>
                <div className="mt-2">
                  <p>{randomTemplate.away_analysis}</p>
                  <p>{randomTemplate.home_analysis}</p>
                  <p className="mb-0">{randomTemplate.conclusion}</p>
                </div>
              </div>

              {isExpanded && (
                <div className="expanded-content" data-match-content={matchId}>
                  <hr className="my-2" />

                  <div className="row text-start">
                    <div>
                      <small className="text-muted">Buts marqués (moy.)</small>
                      <p className="mb-0">
                        {home_team}: {home_goals} | {away_team}: {away_goals}
                      </p>
                    </div>
                    <div>
                      <small className="text-muted">Buts concédés (moy.)</small>
                      <p className="mb-0">
                        {home_team}: {home_conceded} | {away_team}:{" "}
                        {away_conceded}
                      </p>
                    </div>
                    <div>
                      <small className="text-muted">Forme</small>
                      <p className="mb-0">
                        {home_team}: {home_form}% | {away_team}: {away_form}%
                      </p>
                    </div>
                    <div>
                      <small className="text-muted">Écart de points</small>
                      <p className="mb-0">{pointsDiff}</p>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Col>
  );
};

export default MatchPreview;