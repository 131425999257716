import React from "react";
import { Table, Card } from "react-bootstrap";

const BattleLeaderboard = ({ users }) => {
  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title>Classement des Utilisateurs</Card.Title>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Utilisateur</th>
              <th>Score</th>
              <th>Niveau</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.name}</td>
                <td>{user.score}</td>
                <td>{user.level}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default BattleLeaderboard;