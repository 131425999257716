import React, { useState, useEffect } from "react";

// Données des joueurs avec des images réelles
const players = [
  {
    id: 1,
    name: "Kylian Mbappé",
    image: "/images/km.jpg",
    team: "Real Madrid",
  },
  {
    id: 2,
    name: "Erling Haaland",
    image: "/images/el.webp",
    team: "Man City",
  },
  {
    id: 3,
    name: "Jude Bellingham",
    image: "/images/jb.webp",
    team: "Real Madrid",
  },
  {
    id: 4,
    name: "Marcus Rashford",
    image: "/images/mr.jpg",
    team: "Aston Villa",
  },
  {
    id: 5,
    name: "Victor Osimhen",
    image: "/images/vo.jpg",
    team: "Galatasaray",
  },
  {
    id: 6,
    name: "Mohamed Salah",
    image: "/images/ms.webp",
    team: "Liverpool",
  },
  {
    id: 7,
    name: "Lionel Messi",
    image: "/images/lm.jpeg",
    team: "Inter Miami",
  },
  {
    id: 8,
    name: "Cristiano Ronaldo",
    image: "/images/cr.webp",
    team: "Al-Nassr",
  },
  {
    id: 9,
    name: "Vinicius Júnior",
    image: "/images/vj.jpg",
    team: "Real Madrid",
  },
  {
    id: 10,
    name: "Lamine Yamal",
    image: "/images/lm.jpg",
    team: "Barcelona",
  },
  {
    id: 11,
    name: "Neymar",
    image: "/images/nj.jpg",
    team: "Santos",
  },
  {
    id: 12,
    name: "Pedri",
    image: "/images/pg.jpg",
    team: "Barcelona",
  },
  {
    id: 13,
    name: "Kevin De Bruyne",
    image: "/images/kdb.webp",
    team: "Manchester City",
  },
  {
    id: 14,
    name: "Rodrygo",
    image: "/images/rg.jpg",
    team: "Real Madrid",
  },
  {
    id: 15,
    name: "Harry Kane",
    image: "/images/hk.webp",
    team: "Bayern Munich",
  },
  {
    id: 16,
    name: "Ousmane Dembélé",
    image: "/images/od.webp",
    team: "PSG",
  },
  {
    id: 17,
    name: "Bukayo Saka",
    image: "/images/bs.avif",
    team: "Arsenal",
  },
  {
    id: 18,
    name: "Raphinha",
    image: "/images/r.jpg",
    team: "Barcelona",
  },
  {
    id: 19,
    name: "Lautaro Martinez",
    image: "/images/lma.jpg",
    team: "Inter Milan",
  },
  {
    id: 20,
    name: "Marc Allister",
    image: "/images/mc.webp",
    team: "Liverpool",
  },
  {
    id: 21,
    name: "Phil Foden",
    image: "/images/pf.jpg",
    team: "Manchester City",
  },
  {
    id: 22,
    name: "Bruno Fernandes",
    image: "/images/bf.jpg",
    team: "Manchester United",
  },
  {
    id: 23,
    name: "Federico Valverde",
    image: "/images/fv.jpeg",
    team: "Real Madrid",
  },
  {
    id: 24,
    name: "Rafael Leão",
    image: "/images/rl.webp",
    team: "AC Milan",
  },
  {
    id: 25,
    name: "Jamal Musiala",
    image: "/images/jm.webp",
    team: "Bayern Munich",
  },
  {
    id: 26,
    name: "Julián Álvarez",
    image: "/images/ja.jpg",
    team: "Atlético Madrid",
  },
  {
    id: 27,
    name: "Florian Wirtz",
    image: "/images/fw.webp",
    team: "Bayer Leverkusen",
  },
  {
    id: 28,
    name: "Khvicha Kvaratskhelia",
    image: "/images/kk.webp",
    team: "PSG",
  },
  {
    id: 29,
    name: "Gavi",
    image: "/images/ga.jpg",
    team: "Barcelona",
  },
  {
    id: 30,
    name: "Joško Gvardiol",
    image: "/images/jg.avif",
    team: "Manchester City",
  },
  {
    id: 31,
    name: "Martin Ødegaard",
    image: "/images/mo.avif",
    team: "Arsenal",
  },
  {
    id: 32,
    name: "Randal Kolo Muani",
    image: "/images/rk.webp",
    team: "Juventus",
  },
  {
    id: 33,
    name: "Declan Rice",
    image: "/images/dr.webp",
    team: "Arsenal",
  },
  {
    id: 34,
    name: "Aurélien Tchouaméni",
    image: "/images/ac.webp",
    team: "Real Madrid",
  },
  {
    id: 35,
    name: "Eduardo Camavinga",
    image: "/images/ec.jpg",
    team: "Real Madrid",
  },
  {
    id: 36,
    name: "Rodri",
    image: "/images/rod.jpg",
    team: "Manchester City",
  },
  {
    id: 37,
    name: "Cole Palmer",
    image: "/images/cp.jpg",
    team: "Chelsea",
  },
  {
    id: 38,
    name: "Nico Williams",
    image: "/images/nw.webp",
    team: "Athletic Bilbao",
  },
  {
    id: 39,
    name: "Nicolò Barella",
    image: "/images/nb.jpg",
    team: "Inter Milan",
  },
  {
    id: 40,
    name: "Karim Adeyemi",
    image: "/images/ka.webp",
    team: "Borussia Dortmund",
  },
  {
    id: 41,
    name: "William Saliba",
    image: "/images/ws.webp",
    team: "Arsenal",
  },
  {
    id: 42,
    name: "Benjamin Šeško",
    image: "/images/bs.jpg",
    team: "RB Leipzig",
  },
  {
    id: 43,
    name: "Kobbie Mainoo",
    image: "/images/km.webp",
    team: "Manchester United",
  },
  {
    id: 44,
    name: "Ademola Lookman",
    image: "/images/al.jpg",
    team: "Atalanta",
  },
  {
    id: 45,
    name: "Dominik Szoboszlai",
    image: "/images/dz.jpg",
    team: "Liverpool",
  },
  {
    id: 46,
    name: "Xavi Simons",
    image: "/images/xs.jpg",
    team: "RB Leipzig",
  },
  {
    id: 47,
    name: "Micky van de Ven",
    image: "/images/nv.jpg",
    team: "Tottenham Hotspur",
  },
  {
    id: 48,
    name: "Gonçalo Ramos",
    image: "/images/gr.jpg",
    team: "PSG",
  },
  {
    id: 49,
    name: "Warren Zaïre-Emery",
    image: "/images/wz.jpg",
    team: "PSG",
  },
  {
    id: 50,
    name: "Amadou Onana",
    image: "/images/ao.jpg",
    team: "Aston Villa",
  },
];

const HeroSection = ({ bannerImages, currentSlide, onAnalyticsClick }) => {
  const [randomPlayers, setRandomPlayers] = useState([]);

  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const getRandomPlayers = () => {
      setIsChanging(true); // Déclenche l'animation de sortie

      setTimeout(() => {
        const shuffled = [...players]
          .sort(() => 0.5 - Math.random())
          .slice(0, 4);
        setRandomPlayers(shuffled);
        setIsChanging(false); // Déclenche l'animation d'entrée
      }, 300); // Délai correspondant à la durée de l'animation de sortie
    };

    getRandomPlayers();
    const interval = setInterval(getRandomPlayers, 3500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="position-relative"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes fadeOutDown {
            from {
              opacity: 1;
              transform: translateY(0);
            }
            to {
              opacity: 0;
              transform: translateY(20px);
            }
          }

          .player-card {
            animation: fadeInUp 0.3s ease-out forwards;
            transition: all 0.3s ease;
          }

          .player-card:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 20px rgba(0,0,0,0.3);
          }

          .player-card.changing {
            animation: fadeOutDown 0.3s ease-in forwards;
          }

          .players-container {
            display: none;
          }

          .hero-content {
            position: absolute;
            width: 100%;
            text-align: center;
            padding: 0 1rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease;
          }

          @media (min-width: 992px) {
            .players-container {
              display: block;
            }
            
            .hero-content {
              top: 30%;
              transform: translateY(-30%);
            }
          }

          .card-hover-zoom {
            transition: transform 0.3s ease-in-out;
          }

          .card-hover-zoom:hover {
            transform: scale(1.05); /* Ajustez la valeur pour contrôler le niveau de zoom */
          }
        `}
      </style>

      {/* <style>
        {`
          @keyframes slideInFromBottom {
            0% {
              opacity: 0;
              transform: translateY(100%) scale(0.8);
            }
            100% {
              opacity: 1;
              transform: translateY(0) scale(1);
            }
          }

          @keyframes slideOutToTop {
            0% {
              opacity: 1;
              transform: translateY(0) scale(1);
            }
            100% {
              opacity: 0;
              transform: translateY(-100%) scale(0.8);
            }
          }

          .player-card {
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            animation: slideInFromBottom 0.4s ease-out forwards;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
            border: none;
          }

          .player-card.changing {
            animation: slideOutToTop 0.4s ease-in forwards;
          }

          .player-card::after {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              transparent,
              rgba(255, 255, 255, 0.2),
              transparent
            );
            transition: 0.5s;
          }

          .player-card:hover::after {
            left: 100%;
          }

          .player-card:hover {
            transform: translateY(-10px);
            box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
          }

          .player-card:hover .card-img-top {
            transform: scale(1.1);
          }

          .card-img-top {
            transition: transform 0.5s ease;
            position: relative;
            z-index: 1;
          }

          .card-body {
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 0.95)
            );
            position: relative;
            z-index: 2;
          }

          .card-body::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: linear-gradient(
              to right,
              transparent,
              rgba(255, 255, 255, 0.3),
              transparent
            );
          }

          .card-title {
            font-weight: 600;
            margin-bottom: 4px;
            position: relative;
            display: inline-block;
          }

          .card-title::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            height: 2px;
            background: #fff;
            transition: width 0.3s ease;
          }

          .player-card:hover .card-title::after {
            width: 100%;
          }

          .card-text {
            opacity: 0.8;
            font-size: 0.9em;
            transition: opacity 0.3s ease;
          }

          .player-card:hover .card-text {
            opacity: 1;
          }

          .players-container {
            display: none;
          }

          .hero-content {
            position: absolute;
            width: 100%;
            text-align: center;
            padding: 0 1rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease;
          }

          @media (min-width: 992px) {
            .players-container {
              display: block;
            }
            
            .hero-content {
              top: 30%;
              transform: translateY(-30%);
            }
          }
        `}
      </style> */}

      <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active" style={{ height: "100vh" }}>
            <div
              style={{
                backgroundImage: `url(${bannerImages[currentSlide]})`,
                height: "100vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div
                className="position-absolute top-0 start-0 w-100 h-100 bg-dark"
                style={{ opacity: 0.5 }}
              ></div>

              <div
                className="position-absolute top-0 start-0 w-100 h-100 bg-dark"
                style={{ opacity: 0.5 }}
              ></div>

              <div className="hero-content text-white">
                <h1 className="display-6 fw-bold mb-4">
                  Prédictions de Football avec MatchSynce
                </h1>
                <p className="lead mb-4">
                  Analyses statistiques avancées et prédictions précises pour
                  vos paris sportifs
                </p>
                <button
                  className="btn btn-lg btn-success w-sm-75 py-2 card-hover-zoom"
                  onClick={onAnalyticsClick}
                >
                  Maximisez vos chances
                </button>
              </div>

              <div className="position-absolute bottom-0 w-100 pb-4 players-container">
                <div className="container">
                  <div className="row justify-content-center">
                    {randomPlayers.map((player) => (
                      <div key={player.id} className="col-lg-3 col-md-6 px-2 card-hover-zoom">
                        <div
                          className={`card bg-dark bg-opacity-75 text-white text-center border-light player-card ${
                            isChanging ? "changing" : ""
                          }`}
                          style={{ maxHeight: "280px" }}
                        >
                          <img
                            src={player.image}
                            className="card-img-top"
                            alt={player.name}
                            style={{ height: "200px", objectFit: "cover" }}
                            onError={(e) => {
                              e.target.src = "/api/placeholder/200/300";
                            }}
                          />
                          <div className="card-body p-2">
                            <h5 className="card-title mb-0">{player.name}</h5>
                            <p className="card-text small">{player.team}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
