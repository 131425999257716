import React from 'react';
import { Alert } from 'react-bootstrap';

export function OfflineHandler({ children }) {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [lastUpdate, setLastUpdate] = React.useState(localStorage.getItem('lastDataUpdate'));

  React.useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <Alert variant="warning" className="m-2">
          <Alert.Heading>Mode Hors-ligne</Alert.Heading>
          <p>
            Vous êtes actuellement en mode hors-ligne. Les données affichées sont celles du {lastUpdate || 'dernier chargement'}.
          </p>
        </Alert>
      )}
      {children}
    </>
  );
}