import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Container,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import { MessageCircleCode, Trophy, Brain } from "lucide-react";
import Chat from "./Chat";
import BattleChallenge from "./Battle/BattleChallenge";
import UserProfile from "./Battle/UserProfile";
import PronosticForm from "./Battle/PronosticForm";
import BattleLeaderboard from "./Battle/BattleLeaderboard";
import WelcomePage from "./MatchSynceIA/WelcomePage";
import MatchSelectionPage from "./MatchSynceIA/MatchSelectionPage";
import OptionSelectionPage from "./MatchSynceIA/OptionSelectionPage";
import IAChatPage from "./MatchSynceIA/IAChatPage";
import DateSelectionPage from "./MatchSynceIA/DateSelectionPage";
import MainOptionSelectionPage from "./MatchSynceIA/MainOptionSelectionPage"; // Importez le nouveau composant
import SafeDuJour from "./MatchSynceIA/SafeDuJour";
import SafeDeLaSemaine from "./MatchSynceIA/SafeDeLaSemaine";
import PlayerStatistics from "./MatchSynceIA/PlayerStatistics";

const LandingNavbar = ({ onAnalyticsClick }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showBattle, setShowBattle] = useState(false);
  const [showIA, setShowIA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const [currentPage, setCurrentPage] = useState("welcome"); // État pour gérer la page actuelle
  const [selectedDate, setSelectedDate] = useState(null); // État pour stocker la date sélectionnée
  const [selectedMatch, setSelectedMatch] = useState(null); // État pour stocker le match sélectionné
  const [selectedOption, setSelectedOption] = useState(null); // État pour stocker l'option sélectionnée
  const [showSafeDuJour, setShowSafeDuJour] = useState(false);
  const [showSafeDeLaSemaine, setShowSafeDeLaSemaine] = useState(false);
  const [showPlayerStatistics, setShowPlayerStatistics] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleChatOpen = () => {
    setShowChat(true);
    setIsLoading(true);
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleChatClose = () => {
    setShowChat(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  const handleBattleOpen = () => {
    setShowBattle(true);
    setIsLoading(true);
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleBattleClose = () => {
    setShowBattle(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  //Battle

  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    id: 1,
    name: "John Doe",
    score: 100,
    level: 5,
    badges: ["Champion"],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const ligues = [
    { code: "liga", nom: "Liga", flag: "🇪🇸" },
    { code: "bundesliga", nom: "Bundesliga", flag: "🇩🇪" },
    { code: "premier_league", nom: "PL", flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿" },
    { code: "serie_a", nom: "Serie A", flag: "🇮🇹" },
    { code: "ligue_1", nom: "Ligue 1", flag: "🇫🇷" },
    { code: "champions_league", nom: "Champions League", flag: "🇪🇺" },
    { code: "europa_league", nom: "Europa League", flag: "🇪🇺" },
    { code: "conference_league", nom: "Conference League", flag: "🇪🇺" },
  ];

  useEffect(() => {
    loadAllMatches();
  }, []);

  const loadAllMatches = async () => {
    setLoading(true);
    try {
      // Créer un tableau de promesses pour toutes les requêtes
      const promises = ligues.map(async (ligue) => {
        try {
          const response = await fetch(`/${ligue.code}_predictions_viz.json`);
          const data = await response.json();

          // Vérifier si data et data.match_overview existent
          if (data && data.match_overview) {
            return data.match_overview.map((match) => ({
              ...match,
              league: ligue.code,
              leagueName: ligue.nom,
              flag: ligue.flag,
              uniqueId: `${ligue.code}-${match.home_team}-${match.away_team}-${match.date}`,
            }));
          } else {
            console.warn(`Aucune donnée de match pour la ligue ${ligue.code}`);
            return []; // Retourner un tableau vide si pas de données
          }
        } catch (error) {
          console.error(
            `Erreur lors du chargement des matchs pour ${ligue.code}:`,
            error
          );
          return []; // Retourner un tableau vide en cas d'erreur
        }
      });

      // Attendre que toutes les requêtes soient terminées
      const allMatchesArrays = await Promise.all(promises);

      // Combiner tous les matchs en un seul tableau
      const allMatches = allMatchesArrays.flat();

      // Trier les matchs par date
      const sortedMatches = allMatches.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      setMatches(sortedMatches);
      // console.log("Tous les matchs chargés:", sortedMatches);
    } catch (error) {
      console.error("Erreur lors du chargement des matchs:", error);
      setError("Erreur lors du chargement des matchs. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPronostics = (predictions) => {
    console.log("Pronostics soumis:", predictions);
  };

  const handleChallenge = (userId) => {
    console.log("Défi lancé contre l'utilisateur:", userId);
  };

  //MatchSynce IA

  const handleIAOpen = () => {
    setShowIA(true);
    setIsLoading(true);
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleIAClose = () => {
    setShowIA(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  // Gestion des transitions entre les pages
  const handleStart = () => setCurrentPage("mainOptionSelection");
  const handleMainOptionSelect = (option) => {
    if (option === "Résultat sur un match") {
      setCurrentPage("matchSelection");
    } else if (option === "Safe du jour") {
      setShowSafeDuJour(true);
      setShowSafeDeLaSemaine(false);
      setShowPlayerStatistics(false);
    } else if (option === "Safe de la semaine") {
      setShowSafeDeLaSemaine(true);
      setShowSafeDuJour(false);
      setShowPlayerStatistics(false);
    } else if (option === "Statistiques des joueurs") {
      setShowPlayerStatistics(true);
      setShowSafeDuJour(false);
      setShowSafeDeLaSemaine(false);
    }
  };
  const handleDateSelect = (date, matches) => {
    setSelectedDate(date);
    setCurrentPage("matchSelection");
  };
  const handleMatchSelect = (match) => {
    setSelectedMatch(match);
    setCurrentPage("optionSelection");
  };
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setCurrentPage("iaChat");
  };
  const handleBack = () => {
    if (currentPage === "dateSelection") setCurrentPage("welcome");
    if (currentPage === "matchSelection") setCurrentPage("mainOptionSelection");
    if (currentPage === "optionSelection") setCurrentPage("matchSelection");
    if (currentPage === "iaChat") setCurrentPage("optionSelection");
    if (currentPage === "mainOptionSelection") setCurrentPage("welcome");
    if (showSafeDuJour || showSafeDeLaSemaine || showPlayerStatistics) {
      setShowSafeDuJour(false);
      setShowSafeDeLaSemaine(false);
      setShowPlayerStatistics(false);
      setCurrentPage("mainOptionSelection");
    }
  };
  const handleRestart = () => setCurrentPage("matchSelection");
  const handleCloseIA = () => setCurrentPage("welcome");

  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className={`transition-all ${isScrolled ? "bg-white shadow-sm py-2 is-scrolled" : "bg-transparent py-3"}`}
      >
        <Container>
          <Navbar.Brand href="#" className="d-flex align-items-center">
            <img
              src="/logo_mini.png"
              height="40"
              className="d-inline-block align-top me-2"
              alt="MatchSynce Logo"
            />
            <span
              className={`fw-bold ${isScrolled ? "text-dark" : "text-dark"}`}
            >
              MatchSynce
            </span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="mx-auto text-lg-center text-start"></Nav>

            <div className="d-lg-flex flex-lg-row align-items-start mt-3 mt-lg-0">
              <Button
                variant="success"
                className="d-flex align-items-center mb-2 mb-lg-0"
                onClick={handleChatOpen}
              >
                <MessageCircleCode size={20} className="me-2" />
                Messagerie
              </Button>
              <Button
                variant="success"
                className="d-flex align-items-center ms-lg-3 ms-lg-0"
                onClick={handleBattleOpen}
              >
                <Trophy size={20} className="me-2" />
                MatchSynce Battle
              </Button>
              <Button
                variant="danger"
                className="d-flex align-items-center ms-lg-3 ms-lg-0 mt-lg-0 mt-2"
                onClick={handleIAOpen}
              >
                <Brain size={20} className="me-2" />
                MatchSynce IA
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>

        <style>
          {`
          .transition-all {
            transition: all 0.3s ease-in-out;
          }
          
          .navbar-toggler {
            border-color: ${isScrolled ? "#000" : "#fff"};
            outline: none !important;
            // border: none !important;
            border-radius: 0 !important;
            box-shadow: none !important;
          }
          
          .navbar-toggler-icon {
            background-image: url('/images/b-noir.png');  /
            background-size: contain;
            background-repeat: no-repeat;
            width: 30px;  /* Ajuste la taille de l'icône */
            height: 30px; /* Ajuste la taille de l'icône */
          }

          .navbar.is-scrolled .navbar-toggler-icon {
            background-image: url('/images/b-noir.png');
          }
          
          @media (max-width: 991.98px) {
            .navbar-collapse {
              background-color: #000;
              padding: 1rem;
              border-radius: 8px;
              margin-top: 0.5rem;
            }
          }
        `}
        </style>
      </Navbar>

      {/* Chat Modal */}
      {showChat && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "92vw", // Largeur maximale du modal
              width: "92vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Communauté MatchSynce</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleChatClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src="/matchsynce_chat.png"
                      alt="MatchSynce Logo"
                      className="zoom-animation"
                      style={{ width: "180px", height: "130px" }}
                    />
                  </div>
                ) : (
                  <Chat style={{ height: "100%" }} /> // Le composant Chat prend toute la hauteur disponible
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .zoom-animation {
            animation: zoomInOut 1.5s ease-in-out;
          }

          @keyframes zoomInOut {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            50% {
              transform: scale(1.2);
              opacity: 1;
            }
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }

          .transition-all {
            transition: all 0.3s ease-in-out;
          }
          
          .navbar-toggler {
            border-color: #000;
          }
          
          .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='${
              isScrolled ? "%23000" : "%23000"
            }' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
          }
          
          @media (max-width: 991.98px) {
            .navbar-collapse {
              background-color: ${isScrolled ? "#fff" : "rgba(0,0,0,0.7)"};
              padding: 1rem;
              border-radius: 2px;
              margin-top: 0.5rem;
            }
          }
        `}
      </style>

      {showBattle && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "93vw", // Largeur maximale du modal
              width: "93vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">MatchSynce Battle</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleBattleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src="/matchsynce_battle.png"
                      alt="MatchSynce Logo"
                      className="zoom-animation"
                      style={{ width: "130px", height: "180px" }}
                    />
                  </div>
                ) : (
                  <Container fluid className="h-100 p-0">
                    <Row className="h-100">
                      <Col className="h-100">
                        <PronosticForm
                          matches={matches}
                          onSubmit={handleSubmitPronostics}
                          style={{ height: "100%" }} // Prendre toute la hauteur disponible
                        />
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showIA && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-full-mobile"
            style={{
              maxWidth: "80vw", // Largeur maximale du modal
              width: "80vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">MatchSynce IA</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleIAClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                <Container className="mt-5">
                  {currentPage === "welcome" && (
                    <WelcomePage onStart={handleStart} />
                  )}
                  {currentPage === "mainOptionSelection" &&
                    !showSafeDuJour &&
                    !showSafeDeLaSemaine &&
                    !showPlayerStatistics && (
                      <MainOptionSelectionPage
                        matches={matches}
                        onOptionSelect={handleMainOptionSelect}
                        onBack={handleBack}
                      />
                    )}
                  {currentPage === "matchSelection" && (
                    <MatchSelectionPage
                      matches={matches}
                      onMatchSelect={handleMatchSelect}
                      onBack={handleBack}
                    />
                  )}
                  {currentPage === "optionSelection" && (
                    <OptionSelectionPage
                      match={selectedMatch}
                      onOptionSelect={handleOptionSelect}
                      onBack={handleBack}
                    />
                  )}
                  {currentPage === "iaChat" && (
                    <IAChatPage
                      match={selectedMatch}
                      option={selectedOption}
                      onBack={handleBack}
                      onRestart={handleRestart}
                      onClose={handleCloseIA}
                    />
                  )}
                  {showSafeDuJour && (
                    <SafeDuJour
                      matches={matches}
                      onBack={() => setShowSafeDuJour(false)}
                    />
                  )}
                  {showSafeDeLaSemaine && (
                    <SafeDeLaSemaine
                      matches={matches}
                      onBack={() => setShowSafeDeLaSemaine(false)}
                    />
                  )}
                  {showPlayerStatistics && (
                    <PlayerStatistics
                      matches={matches}
                      onBack={() => setShowPlayerStatistics(false)}
                    />
                  )}
                </Container>
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          @media (max-width: 768px) {
            .modal-full-mobile {
              max-width: 95% !important;
              width: 95% !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default LandingNavbar;
