import React from 'react';
import { Card } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const InteractiveCharts = ({ match }) => {
  const data = [
    { name: 'Buts marqués', [match.home_team]: match.home_goals, [match.away_team]: match.away_goals },
    { name: 'Buts encaissés', [match.home_team]: match.home_conceded, [match.away_team]: match.away_conceded },
  ];

  return (
    <Card className="shadow-sm mt-4">
      <Card.Body>
        <h5 className="text-primary">Statistiques du match :</h5>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={match.home_team} fill="#8884d8" />
            <Bar dataKey={match.away_team} fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

export default InteractiveCharts;