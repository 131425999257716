import React from 'react';
import { Button, Container } from 'react-bootstrap';

const HomePage = ({ onShowWelcome }) => {
    return (
        <Container className="mt-5">
            <Button variant="primary" onClick={onShowWelcome}>
                Afficher la page d'accueil
            </Button>
        </Container>
    );
};

export default HomePage;