import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons"; // Import de l'icône de croix

const UpdateModal = () => {
  const [show, setShow] = useState(true);

  // Fonction pour fermer le modal
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered // Centre le modal sur l'écran
        backdropClassName="custom-backdrop" // Classe CSS personnalisée pour l'arrière-plan
        size="lg" // Taille du modal (large)
        className="update-modal" // Classe CSS personnalisée
      >
        {/* <Modal.Header className="border-0 position-relative">
          <Button
            variant="link"
            onClick={handleClose}
            className="position-absolute top-0 end-0 p-3"
            style={{ color: "#6c757d" }}
          >
            <X size={24} />
          </Button>
        </Modal.Header> */}
        <Modal.Body className="text-center p-1">
          <div
            style={{
              position: "relative",
              backgroundImage: "url('/images/l1.jpg')", // Image de fond
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
              padding: "40px",
              color: "white",
            }}
          >
            {/* Shadow transparent sur le contenu */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Shadow transparent
                borderRadius: "5px",
              }}
            ></div>
            
            {/* Contenu du modal (texte et boutons) au-dessus du shadow */}
            <div style={{ position: "relative", zIndex: 1 }}>
              <h2 style={{ fontWeight: "bold", marginBottom: "20px" }}>
                <span className="rocket-animation me-4">🚀</span>  IMMINENT SUR MATCHSYNCE ! 
              </h2>
              {/* <img src="/images/ligue1.jpg" height={80} alt="" /> */}
              <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
                L'élite du football français arrive. Suivez bientôt tous les
                matchs de la Ligue 1 avec nos analyses détaillées et
                personnalisées. Des duels parisiens aux derbys bouillants,
                préparez-vous à vivre le championnat comme jamais auparavant.
                Restez connectés !
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  variant="light"
                  onClick={handleClose}
                  style={{
                    fontWeight: "bold",
                    padding: "10px 20px",
                    borderRadius: "25px",
                  }}
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Styles CSS pour l'arrière-plan semi-transparent et l'animation de la fusée */}
      <style>
        {`
          .custom-backdrop {
            background-color: rgba(0, 0, 0, 0.6) !important; 
          }

          .update-modal .modal-content {
            border-radius: 15px;
            overflow: hidden;
            border: none;
          }

          .update-modal .modal-header {
            border: none;
            padding: 0;
          }

          .update-modal .modal-body {
            padding: 0;
          }
          
          @keyframes rocketFly {
            0% { 
              transform: translateY(0) translateX(0) rotate(0deg); 
              display: inline-block;
            }
            25% { 
              transform: translateY(-20px) translateX(15px) rotate(35deg); 
            }
            50% { 
              transform: translateY(-10px) translateX(30px) rotate(10deg); 
            }
            75% { 
              transform: translateY(-5px) translateX(15px) rotate(-10deg); 
            }
            100% { 
              transform: translateY(0) translateX(0) rotate(0deg); 
            }
          }
          
          .rocket-animation {
            display: inline-block;
            animation: rocketFly 3s ease-in-out infinite;
          }
        `}
      </style>
    </>
  );
};

export default UpdateModal;