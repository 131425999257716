// IAAnalysisVersions.js

import { predictScore } from "./Utils/matchUtils";

export const tacticalAnalysisVersions = [
    // Version 1
    (match) => `Analyse Tactique Avancée : ${match.home_team} et ${match.away_team} adoptent des approches tactiques contrastées. ${match.home_team}, classé ${match.home_position}ème, mise sur une défense solide malgré une forme récente en demi-teinte (${match.home_form}% de victoires). En revanche, ${match.away_team}, mieux classé (${match.away_position}ème), montre une forme plus convaincante (${match.away_form}%) et une attaque plus efficace avec ${match.away_goals} buts par match en moyenne. La probabilité de victoire de ${match.home_team} est de ${match.win_probability}%, mais ${match.away_team} pourrait exploiter les faiblesses défensives de l'adversaire.`,
  
    // Version 2
    (match) => `Analyse Tactique Avancée : Le match oppose deux équipes aux styles de jeu distincts. ${match.home_team}, avec une moyenne de ${match.home_goals} buts marqués et ${match.home_conceded} buts encaissés par match, privilégie un jeu défensif. En face, ${match.away_team}, plus offensif, marque en moyenne ${match.away_goals} buts par match. La probabilité de victoire de ${match.home_team} est faible (${match.win_probability}%), mais leur défense pourrait limiter les dégâts.`,
  
    // Version 3
    (match) => `Analyse Tactique Avancée : ${match.home_team} et ${match.away_team} présentent des dynamiques opposées. ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), doit compter sur sa défense pour contenir l'attaque de ${match.away_team}, qui a marqué ${match.away_goals} buts par match. La probabilité de victoire de ${match.home_team} est de ${match.win_probability}%, mais leur faible forme récente pourrait être un handicap.`,
  
    // Version 4
    (match) => `Analyse Tactique Avancée : ${match.home_team}, classé ${match.home_position}ème, affiche une forme récente décevante (${match.home_form}% de victoires). En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match). La probabilité de victoire de ${match.home_team} est faible (${match.win_probability}%), mais leur défense pourrait résister.`,
  
    // Version 5
    (match) => `Analyse Tactique Avancée : Le match oppose ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), à ${match.away_team}, plus performant (${match.away_form}% de victoires). ${match.home_team} a une moyenne de ${match.home_goals} buts marqués et ${match.home_conceded} buts encaissés, tandis que ${match.away_team} marque en moyenne ${match.away_goals} buts par match. La probabilité de victoire de ${match.home_team} est de ${match.win_probability}%.`,
  
    // Version 6
    (match) => `Analyse Tactique Avancée : ${match.home_team}, classé ${match.home_position}ème, affiche une forme récente en demi-teinte (${match.home_form}% de victoires). En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match). La probabilité de victoire de ${match.home_team} est faible (${match.win_probability}%), mais leur défense pourrait limiter les dégâts.`,
  
    // Version 7
    (match) => `Analyse Tactique Avancée : ${match.home_team} et ${match.away_team} adoptent des approches tactiques contrastées. ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), doit compter sur sa défense pour contenir l'attaque de ${match.away_team}, qui a marqué ${match.away_goals} buts par match. La probabilité de victoire de ${match.home_team} est de ${match.win_probability}%.`,
  
    // Version 8
    (match) => `Analyse Tactique Avancée : Le match oppose ${match.home_team}, classé ${match.home_position}ème, à ${match.away_team}, mieux classé (${match.away_position}ème). ${match.home_team} a une moyenne de ${match.home_goals} buts marqués et ${match.home_conceded} buts encaissés, tandis que ${match.away_team} marque en moyenne ${match.away_goals} buts par match. La probabilité de victoire de ${match.home_team} est faible (${match.win_probability}%).`,
  
    // Version 9
    (match) => `Analyse Tactique Avancée : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), affronte ${match.away_team}, plus performant (${match.away_form}% de victoires). ${match.home_team} a une moyenne de ${match.home_goals} buts marqués et ${match.home_conceded} buts encaissés, tandis que ${match.away_team} marque en moyenne ${match.away_goals} buts par match. La probabilité de victoire de ${match.home_team} est de ${match.win_probability}%.`,
  
    // Version 10
    (match) => `Analyse Tactique Avancée : ${match.home_team}, classé ${match.home_position}ème, affiche une forme récente en demi-teinte (${match.home_form}% de victoires). En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match). La probabilité de victoire de ${match.home_team} est faible (${match.win_probability}%), mais leur défense pourrait résister.`,
  ];
  
  export const performancePredictionVersions = [
    // Version 1
    (match) => `Prédictions Performances : ${match.home_team}, classé ${match.home_position}ème, a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une forme récente plus convaincante (${match.away_form}% de victoires) et une attaque plus efficace (${match.away_goals} buts par match).`,
  
    // Version 2
    (match) => `Prédictions Performances : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match).`,
  
    // Version 3
    (match) => `Prédictions Performances : ${match.home_team}, classé ${match.home_position}ème, a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une forme récente plus convaincante (${match.away_form}% de victoires).`,
  
    // Version 4
    (match) => `Prédictions Performances : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match).`,
  
    // Version 5
    (match) => `Prédictions Performances : ${match.home_team}, classé ${match.home_position}ème, a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une forme récente plus convaincante (${match.away_form}% de victoires).`,
  
    // Version 6
    (match) => `Prédictions Performances : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match).`,
  
    // Version 7
    (match) => `Prédictions Performances : ${match.home_team}, classé ${match.home_position}ème, a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une forme récente plus convaincante (${match.away_form}% de victoires).`,
  
    // Version 8
    (match) => `Prédictions Performances : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match).`,
  
    // Version 9
    (match) => `Prédictions Performances : ${match.home_team}, classé ${match.home_position}ème, a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une forme récente plus convaincante (${match.away_form}% de victoires).`,
  
    // Version 10
    (match) => `Prédictions Performances : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), a une probabilité de victoire de ${match.win_probability}%. Leur attaque marque en moyenne ${match.home_goals} buts par match, mais leur défense encaisse ${match.home_conceded} buts. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match).`,
  ];
  
  export const exactScoreProjectionVersions = [
    // Version 1
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact suggère un résultat de ${prediction.score} en faveur de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}. Cette prédiction est basée sur la meilleure forme récente de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team} et leur attaque plus efficace.`;
    },
  
    // Version 2
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact indique un résultat de ${prediction.score}. Cette prédiction est basée sur la défense solide de ${prediction.score.split('-')[1] < prediction.score.split('-')[0] ? match.home_team : match.away_team} et la faible attaque de ${prediction.score.split('-')[1] > prediction.score.split('-')[0] ? match.home_team : match.away_team}.`;
    },
  
    // Version 3
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact suggère un résultat de ${prediction.score}. Cette prédiction est basée sur les performances équilibrées des deux équipes, avec une attaque et une défense similaires.`;
    },
  
    // Version 4
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact indique un résultat de ${prediction.score} en faveur de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}. Cette prédiction est basée sur la meilleure forme récente de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}.`;
    },
  
    // Version 5
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact suggère un résultat de ${prediction.score} en faveur de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}. Cette prédiction est basée sur la meilleure forme récente de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team} et leur attaque plus efficace.`;
    },
  
    // Version 6
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact indique un résultat de ${prediction.score}. Cette prédiction est basée sur la défense solide de ${prediction.score.split('-')[1] < prediction.score.split('-')[0] ? match.home_team : match.away_team} et la faible attaque de ${prediction.score.split('-')[1] > prediction.score.split('-')[0] ? match.home_team : match.away_team}.`;
    },
  
    // Version 7
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact suggère un résultat de ${prediction.score}. Cette prédiction est basée sur les performances équilibrées des deux équipes, avec une attaque et une défense similaires.`;
    },
  
    // Version 8
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact indique un résultat de ${prediction.score} en faveur de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}. Cette prédiction est basée sur la meilleure forme récente de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}.`;
    },
  
    // Version 9
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact suggère un résultat de ${prediction.score} en faveur de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team}. Cette prédiction est basée sur la meilleure forme récente de ${prediction.score.split('-')[0] > prediction.score.split('-')[1] ? match.home_team : match.away_team} et leur attaque plus efficace.`;
    },
  
    // Version 10
    (match) => {
      const prediction = predictScore(match);
      return `Projection Score Exact : La projection du score exact indique un résultat de ${prediction.score}. Cette prédiction est basée sur la défense solide de ${prediction.score.split('-')[1] < prediction.score.split('-')[0] ? match.home_team : match.away_team} et la faible attaque de ${prediction.score.split('-')[1] > prediction.score.split('-')[0] ? match.home_team : match.away_team}.`;
    },
  ];
  
  export const completeMatchStudyVersions = [
    // Version 1
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : L'étude complète du match met en lumière les forces et faiblesses des deux équipes. ${match.home_team}, classé ${match.home_position}ème, a une moyenne de ${match.home_goals} buts marqués et ${match.home_conceded} buts encaissés par match. En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match). La probabilité BTTS est de ${prediction.bttsProb}%, et le score prédit est ${prediction.score}.`;
    },
  
    // Version 2
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : ${match.home_team} et ${match.away_team} présentent des dynamiques opposées. ${match.home_team} a une défense solide mais une attaque faible (${match.home_goals} buts par match), tandis que ${match.away_team} montre une attaque plus efficace (${match.away_goals} buts par match). Le score prédit est ${prediction.score}, avec une probabilité BTTS de ${prediction.bttsProb}%.`;
    },
  
    // Version 3
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : Le match oppose ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), à ${match.away_team}, plus performant (${match.away_form}% de victoires). Le score prédit est ${prediction.score}, et la probabilité BTTS est de ${prediction.bttsProb}%.`;
    },
  
    // Version 4
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : ${match.home_team}, classé ${match.home_position}ème, affiche une forme récente en demi-teinte (${match.home_form}% de victoires). En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match). Le score prédit est ${prediction.score}, avec une probabilité BTTS de ${prediction.bttsProb}%.`;
    },
  
    // Version 5
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : L'étude complète du match révèle que ${match.home_team} a une défense solide mais une attaque faible (${match.home_goals} buts par match). En face, ${match.away_team} montre une attaque plus efficace (${match.away_goals} buts par match). Le score prédit est ${prediction.score}, et la probabilité BTTS est de ${prediction.bttsProb}%.`;
    },
  
    // Version 6
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), affronte ${match.away_team}, plus performant (${match.away_form}% de victoires). Le score prédit est ${prediction.score}, avec une probabilité BTTS de ${prediction.bttsProb}%.`;
    },
  
    // Version 7
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : Le match oppose deux équipes aux styles de jeu distincts. ${match.home_team} privilégie un jeu défensif, tandis que ${match.away_team} mise sur une attaque rapide. Le score prédit est ${prediction.score}, et la probabilité BTTS est de ${prediction.bttsProb}%.`;
    },
  
    // Version 8
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : ${match.home_team}, classé ${match.home_position}ème, affiche une forme récente en demi-teinte (${match.home_form}% de victoires). En face, ${match.away_team}, mieux classé (${match.away_position}ème), montre une attaque plus efficace (${match.away_goals} buts par match). Le score prédit est ${prediction.score}, avec une probabilité BTTS de ${prediction.bttsProb}%.`;
    },
  
    // Version 9
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : L'étude complète du match met en lumière les forces et faiblesses des deux équipes. ${match.home_team} a une défense solide mais une attaque faible (${match.home_goals} buts par match), tandis que ${match.away_team} montre une attaque plus efficace (${match.away_goals} buts par match). Le score prédit est ${prediction.score}, et la probabilité BTTS est de ${prediction.bttsProb}%.`;
    },
  
    // Version 10
    (match) => {
      const prediction = predictScore(match);
      return `Étude Complète du Match : Le match oppose ${match.home_team}, en difficulté cette saison (${match.home_form}% de victoires), à ${match.away_team}, plus performant (${match.away_form}% de victoires). Le score prédit est ${prediction.score}, avec une probabilité BTTS de ${prediction.bttsProb}%.`;
    },
  ];