import React from "react";
import { Card } from "react-bootstrap";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Trophy, Shield } from "lucide-react";

const SpiralChart = ({ homeTeam, awayTeam, homePoints, awayPoints, homePosition, awayPosition }) => {
  // Données pour le graphique en camembert
  const teamFormData = [
    { name: homeTeam, value: homePoints, color: "#8884d8" }, // Équipe à domicile
    { name: awayTeam, value: awayPoints, color: "#82ca9d" }, // Équipe adverse
  ];

  return (
    <Card className="shadow-sm mt-4">
      <Card.Body>
        <h4 className="mb-3">Comparaison des points</h4>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie
              data={teamFormData}
              cx="50%"
              cy="50%"
              innerRadius={60} // Rayon intérieur pour un effet "donut"
              outerRadius={80} // Rayon extérieur
              fill="#8884d8"
              dataKey="value"
              label // Affiche les étiquettes sur les portions
            >
              {teamFormData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} /> // Couleurs personnalisées
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>

        {/* Affichage des positions */}
        <div className="mt-3 text-center">
          <p>
            <Shield size={16} className="me-2" />
            <strong>{homeTeam}</strong> - {homePosition}ème
          </p>
          <p>
            <Shield size={16} className="me-2" />
            <strong>{awayTeam}</strong> - {awayPosition}ème
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SpiralChart;