import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Download } from "lucide-react";
import { jsPDF } from "jspdf";

const TermsAndConditions = () => {
  const handleDownload = () => {
    const doc = new jsPDF();

    // Configuration des marges et dimensions
    const margin = 20;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const maxWidth = pageWidth - 2 * margin;

    // Chargement du logo
    const logo = new Image();
    logo.src = "/matchsynce.png";

    logo.onload = () => {
      // Ajouter le logo
      const logoWidth = 50;
      const logoHeight = 15;
      const xPos = (pageWidth - logoWidth) / 2;
      const yPos = 20;

      doc.addImage(logo, "PNG", xPos, yPos, logoWidth, logoHeight);

      // Ajouter le titre principal
      let currentY = yPos + logoHeight + 10;
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      // doc.text("MatchSynce", pageWidth / 2, currentY, { align: "center" });
      currentY += 10;
      doc.text("Termes et Conditions d'Utilisation", pageWidth / 2, currentY, {
        align: "center",
      });
      currentY += 20;

      // Récupérer le contenu HTML
      const content = document.getElementById("terms-content");

      // Fonction pour ajouter du texte avec gestion des sauts de page
      const addText = (
        text,
        fontSize = 12,
        isBold = false,
        lineSpacing = 7,
        extraSpacing = 0
      ) => {
        doc.setFontSize(fontSize);
        doc.setFont("helvetica", isBold ? "bold" : "normal");
        const lines = doc.splitTextToSize(text, maxWidth);

        lines.forEach((line) => {
          if (currentY + lineSpacing > pageHeight - margin) {
            doc.addPage();
            currentY = margin;
          }
          doc.text(line, margin, currentY);
          currentY += lineSpacing;
        });
        currentY += extraSpacing; // Ajouter un espacement supplémentaire
      };

      // Parcourir chaque section
      const sections = content.querySelectorAll("section");
      sections.forEach((section) => {
        if (currentY + 20 > pageHeight - margin) {
          doc.addPage();
          currentY = margin;
        }

        // Ajouter le titre de la section (h2)
        const sectionTitle = section.querySelector("h2");
        if (sectionTitle) {
          addText(sectionTitle.innerText, 16, true, 7, 10); // Espacement supplémentaire après le titre
        }

        // Ajouter les sous-sections (h3) et leur contenu
        const subTitles = section.querySelectorAll("h3");
        subTitles.forEach((subTitle) => {
          if (currentY + 15 > pageHeight - margin) {
            doc.addPage();
            currentY = margin;
          }
          addText(subTitle.innerText, 14, true, 7, 5);

          // Ajouter le contenu lié (paragraphes et listes) UNE SEULE FOIS
          let nextElement = subTitle.nextElementSibling;
          while (
            nextElement &&
            nextElement.tagName !== "H3" &&
            nextElement.tagName !== "H2"
          ) {
            if (nextElement.tagName === "P") {
              addText(nextElement.innerText);
            } else if (nextElement.tagName === "UL") {
              nextElement.querySelectorAll("li").forEach((item) => {
                addText(`• ${item.innerText}`);
              });
            }
            nextElement = nextElement.nextElementSibling;
          }
          currentY += 5; // Espacement après chaque sous-section
        });

        // Ajouter les paragraphes ou listes directement dans la section UNE SEULE FOIS
        let nextElement = sectionTitle.nextElementSibling;
        while (nextElement && nextElement.tagName !== "H2") {
          if (!subTitles.length) {
            // Ajouter uniquement si aucune sous-section n'existe
            if (nextElement.tagName === "P") {
              addText(nextElement.innerText);
            } else if (nextElement.tagName === "UL") {
              nextElement.querySelectorAll("li").forEach((item) => {
                addText(`• ${item.innerText}`);
              });
            }
          }
          nextElement = nextElement.nextElementSibling;
        }

        // Ajouter un espace supplémentaire entre les sections
        currentY += 10;
      });

      // Ajouter le pied de page
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} sur ${pageCount} - Document généré le ${new Date().toLocaleDateString(
            "fr-FR"
          )}`,
          margin,
          pageHeight - 10
        );
      }

      // Télécharger le fichier PDF
      doc.save("MatchSynce-Termes-et-Conditions.pdf");
    };
  };

  return (
    <Container className="py-5">
      <div className="text-center mb-5">
        <img
          src="/matchsynce.png"
          alt="Logo MatchSynce"
          width="190"
          height="60"
          className="mb-4"
        />
        <h1 className="text-success">Termes et Conditions d'Utilisation</h1>
        <button
          onClick={handleDownload}
          className="btn btn-outline-success mt-3"
        >
          <Download className="me-2" size={20} />
          Télécharger le document
        </button>
      </div>

      <div id="terms-content" className="terms-content">
        <section className="mb-5">
          <h2>Préambule</h2>
          <p>
            Les présentes conditions générales d'utilisation constituent un
            accord juridique entre vous, l'utilisateur, et MatchSynce,
            propriétaire et opérateur de la plateforme accessible via notre site
            web. Ces conditions régissent votre accès et votre utilisation de
            nos services, y compris l'ensemble des fonctionnalités, API, et
            contenus associés. En accédant à notre plateforme ou en utilisant
            nos services, vous reconnaissez avoir lu, compris et accepté d'être
            lié par ces conditions. Si vous n'acceptez pas ces conditions, vous
            ne devez pas utiliser nos services.
          </p>
          <p>
            MatchSynce est une plateforme sophistiquée d'analyse et de
            prédiction de matchs de football qui utilise des algorithmes avancés
            et des modèles d'apprentissage automatique pour fournir des insights
            précis et détaillés. Notre mission est de démocratiser l'accès à
            l'analyse sportive de haute qualité tout en promouvant une approche
            responsable et éthique des paris sportifs.
          </p>
          <p>
            Ces conditions générales sont conçues pour protéger à la fois les
            intérêts de nos utilisateurs et ceux de notre plateforme, tout en
            assurant une expérience équitable et transparente pour tous. Elles
            sont régulièrement mises à jour pour refléter l'évolution de nos
            services et des réglementations applicables.
          </p>
        </section>

        <section className="mb-5">
          <h2>Article 1 : Définitions</h2>
          <p>
            Dans le cadre des présentes conditions générales d'utilisation, les
            termes suivants, qu'ils soient employés au singulier ou au pluriel,
            auront la signification qui leur est donnée ci-après :
          </p>
          <ul className="mb-4">
            <li>
              <strong>"Plateforme"</strong> : désigne l'ensemble des services
              numériques fournis par MatchSynce, comprenant le site web, les
              applications mobiles, les API, les bases de données, les serveurs
              et toute autre infrastructure technique permettant l'accès et
              l'utilisation des services MatchSynce.
            </li>
            <li>
              <strong>"Utilisateur"</strong> : désigne toute personne physique
              ou morale qui accède à la Plateforme, qu'elle soit inscrite ou
              non, et qui utilise tout ou partie des services proposés. Cette
              définition englobe aussi bien les utilisateurs occasionnels que
              les abonnés premium.
            </li>
            {/* <li>
              <strong>"Compte"</strong> : désigne l'espace personnel sécurisé créé par l'Utilisateur sur la Plateforme, accessible au moyen de ses identifiants de connexion, et permettant l'accès aux fonctionnalités complètes du service.
            </li> */}
            <li>
              <strong>"Contenu"</strong> : désigne l'ensemble des éléments mis à
              disposition sur la Plateforme, incluant mais non limité aux
              textes, images, logos, marques, analyses, statistiques,
              prédictions, graphiques, vidéos, algorithmes, et tout autre
              matériel protégé par les droits de propriété intellectuelle.
            </li>
            <li>
              <strong>"Prédictions"</strong> : désigne les analyses
              prospectives, pronostics et recommandations générés par nos
              algorithmes à partir de l'analyse des données historiques, des
              statistiques de match, et d'autres facteurs pertinents.
            </li>
            <li>
              <strong>"Services"</strong> : désigne l'ensemble des
              fonctionnalités, outils et prestations proposés par la Plateforme,
              qu'ils soient gratuits ou payants.
            </li>
            <li>
              <strong>"Données Personnelles"</strong> : désigne toute
              information se rapportant à une personne physique identifiée ou
              identifiable, telle que définie par le Règlement Général sur la
              Protection des Données (RGPD).
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 2 : Objet</h2>
          <p>
            Les présentes conditions générales ont pour objet de définir le
            cadre juridique et contractuel régissant l'utilisation de la
            Plateforme MatchSynce. Elles établissent les droits, obligations et
            responsabilités mutuelles entre MatchSynce et ses Utilisateurs,
            ainsi que les conditions dans lesquelles les Services sont fournis.
          </p>
          <p>Ces conditions couvrent notamment :</p>
          <ul className="mb-4">
            <li>
              Les modalités d'accès et d'utilisation de la Plateforme, y compris
              les conditions techniques requises et les restrictions
              géographiques éventuelles.
            </li>
            <li>
              Les règles relatives à la gestion des utilisateurs, ainsi que les
              obligations en matière de sécurité et de confidentialité.
            </li>
            <li>
              Les conditions d'utilisation des différents services proposés,
              incluant les services gratuits et premium, ainsi que les modalités
              de paiement et de facturation le cas échéant.
            </li>
            <li>
              Les droits et restrictions concernant l'utilisation du Contenu de
              la Plateforme, notamment en matière de propriété intellectuelle.
            </li>
            <li>
              Les limitations de responsabilité et les garanties accordées par
              MatchSynce, ainsi que les obligations des Utilisateurs.
            </li>
            <li>
              Les modalités de traitement des données personnelles et les
              mesures prises pour assurer leur protection.
            </li>
          </ul>
          <p>
            Ces conditions constituent l'intégralité de l'accord entre
            MatchSynce et les Utilisateurs concernant l'utilisation de la
            Plateforme, et remplacent tout accord antérieur relatif au même
            objet.
          </p>
        </section>

        {/* <section className="mb-5">
          <h2>Article 3 : Création et Gestion du Compte Utilisateur</h2>
          <h3>3.1 Processus d'inscription</h3>
          <p>
            La création d'un compte sur MatchSynce nécessite plusieurs étapes destinées à garantir la sécurité et la fiabilité de notre plateforme. Le processus d'inscription comprend :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Informations obligatoires :</strong>
              <ul>
                <li>Nom complet</li>
                <li>Adresse email valide</li>
                <li>Numéro de téléphone</li>
                <li>Date de naissance (pour vérification de l'âge)</li>
                <li>Pays de résidence</li>
                <li>Mot de passe sécurisé (minimum 8 caractères, incluant majuscules, minuscules, chiffres et caractères spéciaux)</li>
              </ul>
            </li>
          </ul>

          <h3>3.2 Vérification et Validation</h3>
          <p>
            Pour assurer la sécurité de notre communauté et respecter les réglementations en vigueur, nous mettons en place un processus de vérification en plusieurs étapes :
          </p>
          <ol className="mb-4">
            <li>
              <strong>Vérification de l'email :</strong> Un lien de confirmation sera envoyé à l'adresse email fournie. Ce lien doit être cliqué dans les 24 heures pour valider le compte.
            </li>
            <li>
              <strong>Vérification de l'âge :</strong> Pour confirmer que vous avez plus de 18 ans, nous pouvons demander une copie d'une pièce d'identité officielle.
            </li>
            <li>
              <strong>Vérification du numéro de téléphone :</strong> Un code SMS sera envoyé pour confirmer votre numéro.
            </li>
          </ol>

          <h3>3.3 Sécurité du Compte</h3>
          <p>
            La sécurité de votre compte est primordiale. Nous mettons en place les mesures suivantes :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Authentification à deux facteurs (2FA) :</strong> Activée par défaut pour toutes les opérations sensibles (changement de mot de passe, transactions financières).
            </li>
            <li>
              <strong>Historique des connexions :</strong> Accès à l'historique complet des connexions à votre compte.
            </li>
            <li>
              <strong>Notifications de sécurité :</strong> Alertes en cas de connexion depuis un nouvel appareil ou une nouvelle localisation.
            </li>
          </ul>

          <h3>3.4 Gestion des Paramètres</h3>
          <p>
            Votre compte dispose d'un panel de configuration complet permettant de :
          </p>
          <ul className="mb-4">
            <li>Modifier vos informations personnelles</li>
            <li>Gérer vos préférences de notification</li>
            <li>Configurer les options de confidentialité</li>
            <li>Personnaliser votre expérience utilisateur</li>
            <li>Gérer vos abonnements et moyens de paiement</li>
          </ul>

          <h3>3.5 Niveaux de Compte</h3>
          <p>
            MatchSynce propose différents niveaux d'accès :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Compte Gratuit :</strong>
              <ul>
                <li>Accès aux statistiques de base</li>
                <li>Prédictions limitées</li>
                <li>Contenu éducatif général</li>
              </ul>
            </li>
            <li>
              <strong>Compte Premium :</strong>
              <ul>
                <li>Accès complet aux analyses avancées</li>
                <li>Prédictions illimitées</li>
                <li>Alertes personnalisées</li>
                <li>Support prioritaire</li>
                <li>Outils d'analyse exclusifs</li>
              </ul>
            </li>
          </ul>
        </section> */}

        <section className="mb-5">
          <h2>Article 3 : Protection des Données Personnelles</h2>
          <h3>3.1 Collecte et Traitement des Données</h3>
          <p>
            MatchSynce accorde une importance primordiale à la protection de vos
            données personnelles. Notre politique de collecte et de traitement
            des données est conforme au Règlement Général sur la Protection des
            Données (RGPD) et aux autres réglementations applicables en matière
            de protection de la vie privée. Dans ce cadre, nous nous engageons à
            :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Minimisation des données :</strong> Nous ne collectons que
              les données strictement nécessaires à la fourniture de nos
              services, notamment :
              <ul>
                <li>Informations d'identification (nom, prénom, email)</li>
                {/* <li>Coordonnées de contact (email, numéro de téléphone)</li>
                <li>Données de connexion et d'utilisation de la plateforme</li> */}
                {/* <li>Préférences utilisateur et historique des interactions</li> */}
              </ul>
            </li>
            <li>
              <strong>Transparence :</strong> Nous vous informons clairement de
              l'utilisation faite de vos données à travers notre politique de
              confidentialité détaillée.
            </li>
            <li>
              <strong>Sécurisation :</strong> Mise en place de mesures
              techniques et organisationnelles appropriées pour protéger vos
              données contre tout accès non autorisé.
            </li>
          </ul>

          <h3>3.2 Droits des Utilisateurs</h3>
          <p>
            Conformément à la législation en vigueur, vous disposez des droits
            suivants concernant vos données personnelles :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Droit d'accès :</strong> Vous pouvez obtenir une copie de
              l'ensemble des données vous concernant que nous détenons.
            </li>
            {/* <li>
              <strong>Droit de rectification :</strong> Vous pouvez demander la correction de toute information inexacte vous concernant.
            </li> */}
            <li>
              <strong>Droit à l'effacement :</strong> Vous pouvez demander la
              suppression de vos données dans les conditions prévues par la loi.
            </li>
            {/* <li>
              <strong>Droit à la portabilité :</strong> Vous pouvez récupérer vos données dans un format structuré pour les transmettre à un autre service.
            </li> */}
            <li>
              <strong>Droit d'opposition :</strong> Vous pouvez vous opposer au
              traitement de vos données pour des motifs légitimes.
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 4 : Services de Prédiction et Analyse</h2>
          <h3>4.1 Nature des Services</h3>
          <p>
            MatchSynce propose des services sophistiqués d'analyse et de
            prédiction des matchs de football, reposant sur des technologies
            avancées et d'analyse de données. Ces services comprennent :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Analyse statistique approfondie :</strong>
              <ul>
                <li>Historique complet des performances des équipes</li>
                <li>Analyse des confrontations directes</li>
                <li>Études des tendances de jeu</li>
                <li>Évaluation des performances individuelles des joueurs</li>
              </ul>
            </li>
            <li>
              <strong>Prédictions basées sur les algorithmes :</strong>
              <ul>
                <li>Modèles probabilistes avancés</li>
                <li>Analyse des facteurs contextuels</li>
                <li>Évaluation des conditions de match</li>
                <li>Prise en compte des facteurs externes</li>
              </ul>
            </li>
          </ul>

          <h3>4.2 Limitation de Responsabilité</h3>
          <p>
            Malgré la sophistication de nos algorithmes et la précision de nos
            analyses, nous tenons à souligner que :
          </p>
          <ul className="mb-4">
            <li>
              Les prédictions sont fournies à titre informatif uniquement et ne
              constituent en aucun cas des conseils d'investissement ou des
              garanties de résultat.
            </li>
            <li>
              Le sport comporte par nature une part d'imprévisibilité que même
              les analyses les plus avancées ne peuvent totalement anticiper.
            </li>
            <li>
              Les utilisateurs sont seuls responsables des décisions prises sur
              la base de nos analyses.
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 5 : Responsabilité et Obligations</h2>
          <h3>5.1 Obligations de MatchSynce</h3>
          <p>En tant que fournisseur de service, MatchSynce s'engage à :</p>
          <ul className="mb-4">
            <li>
              <strong>Qualité de service :</strong>
              <ul>
                <li>Maintenir une disponibilité optimale de la plateforme</li>
                <li>
                  Assurer la qualité et la fiabilité des analyses fournies
                </li>
                <li>
                  Mettre à jour régulièrement les algorithmes et les données
                </li>
                <li>Fournir un support utilisateur réactif et compétent</li>
              </ul>
            </li>
            <li>
              <strong>Sécurité :</strong>
              <ul>
                <li>Protection des données utilisateurs</li>
                <li>Sécurisation des transactions</li>
                <li>Surveillance continue des menaces potentielles</li>
                <li>Mises à jour régulières des protocoles de sécurité</li>
              </ul>
            </li>
          </ul>

          <h3>5.2 Obligations des Utilisateurs</h3>
          <p>En utilisant notre plateforme, les utilisateurs s'engagent à :</p>
          <ul className="mb-4">
            <li>
              <strong>Usage responsable :</strong>
              <ul>
                <li>
                  Respecter les limites d'âge et les restrictions géographiques
                </li>
                <li>
                  Ne pas utiliser de systèmes automatisés pour accéder au
                  service
                </li>
                <li>Ne pas tenter de contourner les mesures de sécurité</li>
                <li>Respecter les droits de propriété intellectuelle</li>
              </ul>
            </li>
            <li>
              <strong>Véracité des informations :</strong>
              <ul>
                {/* <li>Fournir des informations exactes lors de l'inscription</li> */}
                <li>Maintenir à jour les informations du compte</li>
                <li>Signaler toute utilisation frauduleuse suspectée</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 6 : Propriété Intellectuelle</h2>
          <h3>6.1 Droits de MatchSynce</h3>
          <p>
            L'ensemble des éléments constituant la plateforme MatchSynce est
            protégé par les droits de propriété intellectuelle, notamment :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Éléments protégés :</strong>
              <ul>
                <li>Marques, logos et signes distinctifs</li>
                <li>Algorithmes et codes sources</li>
                <li>Bases de données et contenus</li>
                <li>Interfaces utilisateur et design</li>
                <li>Documents techniques et commerciaux</li>
              </ul>
            </li>
            <li>
              <strong>Restrictions d'utilisation :</strong>
              <ul>
                <li>Interdiction de reproduction non autorisée</li>
                <li>Interdiction de modification ou d'adaptation</li>
                <li>Interdiction de distribution ou de revente</li>
                <li>Interdiction d'extraction systématique des données</li>
              </ul>
            </li>
          </ul>

          <h3>6.2 Utilisation Autorisée</h3>
          <p>Les utilisateurs sont autorisés à :</p>
          <ul className="mb-4">
            <li>Consulter les informations à des fins personnelles</li>
            <li>Télécharger les rapports générés pour leur compte</li>
            <li>Partager les analyses dans un cadre strictement privé</li>
            <li>
              Utiliser les outils mis à disposition selon les conditions
              définies
            </li>
          </ul>
        </section>

        {/* <section className="mb-5">
          <h2>Article 8 : Conditions Financières et Abonnements</h2>
          <h3>8.1 Structure des Abonnements</h3>
          <p>
            MatchSynce propose différentes formules d'abonnement pour répondre aux besoins variés de nos utilisateurs. Chaque formule est conçue pour offrir un accès optimal à nos services :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Compte Gratuit (Basic) :</strong>
              <ul>
                <li>Accès aux statistiques de base des matchs</li>
                <li>Prédictions limitées (3 par semaine)</li>
                <li>Consultation des analyses historiques basiques</li>
                <li>Accès au forum communautaire</li>
                <li>Notifications des matchs importants</li>
              </ul>
            </li>
            <li>
              <strong>Compte Premium :</strong>
              <ul>
                <li>Accès illimité à toutes les analyses statistiques</li>
                <li>Prédictions illimitées avec indices de confiance détaillés</li>
                <li>Analyses approfondies des tendances</li>
                <li>Alertes personnalisées en temps réel</li>
                <li>Support prioritaire 24/7</li>
                <li>Accès aux outils d'analyse avancés</li>
                <li>Rapports hebdomadaires personnalisés</li>
                <li>Historique complet des performances</li>
              </ul>
            </li>
            <li>
              <strong>Compte Professionnel :</strong>
              <ul>
                <li>Tous les avantages du compte Premium</li>
                <li>API d'accès aux données</li>
                <li>Analyses personnalisées sur demande</li>
                <li>Consultation avec nos experts en analyse</li>
                <li>Formation sur l'utilisation avancée des outils</li>
                <li>Rapports sur mesure</li>
                <li>Support dédié</li>
              </ul>
            </li>
          </ul>

          <h3>8.2 Modalités de Paiement</h3>
          <p>
            Les paiements sur MatchSynce sont traités de manière sécurisée selon les modalités suivantes :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Moyens de paiement acceptés :</strong>
              <ul>
                <li>Cartes bancaires (Visa, Mastercard, American Express)</li>
                <li>PayPal</li>
                <li>Virement bancaire (pour les abonnements annuels)</li>
                <li>Mobile Money (selon les régions)</li>
                <li>Autres moyens de paiement locaux selon disponibilité</li>
              </ul>
            </li>
            <li>
              <strong>Périodicité des paiements :</strong>
              <ul>
                <li>Abonnements mensuels : prélèvement automatique mensuel</li>
                <li>Abonnements trimestriels : facturation tous les trois mois</li>
                <li>Abonnements annuels : paiement unique avec réduction</li>
              </ul>
            </li>
            <li>
              <strong>Sécurité des transactions :</strong>
              <ul>
                <li>Cryptage SSL 256 bits pour toutes les transactions</li>
                <li>Conformité aux normes PCI-DSS</li>
                <li>Vérification en deux étapes pour les transactions importantes</li>
                <li>Monitoring continu des transactions suspectes</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 9 : Politique de Résiliation et Remboursement</h2>
          <h3>9.1 Procédure de Résiliation</h3>
          <p>
            Les utilisateurs peuvent résilier leur abonnement à tout moment, selon les conditions suivantes :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Résiliation par l'utilisateur :</strong>
              <ul>
                <li>Via l'espace personnel dans les paramètres du compte</li>
                <li>Par email à notre service client avec confirmation d'identité</li>
                <li>Par courrier recommandé pour les comptes professionnels</li>
              </ul>
            </li>
            <li>
              <strong>Effets de la résiliation :</strong>
              <ul>
                <li>Maintien de l'accès jusqu'à la fin de la période payée</li>
                <li>Conservation des données pendant 30 jours</li>
                <li>Possibilité d'exporter ses données personnelles</li>
                <li>Arrêt des prélèvements automatiques</li>
              </ul>
            </li>
          </ul>

          <h3>9.2 Politique de Remboursement</h3>
          <p>
            Notre politique de remboursement est conçue pour être juste et transparente :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Conditions de remboursement :</strong>
              <ul>
                <li>Délai de rétractation de 14 jours pour tout nouvel abonnement</li>
                <li>Remboursement intégral en cas de problème technique majeur</li>
                <li>Remboursement au prorata en cas d'interruption de service prolongée</li>
                <li>Compensation spécifique pour les incidents de service</li>
              </ul>
            </li>
            <li>
              <strong>Exclusions :</strong>
              <ul>
                <li>Utilisation effective des services pendant la période d'essai</li>
                <li>Violation des conditions d'utilisation</li>
                <li>Résiliation après la période de rétractation</li>
                <li>Services déjà consommés</li>
              </ul>
            </li>
        </ul>
        </section>

        <section className="mb-5">
          <h2>Article 10 : Règlement des Litiges</h2>
          <h3>10.1 Processus de Résolution</h3>
          <p>
            En cas de litige, nous privilégions une approche constructive et progressive :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Première étape : Résolution amiable</strong>
              <ul>
                <li>Contact initial avec le service client</li>
                <li>Analyse détaillée de la situation</li>
                <li>Proposition de solutions adaptées</li>
                <li>Médiation interne si nécessaire</li>
              </ul>
            </li>
            <li>
              <strong>Deuxième étape : Médiation externe</strong>
              <ul>
                <li>Recours à un médiateur indépendant</li>
                <li>Processus de médiation structuré</li>
                <li>Recherche d'un accord équitable</li>
                <li>Partage des coûts de médiation</li>
              </ul>
            </li>
            <li>
              <strong>Dernière étape : Recours juridique</strong>
              <ul>
                <li>Tribunal compétent selon juridiction</li>
                <li>Application du droit en vigueur</li>
                <li>Procédure formelle selon les règles applicables</li>
              </ul>
            </li>
          </ul>

          <h3>10.2 Délais et Procédures</h3>
          <p>
            Chaque étape du processus de résolution est encadrée par des délais précis :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Délais de traitement :</strong>
              <ul>
                <li>Accusé de réception sous 24 heures</li>
                <li>Première réponse détaillée sous 72 heures</li>
                <li>Résolution amiable visée sous 15 jours</li>
                <li>Médiation externe dans un délai de 30 jours</li>
              </ul>
            </li>
            <li>
              <strong>Documentation requise :</strong>
              <ul>
                <li>Description détaillée du litige</li>
                <li>Preuves et documents pertinents</li>
                <li>Historique des échanges</li>
                <li>Solutions souhaitées</li>
              </ul>
            </li>
          </ul>
        </section> */}

        <section className="mb-5">
          <h2>Article 7 : Disponibilité et Maintenance</h2>
          <h3>7.1 Engagement de Service</h3>
          <p>
            MatchSynce s'engage à maintenir une disponibilité optimale de sa
            plateforme :
          </p>
          <ul className="mb-4">
            <li>
              <strong>Objectifs de disponibilité :</strong>
              <ul>
                <li>Disponibilité garantie de 99.9% sur une base mensuelle</li>
                <li>
                  Temps de réponse optimal pour les fonctionnalités critiques
                </li>
                <li>Monitoring continu des performances</li>
              </ul>
            </li>
            <li>
              <strong>Maintenance planifiée :</strong>
              <ul>
                <li>Notifications préalables des interventions</li>
                <li>Interventions programmées pendant les heures creuses</li>
                <li>Documentation des changements effectués</li>
              </ul>
            </li>
          </ul>

          <h3>7.2 Interruptions de Service</h3>
          <p>En cas d'interruption non planifiée :</p>
          <ul className="mb-4">
            <li>
              Information immédiate des utilisateurs via les canaux appropriés
            </li>
            <li>Mise en place de solutions temporaires si possible</li>
            <li>Compensation selon la durée de l'interruption</li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 8 : Support et Assistance</h2>
          <h3>8.1 Canaux de Support</h3>
          <p>MatchSynce propose plusieurs canaux d'assistance :</p>
          <ul className="mb-4">
            <li>
              <strong>Support en ligne :</strong>
              <ul>
                {/* <li>Chat en direct 24/7</li> */}
                <li>Base de connaissances complète</li>
                {/* <li>Tutoriels vidéo</li> */}
                <li>FAQ interactive</li>
              </ul>
            </li>
            {/* <li>
            <strong>Support téléphonique :</strong>
            <ul>
              <li>Ligne dédiée pour les comptes Premium</li>
              <li>Support technique spécialisé</li>
              <li>Assistance en plusieurs langues</li>
            </ul>
          </li> */}
          </ul>
        </section>

        {/* <section className="mb-5">
        <h2>Article 13 : Sécurité et Confidentialité</h2>
        <h3>13.1 Mesures de Sécurité</h3>
        <p>
          MatchSynce met en œuvre des mesures de sécurité avancées :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Protection des infrastructures :</strong>
            <ul>
              <li>Cryptage SSL/TLS pour toutes les communications</li>
              <li>Pare-feu nouvelle génération</li>
              <li>Systèmes de détection d'intrusion</li>
              <li>Sauvegarde redondante des données</li>
            </ul>
          </li>
          <li>
            <strong>Sécurité des comptes :</strong>
            <ul>
              <li>Authentification multifactorielle</li>
              <li>Verrouillage automatique après tentatives échouées</li>
              <li>Surveillance des activités suspectes</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Article 14 : Utilisation des API</h2>
        <h3>14.1 Conditions d'Accès</h3>
        <p>
          L'accès aux API de MatchSynce est soumis aux conditions suivantes :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Authentification :</strong>
            <ul>
              <li>Clés API sécurisées</li>
              <li>Tokens d'accès temporaires</li>
              <li>Limitations de requêtes</li>
            </ul>
          </li>
          <li>
            <strong>Usage autorisé :</strong>
            <ul>
              <li>Intégration dans des applications tierces autorisées</li>
              <li>Respect des quotas d'utilisation</li>
              <li>Conformité aux bonnes pratiques</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Article 15 : Programme d'Affiliation</h2>
        <h3>15.1 Conditions de Participation</h3>
        <p>
          Notre programme d'affiliation est ouvert selon les modalités suivantes :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Critères d'éligibilité :</strong>
            <ul>
              <li>Site web ou plateforme établie</li>
              <li>Contenu en accord avec nos valeurs</li>
              <li>Audience qualifiée</li>
            </ul>
          </li>
          <li>
            <strong>Rémunération :</strong>
            <ul>
              <li>Commission sur les nouveaux abonnements</li>
              <li>Bonus de performance</li>
              <li>Paiements mensuels</li>
            </ul>
          </li>
        </ul>
      </section> */}

        <section className="mb-5">
          <h2>Article 9 : Contenu Utilisateur</h2>
          <h3>9.1 Règles de Publication</h3>
          <p>Les utilisateurs peuvent publier du contenu selon ces règles :</p>
          <ul className="mb-4">
            <li>
              <strong>Contenus autorisés :</strong>
              <ul>
                <li>Analyses sportives constructives</li>
                <li>Commentaires pertinents</li>
                <li>Retours d'expérience</li>
              </ul>
            </li>
            <li>
              <strong>Modération :</strong>
              <ul>
                <li>Vérification préalable</li>
                <li>Signalement des contenus inappropriés</li>
                <li>Sanctions graduées</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* <section className="mb-5">
        <h2>Article 17 : Partenariats et Intégrations</h2>
        <h3>17.1 Types de Partenariats</h3>
        <p>
          MatchSynce développe différents types de partenariats :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Partenariats technologiques :</strong>
            <ul>
              <li>Intégration de services complémentaires</li>
              <li>Développement conjoint de fonctionnalités</li>
              <li>Partage d'expertise</li>
            </ul>
          </li>
          <li>
            <strong>Partenariats commerciaux :</strong>
            <ul>
              <li>Offres conjointes</li>
              <li>Programme de recommandation</li>
              <li>Événements communs</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Article 18 : Formation et Support</h2>
        <h3>18.1 Ressources Éducatives</h3>
        <p>
          MatchSynce propose des ressources de formation complètes :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Centre de formation :</strong>
            <ul>
              <li>Tutoriels vidéo</li>
              <li>Guides pratiques</li>
              <li>Webinaires réguliers</li>
            </ul>
          </li>
          <li>
            <strong>Accompagnement personnalisé :</strong>
            <ul>
              <li>Sessions individuelles</li>
              <li>Formations sur mesure</li>
              <li>Support dédié</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Article 19 : Conformité Légale</h2>
        <h3>19.1 Cadre Réglementaire</h3>
        <p>
          MatchSynce s'engage à respecter toutes les réglementations applicables :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Protection des données :</strong>
            <ul>
              <li>RGPD et réglementations locales</li>
              <li>Sécurité des données personnelles</li>
              <li>Transparence des traitements</li>
            </ul>
          </li>
          <li>
            <strong>Conformité sectorielle :</strong>
            <ul>
              <li>Réglementations des paris sportifs</li>
              <li>Normes de l'industrie</li>
              <li>Certifications requises</li>
            </ul>
          </li>
        </ul>
      </section> */}

        <section className="mb-5">
          <h2>Article 10 : Évolution du Service</h2>
          <h3>10.1 Développement Continu</h3>
          <p>Notre plateforme évolue constamment :</p>
          <ul className="mb-4">
            <li>
              <strong>Améliorations régulières :</strong>
              <ul>
                <li>Nouvelles fonctionnalités</li>
                <li>Optimisations des performances</li>
                <li>Mises à jour de sécurité</li>
              </ul>
            </li>
            <li>
              <strong>Retours utilisateurs :</strong>
              <ul>
                <li>Programme bêta</li>
                <li>Suggestions d'amélioration</li>
                <li>Tests utilisateurs</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 11 : Communication</h2>
          <h3>11.1 Canaux Officiels</h3>
          <p>MatchSynce communique via plusieurs canaux :</p>
          <ul className="mb-4">
            <li>
              <strong>Communications officielles :</strong>
              <ul>
                <li>Emails d'information</li>
                <li>Notifications dans l'application</li>
                {/* <li>Blog officiel</li> */}
              </ul>
            </li>
            <li>
              <strong>Réseaux sociaux :</strong>
              <ul>
                <li>Comptes vérifiés</li>
                <li>Actualités en temps réel</li>
                <li>Interactions communautaires</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 12 : Responsabilité Sociale</h2>
          <h3>12.1 Engagements</h3>
          <p>Notre engagement social comprend :</p>
          <ul className="mb-4">
            <li>
              <strong>Prévention :</strong>
              <ul>
                <li>Sensibilisation aux paris responsables</li>
                <li>Outils de contrôle personnel</li>
                <li>Ressources d'aide</li>
              </ul>
            </li>
            <li>
              <strong>Impact environnemental :</strong>
              <ul>
                {/* <li>Optimisation énergétique</li>
              <li>Compensation carbone</li> */}
                <li>Pratiques durables</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* <section className="mb-5">
        <h2>Article 23 : Confidentialité des Données</h2>
        <h3>23.1 Protection des Informations</h3>
        <p>
          Mesures spécifiques de protection :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Stockage sécurisé :</strong>
            <ul>
              <li>Chiffrement de bout en bout</li>
              <li>Isolation des données</li>
              <li>Backups réguliers</li>
            </ul>
          </li>
          <li>
            <strong>Accès contrôlé :</strong>
            <ul>
              <li>Authentification forte</li>
              <li>Logs d'accès</li>
              <li>Révision périodique</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Article 24 : Propriété des Données</h2>
        <h3>24.1 Gestion des Données</h3>
        <p>
          Politique de propriété des données :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Données utilisateur :</strong>
            <ul>
              <li>Propriété exclusive de l'utilisateur</li>
              <li>Droit de portabilité</li>
              <li>Suppression sur demande</li>
            </ul>
          </li>
          <li>
            <strong>Données agrégées :</strong>
            <ul>
              <li>Utilisation anonymisée</li>
              <li>Analyses statistiques</li>
              <li>Amélioration des services</li>
            </ul>
          </li>
        </ul>
      </section> */}

        <section className="mb-5">
          <h2>Article 13 : Conditions Techniques</h2>
          <h3>13.1 Prérequis Techniques</h3>
          <p>Configuration requise :</p>
          <ul className="mb-4">
            <li>
              <strong>Compatibilité :</strong>
              <ul>
                <li>Navigateurs supportés</li>
                <li>Versions minimales</li>
                <li>Connexion Internet requise</li>
              </ul>
            </li>
            <li>
              <strong>Performance :</strong>
              <ul>
                <li>Bande passante recommandée</li>
                <li>Espace de stockage</li>
                <li>Configuration matérielle</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>Article 14 : Continuité de Service</h2>
          <h3>14.1 Plan de Continuité</h3>
          <p>Mesures de continuité d'activité :</p>
          <ul className="mb-4">
            <li>
              <strong>Infrastructure redondante :</strong>
              <ul>
                <li>Serveurs miroirs</li>
                <li>Réplication des données</li>
                <li>Basculement automatique</li>
              </ul>
            </li>
            <li>
              <strong>Plan de reprise :</strong>
              <ul>
                <li>Procédures d'urgence</li>
                <li>Tests réguliers</li>
                <li>Communication de crise</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* <section className="mb-5">
        <h2>Article 27 : Résiliation</h2>
        <h3>27.1 Conditions de Résiliation</h3>
        <p>
          Modalités de fin de contrat :
        </p>
        <ul className="mb-4">
          <li>
            <strong>Résiliation volontaire :</strong>
            <ul>
              <li>Préavis requis</li>
              <li>Procédure de résiliation</li>
              <li>Conservation des données</li>
            </ul>
          </li>
          <li>
            <strong>Résiliation pour motif :</strong>
            <ul>
              <li>Causes justifiées</li>
              <li>Procédure accélérée</li>
              <li>Remboursement éventuel</li>
            </ul>
          </li>
        </ul>
      </section> */}

        <section className="mb-5">
          <h2>Article 15 : Contact et Support</h2>
          <p>
            Pour toute question relative aux présentes conditions ou pour tout
            support technique, vous pouvez nous contacter :
          </p>
          <ul>
            {/* <li>Par email : ahoglacharlie1@gmail.com</li> */}
            <li>Via le formulaire de contact sur notre plateforme</li>
            {/* <li>Par téléphone : +229 94630674</li> */}
          </ul>
        </section>

        <div className="text-center mt-5">
          <p className="text-muted">
            Dernière mise à jour : {new Date().toLocaleDateString("fr-FR")}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
