import React from "react";
import { Card, Col } from "react-bootstrap";
import {
  Trophy,
  Target,
  Flag,
  ShieldAlert,
  Goal,
  ArrowUpRight,
  Clock,
  CheckCircle,
  XCircle,
} from "lucide-react";

const FootballIcons = {
  goal: "⚽",
  trophy: "🏆",
  fire: "🔥",
  chart: "📊",
  stats: "📈",
  clock: "⏱️",
  flag: "🚩",
  team: "👥",
};

const MatchResultsComponent = ({ match, result }) => {
  const results = {
    score: result?.score || "0-0", // Par défaut à "0-0" si `score` est manquant
    winner: result?.winner || "Inconnu",
  };

  const isDraw = (score) => {
    const [home, away] = score.split("-").map(Number);
    return home === away;
  };

  const actualResult = isDraw(results.score) ? "Match nul" : result.winner;

  // const isCorrectPrediction = (predicted, actual, label) => {
  //   if (label === "Nombre de buts") {
  //     if (actual >= predicted) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else if (label === "Résultat du match") {
  //     console.log("predicted et actual: ", predicted, actual);

  //     const cleanAndCompareStrings = (predicted, actual) => {
  //       // Nettoie et normalise une chaîne
  //       const cleanString = (str) =>
  //         str
  //           ?.toLowerCase() // Convertir en minuscules
  //           .normalize() // Normaliser pour gérer les accents/unicodes
  //           .replace(/\s+/g, " ") // Remplacer plusieurs espaces par un seul
  //           .trim(); // Supprimer les espaces au début/fin

  //       // Appliquer le nettoyage aux deux chaînes
  //       const cleanedPredicted = cleanString(predicted);
  //       const cleanedActual = cleanString(actual);

  //       // Comparer les chaînes nettoyées
  //       return cleanedPredicted === cleanedActual;
  //     };

  //     // Comparaison avec l'égalité stricte après nettoyage
  //     if (cleanAndCompareStrings(predicted, actual)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else if(label === "Plus de 2.5 buts") {
  //     if (actual >= predicted) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else if(label === "Les deux équipes marquent"){
  //     if (actual >= predicted) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  const PredictionDisplay = ({ predicted, actual, label }) => (
    <div className="d-flex align-items-center justify-content-between mb-2 p-2 border-bottom">
      <div className="d-flex align-items-center">
        <strong className="me-2">{label}:</strong>
      </div>
      <div className="d-flex align-items-center">
        <span className="me-2 text-secondary">{predicted}</span>
        <span className="mx-2">→</span>
        <span
          className="text-primary"
        >
          {actual}
        </span>
        {/* {isCorrectPrediction(predicted, actual, label) ? (
          <CheckCircle className="text-success ms-2" size={16} />
        ) : (
          <XCircle className="text-danger ms-2" size={16} />
        )} */}
      </div>
    </div>
  );

  if (!result || !match) {
    return null;
  }

  return (
    <Col md={4} className="mb-3">
      <Card className="h-100 shadow-sm">
        <Card.Header className="bg-secondary text-white">
          <div className="text-center">
            <h5 className="mb-1">
              {match.home_team} vs {match.away_team}
            </h5>
            <div className="fs-4 fw-bold">{result.score}</div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="mb-3">
            <strong>Date:</strong> {match.date}
          </div>

          <h6 className="text-success mb-3">
            Comparaison Prédictions/Résultats
          </h6>

          {/* Résultat du match */}
          <PredictionDisplay
            predicted={match.matchResult}
            actual={result.score.split("-")[0] === result.score.split("-")[1] ? "Match nul" : result.actual_result}
            label="Résultat du match"
          />

            {/* <PredictionDisplay 
            predicted={match.matchResult}
            actual={actualResult}
            label="Double chance"
            /> */}

          {/* Nombre de buts */}
          <PredictionDisplay
            predicted={`${match.expected_goals} buts`}
            actual={`${result.actual_goals} buts`}
            label="Nombre de buts"
          />

          {/* Plus de 2.5 buts */}
          <PredictionDisplay
            predicted={match.over_2_5_prob > 50 ? "Oui" : "Non"}
            actual={result.over_2_5_actual ? "Oui" : "Non"}
            label="Plus de 2.5 buts"
          />

          {/* Les deux équipes marquent */}
          <PredictionDisplay
            predicted={match.btts_prob > 50 ? "Oui" : "Non"}
            actual={result.btts_actual ? "Oui" : "Non"}
            label="Les deux équipes marquent"
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default MatchResultsComponent;
