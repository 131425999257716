import React, { useState, useEffect } from "react";
import { Card, Nav, Row, Col, Form } from "react-bootstrap";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { motion } from "framer-motion";

const LeagueStandings = ({ league }) => {
  const [activeTab, setActiveTab] = useState("ALL");
  const [standings, setStandings] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamStats, setTeamStats] = useState(null);

  useEffect(() => {
    fetch("/league_standings.json")
      .then((response) => response.json())
      .then((data) => {
        if (data[league]) {
          setStandings(data[league]);
          if (data[league].length > 0) {
            setSelectedTeam(data[league][0].Team.name); // Sélectionner la première équipe par défaut
          }
        }
      })
      .catch((error) => console.error("Error loading standings:", error));
  }, [league]);

  useEffect(() => {
    if (selectedTeam && standings.length > 0) {
      const team = standings.find((t) => t.Team.name === selectedTeam);
      if (team) {
        setTeamStats({
          wins: team.W,
          draws: team.D,
          losses: team.L,
          goalsFor: parseInt(team.Goals.split(":")[0]), // Convertir en nombre
          goalsAgainst: parseInt(team.Goals.split(":")[1]), // Convertir en nombre
        });
      }
    }
  }, [selectedTeam, standings]);

  const getFilteredStandings = () => {
    const calculateHomeStats = (team) => {
      const totalMatches = team.P;
      const homeMatches = Math.ceil(totalMatches / 2);
      return {
        P: homeMatches,
        W: Math.ceil(team.W / 2),
        D: Math.ceil(team.D / 2),
        L: Math.ceil(team.L / 2),
        PTS: Math.ceil(team.W / 2) * 3 + Math.ceil(team.D / 2),
      };
    };

    const calculateAwayStats = (team) => {
      const totalMatches = team.P;
      const awayMatches = Math.floor(totalMatches / 2);
      return {
        P: awayMatches,
        W: Math.floor(team.W / 2),
        D: Math.floor(team.D / 2),
        L: Math.floor(team.L / 2),
        PTS: Math.floor(team.W / 2) * 3 + Math.floor(team.D / 2),
      };
    };

    let filteredData = [...standings];

    if (activeTab === "HOME") {
      filteredData = filteredData.map((team) => ({
        ...team,
        ...calculateHomeStats(team),
      }));
    } else if (activeTab === "AWAY") {
      filteredData = filteredData.map((team) => ({
        ...team,
        ...calculateAwayStats(team),
      }));
    }

    return filteredData.sort((a, b) => b.PTS - a.PTS || b.DIFF - a.DIFF);
  };

  const getPositionColor = (position) => {
    switch (true) {
      case position <= 4:
        return "success"; // Champions League
      case position === 5:
        return "primary"; // Europa League
      case position === 6:
        return "info"; // Conference League
      case position >= standings.length - 2:
        return "danger"; // Relegation
      default:
        return "secondary";
    }
  };

  const getFormBadge = (result) => {
    const colors = {
      W: "bg-success text-white",
      L: "bg-danger text-white",
      D: "bg-secondary text-white",
    };
    return colors[result.trim()] || "bg-gray-300";
  };

  const teamFormData = teamStats
    ? [
        { name: "Victoires", value: teamStats.wins, color: "#28a745" },
        { name: "Nuls", value: teamStats.draws, color: "#ffc107" },
        { name: "Défaites", value: teamStats.losses, color: "#dc3545" },
      ]
    : [];

  const teamGoalsData = teamStats
    ? [
        { name: "Buts marqués", value: teamStats.goalsFor, color: "#007bff" },
        {
          name: "Buts encaissés",
          value: teamStats.goalsAgainst,
          color: "#6c757d",
        },
      ]
    : [];

    const calculateLeagueStats = () => {
      // Vérification explicite si standings est null ou vide
      if (!standings || standings.length === 0) {
        return {
          totalGoalsFor: 0,
          totalGoalsAgainst: 0,
          totalWins: 0,
          totalDraws: 0,
          totalLosses: 0,
          averageGoalsPerMatch: 0,
          totalMatches: 0
        };
      }
    
      const stats = {
        totalGoalsFor: 0,
        totalGoalsAgainst: 0,
        totalWins: 0,
        totalDraws: 0,
        totalLosses: 0,
        averageGoalsPerMatch: 0,
        totalMatches: 0
      };
    
      standings.forEach((team) => {
        // Protection contre les données potentiellement invalides
        const goals = team.Goals ? team.Goals.split(":").map(Number) : [0, 0];
        const goalsFor = goals[0] || 0;
        const goalsAgainst = goals[1] || 0;
    
        stats.totalGoalsFor += goalsFor;
        stats.totalGoalsAgainst += goalsAgainst;
    
        // Protection contre des valeurs potentiellement non numériques
        stats.totalWins += team.W || 0;
        stats.totalDraws += team.D || 0;
        stats.totalLosses += team.L || 0;
        stats.totalMatches += team.P || 0;
      });
    
      // Calcul sécurisé du ratio de buts moyen par match
      stats.averageGoalsPerMatch = stats.totalMatches > 0 
        ? stats.totalGoalsFor / stats.totalMatches 
        : 0;
    
      return stats;
    };

  const leagueStats = calculateLeagueStats();

  const leagueGoalsData = [
    {
      name: "Buts marqués (total)",
      value: leagueStats.totalGoalsFor,
      color: "#007bff",
    },
    {
      name: "Buts encaissés (total)",
      value: leagueStats.totalGoalsAgainst,
      color: "#6c757d",
    },
  ];

  const leagueResultsData = [
    {
      name: "Victoires (total)",
      value: leagueStats.totalWins,
      color: "#28a745",
    },
    {
      name: "Matchs nuls (total)",
      value: leagueStats.totalDraws,
      color: "#ffc107",
    },
    {
      name: "Défaites (total)",
      value: leagueStats.totalLosses,
      color: "#dc3545",
    },
  ];

  return (
    <Row>
      <Col md={6}>
        <Card>
          <Card.Body>
            <Nav
              variant="pills"
              className="mb-3"
              activeKey={activeTab}
              onSelect={setActiveTab}
            >
              <Nav.Item>
                <Nav.Link eventKey="ALL" className="mx-1">
                  TOUT
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="HOME" className="mx-1">
                  A DOMICILE
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="AWAY" className="mx-1">
                  A L'EXTÉRIEUR
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Équipe</th>
                    <th className="text-center">P</th>
                    <th className="text-center">W</th>
                    <th className="text-center">D</th>
                    <th className="text-center">L</th>
                    <th className="text-center">DIFF</th>
                    <th className="text-center">Buts</th>
                    <th className="text-center">PTS</th>
                  </tr>
                </thead>
                <tbody>
                  {getFilteredStandings().map((team, index) => (
                    <tr key={team.Team.name}>
                      <td className="align-middle">
                        <div className="d-flex align-items-center">
                          <span
                            className={`d-inline-block rounded-circle me-2 bg-${getPositionColor(
                              index + 1
                            )}`}
                            style={{ width: "10px", height: "10px" }}
                          ></span>
                          {index + 1}
                        </div>
                      </td>
                      <td className="align-middle">{team.Team.name}</td>
                      <td className="text-center align-middle">{team.P}</td>
                      <td className="text-center text-success fw-bold align-middle">
                        {team.W}
                      </td>
                      <td className="text-center text-warning fw-bold align-middle">
                        {team.D}
                      </td>
                      <td className="text-center text-danger  fw-bold align-middle">
                        {team.L}
                      </td>
                      <td className="text-center align-middle">{team.DIFF}</td>
                      <td className="text-center align-middle">{team.Goals}</td>
                      <td className="text-center text-primary align-middle fw-bold">
                        {team.PTS}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Col md={6}>
        <Card>
          <Card.Body>
            <h4 className="text-center">Statistiques de l'équipe</h4>
            <Form.Group className="mb-3">
              <Form.Label className="text-center">Sélectionner une équipe</Form.Label>
              <Form.Control
                as="select"
                value={selectedTeam || ""}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                {standings.map((team, index) => (
                  <option key={index} value={team.Team.name}>
                    {team.Team.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {teamStats && (
              <Row>
                <Col md={6}>
                  <h5 className="text-lg-center">Forme de l'équipe</h5>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <PieChart width={350} height={300}>
                      <Pie
                        data={teamFormData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label
                      >
                        {teamFormData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </motion.div>
                </Col>

                <Col md={6}>
                  <h5 className="text-lg-center mt-lg-0 mt-4">Buts marqués vs encaissés</h5>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <PieChart width={330} height={300}>
                      <Pie
                        data={teamGoalsData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label
                      >
                        {teamGoalsData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </motion.div>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Body>
            <h4 className="text-center">Statistiques du championnat</h4>
            <Row>
              <Col md={6}>
                <h5 className="text-lg-center mt-lg-0 mt-4">Buts marqués vs encaissés (total)</h5>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <PieChart width={330} height={300}>
                    <Pie
                      data={leagueGoalsData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    >
                      {leagueGoalsData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </motion.div>
              </Col>

              <Col md={6}>
                <h5 className="text-lg-center mt-lg-0 mt-4">Résultats (total)</h5>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                >
                  <PieChart width={320} height={300}>
                    <Pie
                      data={leagueResultsData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    >
                      {leagueResultsData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </motion.div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LeagueStandings;
