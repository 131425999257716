import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { ArrowLeft, Shield, Calendar, Trophy, AlertCircle } from "lucide-react";

const SafeDuJour = ({ matches, onBack }) => {
  const today = new Date().toISOString().split("T")[0];
  const todayMatches = matches.filter((match) => {
    // Extrait la partie date, quelle que soit le format (avec T ou avec espace)
    const matchDate = match.date.includes("T") 
      ? match.date.split("T")[0] 
      : match.date.split(" ")[0];
    
    return matchDate === today;
  });

  const getTopMatches = (matches, count = 5) => {
    return matches
      .sort((a, b) => b.win_probability - a.win_probability)
      .slice(0, count);
  };

  const formatLeagueName = (league) => {
    switch (league) {
      case "bundesliga":
        return "🇩🇪 Bundesliga";
      case "liga":
        return "🇪🇸 Liga";
      case "premier_league":
        return "🏴󠁧󠁢󠁥󠁮󠁧󠁿 Premier League";
      case "serie_a":
        return "🇮🇹 Série A";
      case "ligue_1":
        return "🇫🇷 Ligue 1";
      case "champions_league":
        return "🇪🇺 Champions League";
      default:
        return league;
    }
  };

  const getMostProbableEvent = (match) => {
    const events = [
      {
        type: "1X2",
        probability: match.win_probability,
        description: `Victoire de ${match.home_team}`,
      },
      {
        type: "Double Chance",
        probability: match.win_probability > 50 ? 90 : 50, // Exemple simplifié
        description: `Double chance pour ${match.home_team} ou match nul`,
      },
      {
        type: "Over/Under 2.5",
        probability: match.over_2_5_prob,
        description: `Over 2.5 buts`,
      },
      {
        type: "BTTS",
        probability: match.btts_prob,
        description: `Les deux équipes marquent`,
      },
    ];

    return events.sort((a, b) => b.probability - a.probability)[0];
  };

  const topMatches = getTopMatches(todayMatches, 3);

  return (
    <div style={{
      maxHeight: "calc(100vh - 190px)",
      minHeight: "300px",
      overflowY: "auto",
    }}>
      <Container className="mt-lg-5 mt-2 d-flex flex-column align-items-center">
        <div className="d-lg-flex justify-content-between align-items-center mb-4 w-100">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={onBack}
            className="d-flex align-items-center"
          >
            <ArrowLeft size={18} className="me-2" />
            Retour
          </Button>
          <h2 className="text-danger fw-bold mb-0 d-flex align-items-center mt-lg-0 mt-3 text-lg-start text-center">
            <Shield size={24} className="me-2" />
            Safe du Jour
          </h2>
          <div className="invisible">
            <Button variant="outline-light" size="sm">
              Invisible
            </Button>
          </div>
        </div>

        {topMatches.length > 0 ? (
          <Row className="w-100">
            {topMatches.map((match, index) => {
              const event = getMostProbableEvent(match);
              return (
                <Col key={index} md={4} className="mb-4">
                  <Card className="shadow-sm border-0 h-100">
                    <Card.Body className="d-flex flex-column align-items-center text-center">
                      <Trophy size={32} className="mb-3 text-warning" />
                      <h5 className="text-primary">
                        {match.home_team} vs {match.away_team}
                      </h5>
                      <p className="text-muted">
                        <strong>Ligue :</strong> {formatLeagueName(match.league)}
                      </p>
                      <p className="text-muted">
                        <strong>Date :</strong> {match.date.split("T")[0]}
                      </p>
                      <p className="text-muted">
                        <strong>Événement le plus probable :</strong>{" "}
                        {event.type}
                      </p>
                      <p className="text-muted">
                        <strong>Probabilité :</strong> {event.probability}%
                      </p>
                      <p className="text-muted">
                        <strong>Description :</strong> {event.description}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center text-center mt-5">
            <AlertCircle size={48} className="text-muted mb-3" />
            <h4 className="text-muted">Aucun match disponible aujourd'hui</h4>
            <p className="text-muted">
              Revenez demain pour de nouvelles prédictions !
            </p>
          </div>
        )}
      </Container>
    </div>
  );
};

export default SafeDuJour;
