import { AlertTriangle } from 'lucide-react';

const ResultsPending = () => {
  return (
    <div className="text-center py-5">
      <div className="bg-light rounded p-5 mx-auto shadow" style={{ maxWidth: '600px' }}>
        <div className="mb-4">
          <AlertTriangle className="text-primary" size={64} />
        </div>
        
        <h3 className="fw-bold text-primary mb-4">
          Résultats en attente
        </h3>
        
        <p className="text-secondary mb-4">
          Les résultats des matchs ne sont pas encore disponibles. Ils seront affichés ici dès que les rencontres seront terminées.
        </p>
        
        <div className="d-flex justify-content-center gap-3 mb-4">
          {[0, 1, 2].map((i) => (
            <div 
              key={i} 
              className="d-inline-block"
              style={{
                animation: `bounce 1s ease-in-out ${i * 0.2}s infinite`
              }}
            >
              ⚽
            </div>
          ))}
        </div>
        
        <p className="text-muted small">
          Revenez plus tard pour consulter les analyses détaillées
        </p>
      </div>

      <style>
        {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-20px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default ResultsPending;