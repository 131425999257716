import { ArrowLeft, Satellite, Shield } from "lucide-react";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

const PlayerStatistics = ({ matches, onBack }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [randomQuestions, setRandomQuestions] = useState([]);
  const [selectedSubQuestions, setSelectedSubQuestions] = useState([]);
  const [isAnswering, setIsAnswering] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [data, setData] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  // Chargement des données
  useEffect(() => {
    fetch("/stats_football_categorisees.json")
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        // Initialiser l'application après le chargement des données
        initializeApp(jsonData);
      })
      .catch((error) =>
        console.error("Erreur lors du chargement des données :", error)
      );
  }, []);

  // Initialisation de l'application après le chargement des données
  const initializeApp = (jsonData) => {
    // Générer les questions aléatoires
    generateRandomQuestions(jsonData);

    // Message d'accueil
    setChatMessages([
      {
        type: "answer",
        content:
          "Bienvenue dans les statistiques des joueurs ! Posez-moi vos questions sur les performances des joueurs de tous les championnats.",
      },
    ]);

    setAnimationFinished(true);
  };

  const generateRandomQuestions = (data) => {
    if (!data) return;

    const questions = [
      {
        question:
          "Qui sont les meilleurs joueurs de la Premier League cette saison ?",
        subQuestions: [
          {
            question: "Qui est le meilleur buteur de la Premier League ?",
            answer: (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topScorer = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.buts > current.statistiques.buts
                  ? prev
                  : current
              );
              return `Le meilleur buteur de la Premier League est **${topScorer.nom}** avec **${topScorer.statistiques.buts} buts**. Il joue pour **${topScorer.equipe}**.`;
            },
          },
          {
            question: "Qui a le plus de passes décisives en Premier League ?",
            answer: (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topAssister = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.passes_decisives >
                current.statistiques.passes_decisives
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de passes décisives en Premier League est **${topAssister.nom}** avec **${topAssister.statistiques.passes_decisives} passes décisives**. Il joue pour **${topAssister.equipe}**.`;
            },
          },
          {
            question:
              "Quel joueur a la meilleure note moyenne en Premier League ?",
            answer: (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topRated = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.note > current.statistiques.note
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure note moyenne en Premier League est **${topRated.nom}** avec une note de **${topRated.statistiques.note}**. Il joue pour **${topRated.equipe}**.`;
            },
          },
        ],
        answer: (data) => {
          const premierLeaguePlayers = data.par_ligue["Premier League"];
          const topScorer = premierLeaguePlayers.reduce((prev, current) =>
            prev.statistiques.buts > current.statistiques.buts ? prev : current
          );
          const topAssister = premierLeaguePlayers.reduce((prev, current) =>
            prev.statistiques.passes_decisives >
            current.statistiques.passes_decisives
              ? prev
              : current
          );
          const topRated = premierLeaguePlayers.reduce((prev, current) =>
            prev.statistiques.note > current.statistiques.note ? prev : current
          );
          return `En Premier League cette saison :\n- Meilleur buteur : **${topScorer.nom}** (${topScorer.statistiques.buts} buts)\n- Meilleur passeur : **${topAssister.nom}** (${topAssister.statistiques.passes_decisives} passes décisives)\n- Meilleure note : **${topRated.nom}** (${topRated.statistiques.note})`;
        },
      },
      {
        question:
          "Qui sont les meilleurs buteurs du Top 5 des ligues européennes ?",
        subQuestions: [
          {
            question: "Qui est le meilleur buteur de la Liga ?",
            answer: (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topScorer = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.buts > current.statistiques.buts
                  ? prev
                  : current
              );
              return `Le meilleur buteur de la Liga est **${topScorer.nom}** avec **${topScorer.statistiques.buts} buts**. Il joue pour **${topScorer.equipe}**.`;
            },
          },
          {
            question: "Qui est le meilleur buteur de la Bundesliga ?",
            answer: (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topScorer = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.buts > current.statistiques.buts
                  ? prev
                  : current
              );
              return `Le meilleur buteur de la Bundesliga est **${topScorer.nom}** avec **${topScorer.statistiques.buts} buts**. Il joue pour **${topScorer.equipe}**.`;
            },
          },
          {
            question: "Qui est le meilleur buteur de la Serie A ?",
            answer: (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topScorer = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.buts > current.statistiques.buts
                  ? prev
                  : current
              );
              return `Le meilleur buteur de la Serie A est **${topScorer.nom}** avec **${topScorer.statistiques.buts} buts**. Il joue pour **${topScorer.equipe}**.`;
            },
          },
          {
            question: "Qui est le meilleur buteur de la Ligue 1 ?",
            answer: (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topScorer = ligue1Players.reduce((prev, current) =>
                prev.statistiques.buts > current.statistiques.buts
                  ? prev
                  : current
              );
              return `Le meilleur buteur de la Ligue 1 est **${topScorer.nom}** avec **${topScorer.statistiques.buts} buts**. Il joue pour **${topScorer.equipe}**.`;
            },
          },
        ],
        answer: (data) => {
          // Correction du problème ici - extraction appropriée des données par ligue
          const leagues = [
            "Premier League",
            "Liga",
            "Bundesliga",
            "Serie A",
            "Ligue 1",
          ];
          const topScorers = leagues.map((league) => {
            if (!data.par_ligue[league])
              return `${league} : Données non disponibles`;

            const players = data.par_ligue[league];
            const topScorer = players.reduce((prev, current) =>
              prev.statistiques.buts > current.statistiques.buts
                ? prev
                : current
            );
            return `${league} : **${topScorer.nom}** (${topScorer.statistiques.buts} buts)`;
          });
          return `Les meilleurs buteurs du Top 5 des ligues européennes sont :\n${topScorers.join(
            "\n"
          )}`;
        },
      },
      {
        "question": "Quels sont les joueurs avec le plus de dribbles réussis dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de dribbles réussis en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topDribbler = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.dribbles_reussis > current.statistiques.dribbles_reussis
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis en Premier League est **${topDribbler.nom}** avec **${topDribbler.statistiques.dribbles_reussis} dribbles**. Il joue pour **${topDribbler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topDribbler = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.dribbles_reussis > current.statistiques.dribbles_reussis
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis en Liga est **${topDribbler.nom}** avec **${topDribbler.statistiques.dribbles_reussis} dribbles**. Il joue pour **${topDribbler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topDribbler = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.dribbles_reussis > current.statistiques.dribbles_reussis
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis en Bundesliga est **${topDribbler.nom}** avec **${topDribbler.statistiques.dribbles_reussis} dribbles**. Il joue pour **${topDribbler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topDribbler = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.dribbles_reussis > current.statistiques.dribbles_reussis
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis en Serie A est **${topDribbler.nom}** avec **${topDribbler.statistiques.dribbles_reussis} dribbles**. Il joue pour **${topDribbler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topDribbler = ligue1Players.reduce((prev, current) =>
                prev.statistiques.dribbles_reussis > current.statistiques.dribbles_reussis
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis en Ligue 1 est **${topDribbler.nom}** avec **${topDribbler.statistiques.dribbles_reussis} dribbles**. Il joue pour **${topDribbler.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topDribblers = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topDribbler = players.reduce((prev, current) =>
              prev.statistiques.dribbles_reussis > current.statistiques.dribbles_reussis
                ? prev
                : current
            );
            return `${league} : **${topDribbler.nom}** (${topDribbler.statistiques.dribbles_reussis} dribbles)`;
          });
          return `Les joueurs avec le plus de dribbles réussis dans chaque ligue sont :\n${topDribblers.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec la meilleure précision de passes dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a la meilleure précision de passes en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topPasser = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.precision_passes_pourcentage > current.statistiques.precision_passes_pourcentage
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure précision de passes en Premier League est **${topPasser.nom}** avec **${topPasser.statistiques.precision_passes_pourcentage}%**. Il joue pour **${topPasser.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure précision de passes en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topPasser = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.precision_passes_pourcentage > current.statistiques.precision_passes_pourcentage
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure précision de passes en Liga est **${topPasser.nom}** avec **${topPasser.statistiques.precision_passes_pourcentage}%**. Il joue pour **${topPasser.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure précision de passes en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topPasser = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.precision_passes_pourcentage > current.statistiques.precision_passes_pourcentage
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure précision de passes en Bundesliga est **${topPasser.nom}** avec **${topPasser.statistiques.precision_passes_pourcentage}%**. Il joue pour **${topPasser.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure précision de passes en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topPasser = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.precision_passes_pourcentage > current.statistiques.precision_passes_pourcentage
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure précision de passes en Serie A est **${topPasser.nom}** avec **${topPasser.statistiques.precision_passes_pourcentage}%**. Il joue pour **${topPasser.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure précision de passes en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topPasser = ligue1Players.reduce((prev, current) =>
                prev.statistiques.precision_passes_pourcentage > current.statistiques.precision_passes_pourcentage
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure précision de passes en Ligue 1 est **${topPasser.nom}** avec **${topPasser.statistiques.precision_passes_pourcentage}%**. Il joue pour **${topPasser.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topPassers = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topPasser = players.reduce((prev, current) =>
              prev.statistiques.precision_passes_pourcentage > current.statistiques.precision_passes_pourcentage
                ? prev
                : current
            );
            return `${league} : **${topPasser.nom}** (${topPasser.statistiques.precision_passes_pourcentage}%)`;
          });
          return `Les joueurs avec la meilleure précision de passes dans chaque ligue sont :\n${topPassers.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de tacles dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de tacles en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topTackler = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.tacles > current.statistiques.tacles
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles en Premier League est **${topTackler.nom}** avec **${topTackler.statistiques.tacles} tacles**. Il joue pour **${topTackler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topTackler = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.tacles > current.statistiques.tacles
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles en Liga est **${topTackler.nom}** avec **${topTackler.statistiques.tacles} tacles**. Il joue pour **${topTackler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topTackler = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.tacles > current.statistiques.tacles
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles en Bundesliga est **${topTackler.nom}** avec **${topTackler.statistiques.tacles} tacles**. Il joue pour **${topTackler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topTackler = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.tacles > current.statistiques.tacles
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles en Serie A est **${topTackler.nom}** avec **${topTackler.statistiques.tacles} tacles**. Il joue pour **${topTackler.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topTackler = ligue1Players.reduce((prev, current) =>
                prev.statistiques.tacles > current.statistiques.tacles
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles en Ligue 1 est **${topTackler.nom}** avec **${topTackler.statistiques.tacles} tacles**. Il joue pour **${topTackler.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topTacklers = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topTackler = players.reduce((prev, current) =>
              prev.statistiques.tacles > current.statistiques.tacles
                ? prev
                : current
            );
            return `${league} : **${topTackler.nom}** (${topTackler.statistiques.tacles} tacles)`;
          });
          return `Les joueurs avec le plus de tacles dans chaque ligue sont :\n${topTacklers.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de buts attendus (xG) dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de buts attendus en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topXG = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.expected_goals > current.statistiques.expected_goals
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus en Premier League est **${topXG.nom}** avec **${topXG.statistiques.expected_goals} xG**. Il joue pour **${topXG.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topXG = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.expected_goals > current.statistiques.expected_goals
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus en Liga est **${topXG.nom}** avec **${topXG.statistiques.expected_goals} xG**. Il joue pour **${topXG.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topXG = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.expected_goals > current.statistiques.expected_goals
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus en Bundesliga est **${topXG.nom}** avec **${topXG.statistiques.expected_goals} xG**. Il joue pour **${topXG.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topXG = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.expected_goals > current.statistiques.expected_goals
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus en Serie A est **${topXG.nom}** avec **${topXG.statistiques.expected_goals} xG**. Il joue pour **${topXG.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topXG = ligue1Players.reduce((prev, current) =>
                prev.statistiques.expected_goals > current.statistiques.expected_goals
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus en Ligue 1 est **${topXG.nom}** avec **${topXG.statistiques.expected_goals} xG**. Il joue pour **${topXG.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topXGs = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topXG = players.reduce((prev, current) =>
              prev.statistiques.expected_goals > current.statistiques.expected_goals
                ? prev
                : current
            );
            return `${league} : **${topXG.nom}** (${topXG.statistiques.expected_goals} xG)`;
          });
          return `Les joueurs avec le plus de buts attendus dans chaque ligue sont :\n${topXGs.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de passes décisives dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de passes décisives en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topAssister = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.passes_decisives > current.statistiques.passes_decisives
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de passes décisives en Premier League est **${topAssister.nom}** avec **${topAssister.statistiques.passes_decisives} passes décisives**. Il joue pour **${topAssister.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de passes décisives en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topAssister = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.passes_decisives > current.statistiques.passes_decisives
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de passes décisives en Liga est **${topAssister.nom}** avec **${topAssister.statistiques.passes_decisives} passes décisives**. Il joue pour **${topAssister.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de passes décisives en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topAssister = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.passes_decisives > current.statistiques.passes_decisives
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de passes décisives en Bundesliga est **${topAssister.nom}** avec **${topAssister.statistiques.passes_decisives} passes décisives**. Il joue pour **${topAssister.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de passes décisives en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topAssister = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.passes_decisives > current.statistiques.passes_decisives
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de passes décisives en Serie A est **${topAssister.nom}** avec **${topAssister.statistiques.passes_decisives} passes décisives**. Il joue pour **${topAssister.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de passes décisives en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topAssister = ligue1Players.reduce((prev, current) =>
                prev.statistiques.passes_decisives > current.statistiques.passes_decisives
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de passes décisives en Ligue 1 est **${topAssister.nom}** avec **${topAssister.statistiques.passes_decisives} passes décisives**. Il joue pour **${topAssister.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topAssisters = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topAssister = players.reduce((prev, current) =>
              prev.statistiques.passes_decisives > current.statistiques.passes_decisives
                ? prev
                : current
            );
            return `${league} : **${topAssister.nom}** (${topAssister.statistiques.passes_decisives} passes décisives)`;
          });
          return `Les joueurs avec le plus de passes décisives dans chaque ligue sont :\n${topAssisters.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec la meilleure note moyenne dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a la meilleure note moyenne en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topRated = premierLeaguePlayers.reduce((prev, current) =>
                prev.statistiques.note > current.statistiques.note
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure note moyenne en Premier League est **${topRated.nom}** avec une note de **${topRated.statistiques.note}**. Il joue pour **${topRated.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure note moyenne en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topRated = ligaPlayers.reduce((prev, current) =>
                prev.statistiques.note > current.statistiques.note
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure note moyenne en Liga est **${topRated.nom}** avec une note de **${topRated.statistiques.note}**. Il joue pour **${topRated.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure note moyenne en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topRated = bundesligaPlayers.reduce((prev, current) =>
                prev.statistiques.note > current.statistiques.note
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure note moyenne en Bundesliga est **${topRated.nom}** avec une note de **${topRated.statistiques.note}**. Il joue pour **${topRated.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure note moyenne en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topRated = serieAPlayers.reduce((prev, current) =>
                prev.statistiques.note > current.statistiques.note
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure note moyenne en Serie A est **${topRated.nom}** avec une note de **${topRated.statistiques.note}**. Il joue pour **${topRated.equipe}**.`;
            }
          },
          {
            "question": "Qui a la meilleure note moyenne en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topRated = ligue1Players.reduce((prev, current) =>
                prev.statistiques.note > current.statistiques.note
                  ? prev
                  : current
              );
              return `Le joueur avec la meilleure note moyenne en Ligue 1 est **${topRated.nom}** avec une note de **${topRated.statistiques.note}**. Il joue pour **${topRated.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topRatedPlayers = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topRated = players.reduce((prev, current) =>
              prev.statistiques.note > current.statistiques.note
                ? prev
                : current
            );
            return `${league} : **${topRated.nom}** (${topRated.statistiques.note})`;
          });
          return `Les joueurs avec la meilleure note moyenne dans chaque ligue sont :\n${topRatedPlayers.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de buts et de passes décisives combinés dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de buts et de passes décisives combinés en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topContributor = premierLeaguePlayers.reduce((prev, current) =>
                (prev.statistiques.buts + prev.statistiques.passes_decisives) >
                (current.statistiques.buts + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts et de passes décisives combinés en Premier League est **${topContributor.nom}** avec **${topContributor.statistiques.buts} buts** et **${topContributor.statistiques.passes_decisives} passes décisives**. Il joue pour **${topContributor.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts et de passes décisives combinés en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topContributor = ligaPlayers.reduce((prev, current) =>
                (prev.statistiques.buts + prev.statistiques.passes_decisives) >
                (current.statistiques.buts + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts et de passes décisives combinés en Liga est **${topContributor.nom}** avec **${topContributor.statistiques.buts} buts** et **${topContributor.statistiques.passes_decisives} passes décisives**. Il joue pour **${topContributor.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts et de passes décisives combinés en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topContributor = bundesligaPlayers.reduce((prev, current) =>
                (prev.statistiques.buts + prev.statistiques.passes_decisives) >
                (current.statistiques.buts + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts et de passes décisives combinés en Bundesliga est **${topContributor.nom}** avec **${topContributor.statistiques.buts} buts** et **${topContributor.statistiques.passes_decisives} passes décisives**. Il joue pour **${topContributor.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts et de passes décisives combinés en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topContributor = serieAPlayers.reduce((prev, current) =>
                (prev.statistiques.buts + prev.statistiques.passes_decisives) >
                (current.statistiques.buts + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts et de passes décisives combinés en Serie A est **${topContributor.nom}** avec **${topContributor.statistiques.buts} buts** et **${topContributor.statistiques.passes_decisives} passes décisives**. Il joue pour **${topContributor.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts et de passes décisives combinés en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topContributor = ligue1Players.reduce((prev, current) =>
                (prev.statistiques.buts + prev.statistiques.passes_decisives) >
                (current.statistiques.buts + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts et de passes décisives combinés en Ligue 1 est **${topContributor.nom}** avec **${topContributor.statistiques.buts} buts** et **${topContributor.statistiques.passes_decisives} passes décisives**. Il joue pour **${topContributor.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topContributors = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topContributor = players.reduce((prev, current) =>
              (prev.statistiques.buts + prev.statistiques.passes_decisives) >
              (current.statistiques.buts + current.statistiques.passes_decisives)
                ? prev
                : current
            );
            return `${league} : **${topContributor.nom}** (${topContributor.statistiques.buts} buts + ${topContributor.statistiques.passes_decisives} passes décisives)`;
          });
          return `Les joueurs avec le plus de buts et de passes décisives combinés dans chaque ligue sont :\n${topContributors.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de dribbles réussis et de passes décisives combinés dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de dribbles réussis et de passes décisives combinés en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topDribbleAssist = premierLeaguePlayers.reduce((prev, current) =>
                (prev.statistiques.dribbles_reussis + prev.statistiques.passes_decisives) >
                (current.statistiques.dribbles_reussis + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis et de passes décisives combinés en Premier League est **${topDribbleAssist.nom}** avec **${topDribbleAssist.statistiques.dribbles_reussis} dribbles** et **${topDribbleAssist.statistiques.passes_decisives} passes décisives**. Il joue pour **${topDribbleAssist.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis et de passes décisives combinés en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topDribbleAssist = ligaPlayers.reduce((prev, current) =>
                (prev.statistiques.dribbles_reussis + prev.statistiques.passes_decisives) >
                (current.statistiques.dribbles_reussis + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis et de passes décisives combinés en Liga est **${topDribbleAssist.nom}** avec **${topDribbleAssist.statistiques.dribbles_reussis} dribbles** et **${topDribbleAssist.statistiques.passes_decisives} passes décisives**. Il joue pour **${topDribbleAssist.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis et de passes décisives combinés en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topDribbleAssist = bundesligaPlayers.reduce((prev, current) =>
                (prev.statistiques.dribbles_reussis + prev.statistiques.passes_decisives) >
                (current.statistiques.dribbles_reussis + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis et de passes décisives combinés en Bundesliga est **${topDribbleAssist.nom}** avec **${topDribbleAssist.statistiques.dribbles_reussis} dribbles** et **${topDribbleAssist.statistiques.passes_decisives} passes décisives**. Il joue pour **${topDribbleAssist.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis et de passes décisives combinés en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topDribbleAssist = serieAPlayers.reduce((prev, current) =>
                (prev.statistiques.dribbles_reussis + prev.statistiques.passes_decisives) >
                (current.statistiques.dribbles_reussis + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis et de passes décisives combinés en Serie A est **${topDribbleAssist.nom}** avec **${topDribbleAssist.statistiques.dribbles_reussis} dribbles** et **${topDribbleAssist.statistiques.passes_decisives} passes décisives**. Il joue pour **${topDribbleAssist.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de dribbles réussis et de passes décisives combinés en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topDribbleAssist = ligue1Players.reduce((prev, current) =>
                (prev.statistiques.dribbles_reussis + prev.statistiques.passes_decisives) >
                (current.statistiques.dribbles_reussis + current.statistiques.passes_decisives)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de dribbles réussis et de passes décisives combinés en Ligue 1 est **${topDribbleAssist.nom}** avec **${topDribbleAssist.statistiques.dribbles_reussis} dribbles** et **${topDribbleAssist.statistiques.passes_decisives} passes décisives**. Il joue pour **${topDribbleAssist.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topDribbleAssists = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topDribbleAssist = players.reduce((prev, current) =>
              (prev.statistiques.dribbles_reussis + prev.statistiques.passes_decisives) >
              (current.statistiques.dribbles_reussis + current.statistiques.passes_decisives)
                ? prev
                : current
            );
            return `${league} : **${topDribbleAssist.nom}** (${topDribbleAssist.statistiques.dribbles_reussis} dribbles + ${topDribbleAssist.statistiques.passes_decisives} passes décisives)`;
          });
          return `Les joueurs avec le plus de dribbles réussis et de passes décisives combinés dans chaque ligue sont :\n${topDribbleAssists.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de tacles et d'interceptions combinés dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de tacles et d'interceptions combinés en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topTackleIntercept = premierLeaguePlayers.reduce((prev, current) =>
                (prev.statistiques.tacles + 0) >
                (current.statistiques.tacles + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles et d'interceptions combinés en Premier League est **${topTackleIntercept.nom}** avec **${topTackleIntercept.statistiques.tacles} tacles** et **de nombreuses interceptions**. Il joue pour **${topTackleIntercept.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles et d'interceptions combinés en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topTackleIntercept = ligaPlayers.reduce((prev, current) =>
                (prev.statistiques.tacles + 0) >
                (current.statistiques.tacles + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles et d'interceptions combinés en Liga est **${topTackleIntercept.nom}** avec **${topTackleIntercept.statistiques.tacles} tacles** et **de nombreuses interceptions**. Il joue pour **${topTackleIntercept.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles et d'interceptions combinés en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topTackleIntercept = bundesligaPlayers.reduce((prev, current) =>
                (prev.statistiques.tacles + 0) >
                (current.statistiques.tacles + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles et d'interceptions combinés en Bundesliga est **${topTackleIntercept.nom}** avec **${topTackleIntercept.statistiques.tacles} tacles** et **de nombreuses interceptions**. Il joue pour **${topTackleIntercept.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles et d'interceptions combinés en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topTackleIntercept = serieAPlayers.reduce((prev, current) =>
                (prev.statistiques.tacles + 0) >
                (current.statistiques.tacles + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles et d'interceptions combinés en Serie A est **${topTackleIntercept.nom}** avec **${topTackleIntercept.statistiques.tacles} tacles** et **de nombreuses interceptions**. Il joue pour **${topTackleIntercept.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de tacles et d'interceptions combinés en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topTackleIntercept = ligue1Players.reduce((prev, current) =>
                (prev.statistiques.tacles + 0) >
                (current.statistiques.tacles + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de tacles et d'interceptions combinés en Ligue 1 est **${topTackleIntercept.nom}** avec **${topTackleIntercept.statistiques.tacles} tacles** et **de nombreuses interceptions**. Il joue pour **${topTackleIntercept.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topTackleIntercepts = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topTackleIntercept = players.reduce((prev, current) =>
              (prev.statistiques.tacles + 0) >
              (current.statistiques.tacles + 0)
                ? prev
                : current
            );
            return `${league} : **${topTackleIntercept.nom}** (${topTackleIntercept.statistiques.tacles} tacles + de nombreuses interceptions)`;
          });
          return `Les joueurs avec le plus de tacles et d'interceptions combinés dans chaque ligue sont :\n${topTackleIntercepts.join("\n")}`;
        }
      },
      {
        "question": "Quels sont les joueurs avec le plus de buts attendus (xG) et de passes décisives attendues (xA) combinés dans chaque ligue ?",
        "subQuestions": [
          {
            "question": "Qui a le plus de buts attendus et de passes décisives attendues combinés en Premier League ?",
            "answer": (data) => {
              const premierLeaguePlayers = data.par_ligue["Premier League"];
              const topXGxA = premierLeaguePlayers.reduce((prev, current) =>
                (prev.statistiques.expected_goals + 0) >
                (current.statistiques.expected_goals + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus et de passes décisives attendues combinés en Premier League est **${topXGxA.nom}** avec **${topXGxA.statistiques.expected_goals} xG** et **un nombre important de xA**. Il joue pour **${topXGxA.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus et de passes décisives attendues combinés en Liga ?",
            "answer": (data) => {
              const ligaPlayers = data.par_ligue["Liga"];
              const topXGxA = ligaPlayers.reduce((prev, current) =>
                (prev.statistiques.expected_goals + 0) >
                (current.statistiques.expected_goals + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus et de passes décisives attendues combinés en Liga est **${topXGxA.nom}** avec **${topXGxA.statistiques.expected_goals} xG** et **un nombre important de xA**. Il joue pour **${topXGxA.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus et de passes décisives attendues combinés en Bundesliga ?",
            "answer": (data) => {
              const bundesligaPlayers = data.par_ligue["Bundesliga"];
              const topXGxA = bundesligaPlayers.reduce((prev, current) =>
                (prev.statistiques.expected_goals + 0) >
                (current.statistiques.expected_goals + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus et de passes décisives attendues combinés en Bundesliga est **${topXGxA.nom}** avec **${topXGxA.statistiques.expected_goals} xG** et **un nombre important de xA**. Il joue pour **${topXGxA.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus et de passes décisives attendues combinés en Serie A ?",
            "answer": (data) => {
              const serieAPlayers = data.par_ligue["Serie A"];
              const topXGxA = serieAPlayers.reduce((prev, current) =>
                (prev.statistiques.expected_goals + 0) >
                (current.statistiques.expected_goals + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus et de passes décisives attendues combinés en Serie A est **${topXGxA.nom}** avec **${topXGxA.statistiques.expected_goals} xG** et **un nombre important de xA**. Il joue pour **${topXGxA.equipe}**.`;
            }
          },
          {
            "question": "Qui a le plus de buts attendus et de passes décisives attendues combinés en Ligue 1 ?",
            "answer": (data) => {
              const ligue1Players = data.par_ligue["Ligue 1"];
              const topXGxA = ligue1Players.reduce((prev, current) =>
                (prev.statistiques.expected_goals + 0) >
                (current.statistiques.expected_goals + 0)
                  ? prev
                  : current
              );
              return `Le joueur avec le plus de buts attendus et de passes décisives attendues combinés en Ligue 1 est **${topXGxA.nom}** avec **${topXGxA.statistiques.expected_goals} xG** et **un nombre important de xA**. Il joue pour **${topXGxA.equipe}**.`;
            }
          }
        ],
        "answer": (data) => {
          const leagues = ["Premier League", "Liga", "Bundesliga", "Serie A", "Ligue 1"];
          const topXGxAs = leagues.map((league) => {
            if (!data.par_ligue[league]) return `${league} : Données non disponibles`;
    
            const players = data.par_ligue[league];
            const topXGxA = players.reduce((prev, current) =>
              (prev.statistiques.expected_goals + 0) >
              (current.statistiques.expected_goals + 0)
                ? prev
                : current
            );
            return `${league} : **${topXGxA.nom}** (${topXGxA.statistiques.expected_goals} xG + un nombre important de xA)`;
          });
          return `Les joueurs avec le plus de buts attendus et de passes décisives attendues combinés dans chaque ligue sont :\n${topXGxAs.join("\n")}`;
        }
      }
    ];

    // Fonction pour sélectionner des questions aléatoires
    function getRandomQuestions(questions, count = 10) {
      const randomQuestions = [];
      // Faire une copie du tableau pour ne pas modifier l'original
      const availableQuestions = [...questions];

      // Sélectionner des questions aléatoires
      while (randomQuestions.length < count && availableQuestions.length > 0) {
        const randomIndex = Math.floor(
          Math.random() * availableQuestions.length
        );
        randomQuestions.push(availableQuestions[randomIndex]);
        // Retirer la question sélectionnée des options disponibles
        availableQuestions.splice(randomIndex, 1);
      }

      return randomQuestions;
    }

    const questionsChoice = getRandomQuestions(questions, 10);
    setRandomQuestions(questionsChoice);
  };

  const handleQuestionClick = (question) => {
    if (!data || isAnswering) return; // Disable clicks during animation
    
    // Add the question to the chat
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { type: "question", content: question.question, isAnimated: false },
    ]);
    
    // Add an empty answer to the chat to start animation
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { type: "answer", content: "", isAnimated: true },
    ]);
    
    // Disable clicks during animation
    setIsAnswering(true);
    
    // If this question has sub-questions, update them
    if (question.subQuestions) {
      setSelectedSubQuestions(question.subQuestions);
    }
    
    // Generate the answer
    const answer = typeof question.answer === 'function' 
      ? question.answer(data) 
      : question.answer;
    
    // Animate the answer letter by letter
    let index = 0;
    const interval = setInterval(() => {
      if (index < answer.length) {
        setChatMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              content: answer.substring(0, index + 1),
            },
          ];
        });
        index++;
      } else {
        clearInterval(interval);
        // Mark the response as fully animated
        setChatMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, -1),
            { ...lastMessage, isAnimated: false },
          ];
        });
        // Re-enable clicks after animation
        setIsAnswering(false);
      }
    }, 50); // Writing speed
  };

  // Ajouter ce code après chaque modification de chatMessages pour déboguer
  useEffect(() => {
    console.log("État actuel de chatMessages:", chatMessages);
  }, [chatMessages]);

  const handleBackClick = () => {
    setSelectedSubQuestions([]);
  };

  // Fonction pour formater le texte avec des sauts de ligne et Markdown basique
  const formatTextWithLineBreaks = (text) => {
    if (!text) return null;

    // Transformer le markdown basique
    const formattedText = text.split("\n").map((line, i) => {
      // Remplacer les balises **texte** par des balises <strong>
      let processedLine = line;

      // Utiliser une regexp avec remplacement de fonction pour éviter les problèmes
      const boldRegex = /\*\*(.*?)\*\*/g;
      let match;
      let parts = [];
      let lastIndex = 0;

      while ((match = boldRegex.exec(processedLine)) !== null) {
        // Ajouter le texte avant le match
        if (match.index > lastIndex) {
          parts.push(processedLine.substring(lastIndex, match.index));
        }

        // Ajouter le texte en gras
        parts.push(
          <strong key={`bold-${i}-${match.index}`}>{match[1]}</strong>
        );

        lastIndex = match.index + match[0].length;
      }

      // Ajouter le reste du texte
      if (lastIndex < processedLine.length) {
        parts.push(processedLine.substring(lastIndex));
      }

      return (
        <React.Fragment key={i}>
          {parts.length > 0 ? parts : processedLine}
          {i < text.split("\n").length - 1 && <br />}
        </React.Fragment>
      );
    });

    return formattedText;
  };

  // Fonction pour faire défiler automatiquement vers le bas après l'ajout d'un message
  useEffect(() => {
    if (chatMessages.length > 0) {
      const chatContainer = document.getElementById("chat-container");
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [chatMessages]);

  return (
    <div
      className="border-0 mt-5"
      style={{ backgroundColor: "#fff", borderRadius: "1px", maxHeight: "calc(100vh - 260px)",
        minHeight: "300px",
        overflowY: "auto", }}
    >
        <div className="d-lg-flex justify-content-between align-items-center mb-4 w-100">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={onBack}
            className="d-flex align-items-center"
          >
            <ArrowLeft size={18} className="me-2" />
            Retour
          </Button>
          <h2 className="text-danger fw-bold mb-0 d-flex align-items-center mt-lg-0 mt-3 text-lg-start text-center">
            <Satellite size={24} className="me-2" />
            Statistiques des joueurs
          </h2>
          <div className="invisible">
            <Button variant="outline-light" size="sm">
              Invisible
            </Button>
          </div>
        </div>
      <div
        id="chat-container"
        style={{
          maxHeight: "500px",
          overflowY: "auto",
          marginBottom: "15px",
          padding: "10px",
        }}
      >
        {/* Messages de chat permanents */}
        {chatMessages.map((message, index) => (
          <div
            key={`message-${index}`} // Clé plus spécifique
            style={{
              textAlign: message.type === "question" ? "right" : "left",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                display: "inline-block",
                padding: "8px 12px",
                borderRadius:
                  message.type === "question"
                    ? "12px 12px 0 12px"
                    : "12px 12px 12px 0",
                backgroundColor:
                  message.type === "question" ? "#dc3545" : "#f8f9fa",
                color: message.type === "question" ? "#fff" : "#000",
                maxWidth: "80%",
              }}
            >
              {formatTextWithLineBreaks(message.content)}
            </div>
            {/* Indicateur de frappe */}
            {index === chatMessages.length - 1 &&
              isTyping &&
              message.type === "answer" && (
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: "#6c757d",
                    marginTop: "2px",
                    marginLeft: "10px",
                  }}
                >
                  En train d'écrire...
                </div>
              )}
          </div>
        ))}
      </div>

      {/* Section des questions aléatoires */}
      {animationFinished && (
        <>

          {/* Bouton Retour */}
          {selectedSubQuestions.length > 0 && (
            <Button
              variant="outline-secondary"
              size="sm"
              className="mb-2 me-2"
              onClick={handleBackClick}
              disabled={isAnswering || isTyping}
            >
              Retour vers les questions principales
            </Button>
          )}

          {/* Section des sous-questions */}
          {selectedSubQuestions.length > 0 && (
            <>
              <h6 className="mt-5 mb-3">
                Envie d'aller encore plus loin ? Explorez ces sous-questions
                pour affiner votre analyse !
              </h6>
              {selectedSubQuestions.map((subQuestion, index) => (
                <Button
                  key={index}
                  variant="outline-secondary"
                  size="sm"
                  className="mb-2 me-2"
                  onClick={() => handleQuestionClick(subQuestion)}
                  disabled={isAnswering || isTyping}
                >
                  {subQuestion.question}
                </Button>
              ))}
            </>
          )}

          {/* Questions principales - affichées uniquement si aucune sous-question n'est sélectionnée */}
          {selectedSubQuestions.length === 0 &&
            randomQuestions.map((question, index) => (
              <Button
                key={index}
                variant="outline-danger"
                size="sm"
                className="mb-2 me-2"
                onClick={() => handleQuestionClick(question)}
                disabled={isAnswering || isTyping}
              >
                {question.question}
              </Button>
            ))}
        </>
      )}

      {/* Styles CSS pour animations */}
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default PlayerStatistics;
