import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Plus, Minus } from 'lucide-react';

const MatchScoreComponent = ({ 
  match, 
  expandedCards = [], 
  onToggleCard = () => {} 
}) => {
  const matchId = `${match.home_team}-${match.away_team}-${match.date}-${match.expected_goals}`.replace(/[^a-zA-Z0-9-]/g, '');
  const isExpanded = Array.isArray(expandedCards) && expandedCards.includes(matchId);

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleCard(matchId);
  };

  // Fonction pour calculer la probabilité que les deux équipes marquent (BTTS)
  const calculateBttsProb = (match) => {
    // Facteurs basés sur les buts marqués et encaissés
    const homeScoreProb = (match.home_goals + match.away_conceded) / 2;
    const awayScoreProb = (match.away_goals + match.home_conceded) / 2;
    
    // Facteur basé sur l'écart de niveau
    const levelGap = Math.abs(match.home_position - match.away_position);
    let levelFactor = 1;
    if (levelGap > 10) {
      levelFactor = 0.8; // Réduction si grand écart de niveau
    }
    
    // Facteur basé sur la forme des équipes
    const formFactor = (match.home_form + match.away_form) / 200; // 0-1 range
    
    // Facteur basé sur expected goals
    const xgFactor = match.expected_goals > 2 ? 1.2 : 1;
    
    // Facteur basé sur probabilité over 2.5
    const overFactor = match.over_2_5_prob / 100;
    
    // Calcul de la probabilité BTTS (0-1)
    let bttsProb = Math.min(homeScoreProb * awayScoreProb * levelFactor * (1 + formFactor) * xgFactor * overFactor, 1);
    
    // Ajustement pour éviter les valeurs extrêmes
    bttsProb = Math.max(Math.min(bttsProb, 0.95), 0.25);
    
    return bttsProb * 100; // Convertir en pourcentage
  };

  // Fonction avancée pour prédire le score exact
  const predictScore = (match) => {
    // Extraire les données pertinentes
    const homeGoalsAvg = match.home_goals;
    const awayGoalsAvg = match.away_goals;
    const homeConcededAvg = match.home_conceded;
    const awayConcededAvg = match.away_conceded;
    const expectedGoals = match.expected_goals;
    const winProbability = match.win_probability / 100;
    const homeForm = match.home_form / 100;
    const awayForm = match.away_form / 100;
    
    // Facteur d'avantage à domicile
    const homeAdvantage = 1.2;
    
    // Calculer les forces d'attaque et faiblesses défensives
    const meanGoals = (homeGoalsAvg + awayGoalsAvg) / 2;
    const meanConceded = (homeConcededAvg + awayConcededAvg) / 2;
    
    const homeAttackStrength = homeGoalsAvg / meanGoals;
    const awayAttackStrength = awayGoalsAvg / meanGoals;
    const homeDefenseWeakness = homeConcededAvg / meanConceded;
    const awayDefenseWeakness = awayConcededAvg / meanConceded;
    
    // Calculer les lambdas (taux de buts attendus) pour les deux équipes
    let lambdaHome = expectedGoals * homeAttackStrength * awayDefenseWeakness * homeAdvantage * homeForm;
    let lambdaAway = expectedGoals * awayAttackStrength * homeDefenseWeakness * awayForm;
    
    // Ajuster en fonction de la probabilité de victoire
    const drawProbability = 0.25; // Estimation standard
    const awayWinProbability = 1 - winProbability - drawProbability;
    
    // Si l'équipe extérieure est fortement favorite
    if (winProbability < 0.2 && awayWinProbability > 0.5) {
      lambdaHome *= 0.8;
      lambdaAway *= 1.2;
    }
    
    // Prendre en compte les positions des équipes
    const positionFactor = Math.log(match.away_position / match.home_position);
    if (positionFactor > 0) {
      // L'équipe à domicile est mieux classée
      lambdaHome *= (1 + positionFactor * 0.1);
      lambdaAway *= (1 - positionFactor * 0.05);
    } else if (positionFactor < 0) {
      // L'équipe à l'extérieur est mieux classée
      lambdaHome *= (1 + positionFactor * 0.05);
      lambdaAway *= (1 - positionFactor * 0.1);
    }
    
    // Prendre en compte la probabilité de plus/moins de 2.5 buts
    if (match.over_2_5_prob > 60) {
      const totalExpectedGoals = lambdaHome + lambdaAway;
      if (totalExpectedGoals < 2.5) {
        const adjustmentFactor = 2.5 / totalExpectedGoals;
        lambdaHome *= adjustmentFactor;
        lambdaAway *= adjustmentFactor;
      }
    }
    
    // Calculer notre propre probabilité BTTS
    const bttsProb = calculateBttsProb(match);
    
    // Ajuster les scores en fonction de notre analyse BTTS
    if (bttsProb > 70) {
      // Haute probabilité que les deux équipes marquent
      lambdaHome = Math.max(lambdaHome, 1);
      lambdaAway = Math.max(lambdaAway, 1);
    } else if (bttsProb < 40) {
      // Faible probabilité que les deux équipes marquent
      // Au moins une équipe risque de ne pas marquer
      if (winProbability > 0.5) {
        // L'équipe à domicile est favorite, l'adversaire risque de ne pas marquer
        lambdaAway = Math.min(lambdaAway, 0.5);
      } else if (awayWinProbability > 0.5) {
        // L'équipe extérieure est favorite, l'équipe à domicile risque de ne pas marquer
        lambdaHome = Math.min(lambdaHome, 0.5);
      }
    }
    
    // Arrondir pour obtenir le score final
    const homeScore = Math.round(lambdaHome);
    const awayScore = Math.round(lambdaAway);
    
    return {
      score: `${homeScore}-${awayScore}`,
      bttsProb: Math.round(bttsProb)
    };
  };

  const prediction = predictScore(match);
  const predictedScore = prediction.score;
  const bttsProb = prediction.bttsProb;

  // Détermination si les deux équipes vont marquer ou non
  const willBothTeamsScore = bttsProb > 50 ? "Oui" : "Non";
  const bttsColor = bttsProb > 70 ? "text-success" : 
                    bttsProb > 50 ? "text-warning" : "text-danger";

  return (
    <Col md={4} className="mb-4">
      <div style={{ 
        position: 'relative',
        minHeight: '110px',
        transition: 'min-height 0.2s ease-in-out'
      }}>
        <div style={{
          position: 'static',
          width: '100%',
          zIndex: 1,
        }}>
          <Card 
            style={{ 
              width: '100%',
              backgroundColor: 'white',
              boxShadow: isExpanded ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
            }} 
            data-match-id={matchId}
          >
            <Card.Header className="d-flex justify-content-between align-items-center py-2">
              <span>{match.home_team} vs {match.away_team}</span>
              {/* <button 
                onClick={handleToggle}
                style={{
                  border: 'none',
                  background: 'none',
                  padding: '0',
                  cursor: 'pointer'
                }}
              >
                {isExpanded ? <Minus size={16} /> : <Plus size={16} />}
              </button> */}
            </Card.Header>
            <Card.Body className="py-2">
              <div className="mb-2">
                <strong>Date:</strong> {match.date}
              </div>
              <div className="mb-2">
                <strong>Score Prédit:</strong> {predictedScore}
              </div>
              <div className="mb-2">
                {/* <strong>Les deux équipes marquent:</strong> <span className={bttsColor}>{willBothTeamsScore} ({bttsProb}%)</span> */}
              </div>
              {/* {isExpanded && (
                <div className="mt-3">
                  <div className="mb-1"><strong>Statistiques:</strong></div>
                  <div className="small">
                    <div>Pos: {match.home_position} vs {match.away_position}</div>
                    <div>Pts: {match.home_points} vs {match.away_points}</div>
                    <div>Forme: {match.home_form}% vs {match.away_form}%</div>
                    <div>Buts: {match.home_goals} vs {match.away_goals}</div>
                    <div>Encaissés: {match.home_conceded} vs {match.away_conceded}</div>
                    <div>Prob. victoire: {match.win_probability}%</div>
                    <div>Expected goals: {match.expected_goals}</div>
                    <div>Over 2.5: {match.over_2_5_prob}%</div>
                  </div>
                </div>
              )} */}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Col>
  );
};

export default MatchScoreComponent;