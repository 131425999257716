import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Container,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import { MessageCircleCode, Trophy } from "lucide-react";
import Chat from "./Chat";
import BattleChallenge from "./Battle/BattleChallenge";
import UserProfile from "./Battle/UserProfile";
import PronosticForm from "./Battle/PronosticForm";
import BattleLeaderboard from "./Battle/BattleLeaderboard";

const LandingNavbar = ({ onAnalyticsClick }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showBattle, setShowBattle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleChatOpen = () => {
    setShowChat(true);
    setIsLoading(true);
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleChatClose = () => {
    setShowChat(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  const handleBattleOpen = () => {
    setShowBattle(true);
    setIsLoading(true);
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleBattleClose = () => {
    setShowBattle(false);
    setIsLoading(false);
    document.body.classList.remove("overflow-hidden"); // Réactive le scroll
  };

  //Battle

  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    id: 1,
    name: "John Doe",
    score: 100,
    level: 5,
    badges: ["Champion"],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const ligues = [
    { code: "liga", nom: "Liga", flag: "🇪🇸" },
    { code: "bundesliga", nom: "Bundesliga", flag: "🇩🇪" },
    { code: "premier_league", nom: "PL", flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿" },
    { code: "serie_a", nom: "Serie A", flag: "🇮🇹" },
    { code: "ligue_1", nom: "Ligue 1", flag: "🇫🇷" },
    { code: "champions_league", nom: "Champions League", flag: "🇪🇺" },
    { code: "europa_league", nom: "Europa League", flag: "🇪🇺" },
    { code: "conference_league", nom: "Conference League", flag: "🇪🇺" },
  ];

  useEffect(() => {
    loadAllMatches();
  }, []);

  const loadAllMatches = async () => {
    setLoading(true);
    try {
      // Créer un tableau de promesses pour toutes les requêtes
      const promises = ligues.map(async (ligue) => {
        const response = await fetch(`/${ligue.code}_predictions_viz.json`);
        const data = await response.json();
        return data.match_overview.map((match) => ({
          ...match,
          league: ligue.code,
          leagueName: ligue.nom,
          flag: ligue.flag,
          uniqueId: `${ligue.code}-${match.home_team}-${match.away_team}-${match.date}`,
        }));
      });

      // Attendre que toutes les requêtes soient terminées
      const allMatchesArrays = await Promise.all(promises);

      // Combiner tous les matchs en un seul tableau
      const allMatches = allMatchesArrays.flat();

      // Trier les matchs par date
      const sortedMatches = allMatches.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      setMatches(sortedMatches);
      // console.log("Tous les matchs chargés:", sortedMatches);
    } catch (error) {
      console.error("Erreur lors du chargement des matchs:", error);
      setError("Erreur lors du chargement des matchs. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPronostics = (predictions) => {
    console.log("Pronostics soumis:", predictions);
  };

  const handleChallenge = (userId) => {
    console.log("Défi lancé contre l'utilisateur:", userId);
  };

  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className={`transition-all ${isScrolled ? "bg-white shadow-sm py-2 is-scrolled" : "bg-transparent py-3"}`}
      >
        <Container>
          <Navbar.Brand href="#" className="d-flex align-items-center">
            <img
              src="/logo_mini.png"
              height="40"
              className="d-inline-block align-top me-2"
              alt="MatchSynce Logo"
            />
            <span
              className={`fw-bold ${isScrolled ? "text-dark" : "text-white"}`}
            >
              MatchSynce
            </span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="mx-auto text-lg-center text-start">
              <Nav.Link
                href="#about"
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                À propos
              </Nav.Link>
              <Nav.Link
                href="#messaging"
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                Fonctionnalités
              </Nav.Link>
              <Nav.Link
                href="#installation"
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                Installation
              </Nav.Link>
              <Nav.Link
                href="#contact"
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                href="#feedback"
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                Ton avis
              </Nav.Link>
              {/* <Nav.Link
                onClick={handleChatOpen}
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                Messagerie
              </Nav.Link> */}
              <Nav.Link
                onClick={handleBattleOpen}
                className={`mx-2 ${isScrolled ? "text-dark" : "text-white"}`}
              >
                MatchSynce Battle
              </Nav.Link>
            </Nav>

            <div className="d-flex flex-lg-row align-items-start mt-3 mt-lg-0">
              <Button
                variant="success"
                className="d-flex align-items-center mb-2 mb-lg-0"
                onClick={handleChatOpen}
              >
                <MessageCircleCode size={20} className="me-2" />
                Messagerie
              </Button>
              <Button
                variant="success"
                className="d-flex align-items-center ms-lg-3 ms-3 ms-lg-0"
                onClick={onAnalyticsClick}
              >
                <Trophy size={20} className="me-2" />
                Voir les Analyses
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Chat Modal */}
      {showChat && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "92vw", // Largeur maximale du modal
              width: "92vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Communauté MatchSynce</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleChatClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src="/matchsynce_chat.png"
                      alt="MatchSynce Logo"
                      className="zoom-animation"
                      style={{ width: "180px", height: "130px" }}
                    />
                  </div>
                ) : (
                  <Chat style={{ height: "100%" }} /> // Le composant Chat prend toute la hauteur disponible
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .zoom-animation {
            animation: zoomInOut 1.5s ease-in-out;
          }

          @keyframes zoomInOut {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            50% {
              transform: scale(1.2);
              opacity: 1;
            }
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }

          .transition-all {
            transition: all 0.3s ease-in-out;
          }
          
          .navbar-toggler {
            border-color: ${isScrolled ? "#000" : "#fff"};
            outline: none !important;
            // border: none !important;
            border-radius: 0 !important;
            box-shadow: none !important;
          }
          
          .navbar-toggler-icon {
            background-image: url('/images/b-blanc.png');  /* Image blanche par défaut */
            background-size: contain;
            background-repeat: no-repeat;
            width: 30px;  /* Ajuste la taille de l'icône */
            height: 30px; /* Ajuste la taille de l'icône */
          }

          /* Quand la page est scrollée (isScrolled == true), utilise l'image noire */
          .navbar.is-scrolled .navbar-toggler-icon {
            background-image: url('/images/b-noir.png');
          }

          
          @media (max-width: 991.98px) {
            .navbar-collapse {
              background-color: ${isScrolled ? "#fff" : "rgba(0,0,0,0.7)"};
              padding: 1rem;
              border-radius: 8px;
              margin-top: 0.5rem;
            }
          }
        `}
      </style>

      {showBattle && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "93vw", // Largeur maximale du modal
              width: "93vw", // Largeur du modal
              maxHeight: "90vh", // Hauteur maximale du modal
              height: "90vh", // Hauteur du modal
            }}
          >
            <div
              className="modal-content"
              style={{ height: "100%", maxHeight: "90vh", overflow: "hidden" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">MatchSynce Battle</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleBattleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body p-0 d-flex flex-column"
                style={{ height: "100%" }}
              >
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src="/matchsynce_battle.png"
                      alt="MatchSynce Logo"
                      className="zoom-animation"
                      style={{ width: "130px", height: "180px" }}
                    />
                  </div>
                ) : (
                  <Container fluid className="h-100 p-0">
                    <Row className="h-100">
                      <Col className="h-100">
                        <PronosticForm
                          matches={matches}
                          onSubmit={handleSubmitPronostics}
                          style={{ height: "100%" }} // Prendre toute la hauteur disponible
                        />
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingNavbar;
