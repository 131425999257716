import React, { useState, useCallback } from 'react';
import { InputGroup, Form, Button, Alert } from 'react-bootstrap';
import { Search, RotateCw } from 'lucide-react';

const TeamSearch = ({ matchList = [], onFilterChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [noResults, setNoResults] = useState(false);

  const filterMatches = useCallback((term) => {
    if (!term.trim()) {
      setNoResults(false);
      onFilterChange(matchList);
      return;
    }

    const filtered = matchList.filter(match => 
      match.home_team.toLowerCase().includes(term.toLowerCase()) ||
      match.away_team.toLowerCase().includes(term.toLowerCase())
    );
    
    setNoResults(filtered.length === 0);
    onFilterChange(filtered);
  }, [matchList, onFilterChange]);

  const handleSearch = (newTerm) => {
    setSearchTerm(newTerm);
    filterMatches(newTerm);
  };

  const handleReset = () => {
    setSearchTerm('');
    filterMatches('');
  };

  if (!matchList || matchList.length === 0) {
    return null;
  }

  return (
    <div className="w-30 mb-3">
      <InputGroup>
        <InputGroup.Text className="bg-success text-white">
          <Search size={18} />
        </InputGroup.Text>
        <Form.Control
          placeholder="Rechercher une équipe..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          className="border-success"
        />
        {searchTerm && (
          <Button 
            variant="outline-success" 
            onClick={handleReset}
            className="d-flex align-items-center"
          >
            <RotateCw size={18} />
          </Button>
        )}
      </InputGroup>

      {noResults && (
        <Alert variant="warning" className="mt-3 d-flex align-items-center justify-content-between">
          <div>
            <strong>Aucune équipe trouvée pour "{searchTerm}"</strong>
          </div>
          <Button 
            variant="warning" 
            size="sm" 
            onClick={handleReset}
            className="d-flex align-items-center gap-2"
          >
            <RotateCw size={16} />
            Afficher toutes les équipes
          </Button>
        </Alert>
      )}
    </div>
  );
};

export default TeamSearch;