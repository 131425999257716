import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Search, User } from "lucide-react";

const PronosticForm = ({ matches }) => {
  const [predictions, setPredictions] = useState({});
  const [submittedPredictions, setSubmittedPredictions] = useState({});
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const [token, setToken] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("matches");
  const [allPronostics, setAllPronostics] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [predictionAnalysis, setPredictionAnalysis] = useState([]);
  const [startDate, setStartDate] = useState(""); // Date de début
  const [endDate, setEndDate] = useState(""); // Date de fin

  useEffect(() => {
    const savedToken = localStorage.getItem("pronosticToken");
    const savedUsername = localStorage.getItem("pronosticUsername");

    if (savedToken && savedUsername) {
      setToken(savedToken);
      setUsername(savedUsername);
      setIsRegistered(true);
      loadUserPronostics(decodeToken(savedToken).id);
      loadAllPronostics();
      getAllUsers();
    }
  }, []);

  const getServerUrl = () => {
    const hostname = window.location.hostname;
    const port = import.meta.env?.VITE_SERVER_PORT || 5003;
    return hostname === "matchsynce.com"
      ? `https://matchsynce.com:${port}`
      : `https://${hostname}:${port}`;
  };

  const getServerPronoUrl = () => {
    const hostname = window.location.hostname;
    const port = import.meta.env?.VITE_SERVER_PORT || 5005;
    return hostname === "matchsynce.com"
      ? `https://matchsynce.com:${port}`
      : `https://${hostname}:${port}`;
  };

  const decodeToken = (token) => {
    try {
      // Extraire le payload (la partie du milieu)
      const payload = token.split(".")[1];

      // Décoder la base64 et convertir en objet
      const decodedPayload = JSON.parse(atob(payload));

      return decodedPayload;
    } catch (error) {
      // console.error("Erreur lors du décodage du token:", error);
      return null;
    }
  };

  // setCurrentUserName(decodeToken(token).username);

  const loadUserPronostics = async (userId) => {
    try {
      const serverUrl = getServerPronoUrl();
      const response = await fetch(
        `${serverUrl}/api/pronostics/user/${userId}`
      );
      const data = await response.json();

      const predictionMap = {};
      const submittedMap = {};

      data.forEach((pronostic) => {
        predictionMap[pronostic.matchId] = {
          homeScore: pronostic.homeScore,
          awayScore: pronostic.awayScore,
        };
        submittedMap[pronostic.matchId] = true;
      });

      setPredictions(predictionMap);
      setSubmittedPredictions(submittedMap);
    } catch (error) {
      setError("Erreur lors du chargement des pronostics");
    }
  };

  const loadAllPronostics = async () => {
    try {
      const serverUrl = getServerPronoUrl();
      const response = await fetch(`${serverUrl}/api/pronostics`);
      const data = await response.json();
      //console.log('Pronostics data: ', data)
      setAllPronostics(data);
    } catch (error) {
      setError("Erreur lors du chargement des pronostics");
    }
  };

  const getAllUsers = async () => {
    try {
      const serverUrl = getServerUrl();
      console.log("URL du serveur:", serverUrl);
      console.log("Début de la requête...");
      const response = await fetch(`${serverUrl}/api/users/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
        method: "GET",
      });

      console.log("Réponse reçue:", response.status);
      const data = await response.json();
      console.log("Users data: ", data);
      setAllUsers(data);
    } catch (error) {
      console.error("Erreur complète:", error);
      setError("Erreur lors du chargement des utilisateurs");
    }
  };

  //console.log('setAllPronostics: ', allPronostics)

  const handleAuth = async (e) => {
    e.preventDefault();
    setError("");

    const endpoint = isLogin ? "login" : "register";
    const serverUrl = getServerUrl();

    try {
      const response = await fetch(`${serverUrl}/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("pronosticToken", data.token);
        localStorage.setItem("pronosticUsername", data.user.username);
        setToken(data.token);
        setUsername(data.user.username);
        setIsRegistered(true);

        loadUserPronostics(decodeToken(data.token).id);
        loadAllPronostics();
      } else {
        setError(data.message || "Une erreur est survenue");
      }
    } catch (error) {
      setError("Erreur de connexion au serveur");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("pronosticToken");
    localStorage.removeItem("pronosticUsername");
    setToken(null);
    setUsername("");
    setIsRegistered(false);
    setPredictions({});
    setSubmittedPredictions({});
  };

  const handleInputChange = (matchId, field, value) => {
    if (!submittedPredictions[matchId]) {
      setPredictions((prev) => ({
        ...prev,
        [matchId]: {
          ...prev[matchId],
          [field]: value,
        },
      }));
    }
  };

  const handleSubmitMatch = async (matchId) => {
    if (!token) return;

    /**
     * @type {Object|null}
     */
    const decodedToken = decodeToken(token);
    const userId = decodedToken.id;

    const matchPrediction = predictions[matchId];
    if (!matchPrediction?.homeScore || !matchPrediction?.awayScore) return;

    // Trouver le match correspondant
    const match = matches.find((m) => m.uniqueId === matchId);

    try {
      const serverUrl = getServerPronoUrl();
      const response = await fetch(`${serverUrl}/api/pronostics`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userId,
          matchId: matchId,
          homeTeam: match.home_team,
          awayTeam: match.away_team,
          homeScore: parseInt(matchPrediction.homeScore),
          awayScore: parseInt(matchPrediction.awayScore),
          league: match.leagueName,
          flag: match.flag,
          matchDate: match.date,
        }),
      });

      if (response.ok) {
        setSubmittedPredictions((prev) => ({
          ...prev,
          [matchId]: true,
        }));
      } else {
        const data = await response.json();
        setError(data.message || "Erreur lors de la soumission du pronostic");
      }
    } catch (error) {
      setError("Erreur de connexion au serveur");
    }
  };

  const filteredMatches = matches.filter((match) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      match.home_team.toLowerCase().includes(searchLower) ||
      match.away_team.toLowerCase().includes(searchLower)
    );
  });

  const filteredPronostics = Array.isArray(allPronostics)
    ? allPronostics.filter((pronostic) => {
        const matchSearch = searchTerm.toLowerCase();
        const userSearch = userSearchTerm.toLowerCase();
        const matchDate = new Date(pronostic.matchDate);

        const matchesTeams =
          (pronostic.homeTeam?.toLowerCase() || "").includes(matchSearch) ||
          (pronostic.awayTeam?.toLowerCase() || "").includes(matchSearch);

        const matchesUser = (pronostic.userName?.toLowerCase() || "").includes(
          userSearch
        );

        // Filtre par date
        const matchesDate =
          (!startDate || matchDate >= new Date(startDate)) &&
          (!endDate || matchDate <= new Date(endDate));

        return matchesTeams && matchesUser && matchesDate;
      })
    : [];

  //console.log("user: ", currentUserName);

  const isUserPronostic = (pronostic) => {
    const decodedToken = decodeToken(token);
    // console.log("user name: ", decodedToken);
    return (
      decodedToken &&
      decodedToken.username &&
      decodedToken.id === pronostic.userId
    );
  };

  //classement

  useEffect(() => {
    const loadPredictionAnalysis = async () => {
      try {
        const response = await fetch("./prediction_analysis.json");
        const data = await response.json();
        console.log("loadPredictionAnalysis data: ", data);
        setPredictionAnalysis(data);
      } catch (error) {
        console.error(
          "Erreur lors du chargement des analyses de prédiction:",
          error
        );
      }
    };

    loadPredictionAnalysis();
  }, []);

  const calculatePoints = (pronostic, actualResult) => {
    let points = 0;

    // Comparer le score
    if (
      pronostic.homeScore === actualResult.actual_goals_home &&
      pronostic.awayScore === actualResult.actual_goals_away
    ) {
      points += 3; // Score exact
    } else if (
      (pronostic.homeScore > pronostic.awayScore &&
        actualResult.actual_goals_home > actualResult.actual_goals_away) ||
      (pronostic.homeScore < pronostic.awayScore &&
        actualResult.actual_goals_home < actualResult.actual_goals_away) ||
      (pronostic.homeScore === pronostic.awayScore &&
        actualResult.actual_goals_home === actualResult.actual_goals_away)
    ) {
      points += 1; // Résultat correct (victoire, défaite ou match nul)
    }

    // Comparer over/under 2.5
    if (
      (pronostic.homeScore + pronostic.awayScore > 2.5 &&
        actualResult.over_2_5_actual) ||
      (pronostic.homeScore + pronostic.awayScore <= 2.5 &&
        !actualResult.over_2_5_actual)
    ) {
      points += 1;
    }

    // Comparer BTTS
    if (
      (pronostic.homeScore > 0 &&
        pronostic.awayScore > 0 &&
        actualResult.btts_actual) ||
      ((pronostic.homeScore === 0 || pronostic.awayScore === 0) &&
        !actualResult.btts_actual)
    ) {
      points += 1;
    }

    return points;
  };

  useEffect(() => {
    console.log("useEffect triggered");
    const fetchUsers = async () => {
      try {
        //console.log("Fetching users...");
        const chatUsersResponse = await fetch(
          `${getServerUrl()}/api/users/all`
        );
        //console.log("Chat users response:", chatUsersResponse);
        const chatUsers = await chatUsersResponse.json();
        //console.log("Chat users data:", chatUsers);

        const pronoUsersResponse = await fetch(
          `${getServerPronoUrl()}/api/users`
        );
        //console.log("Prono users response:", pronoUsersResponse);
        const pronoUsers = await pronoUsersResponse.json();
        //console.log("Prono users data:", pronoUsers);

        // Fusionner les utilisateurs
        const mergedUsers = chatUsers.map((chatUser) => {
          const pronoUser = pronoUsers.find((user) => user.id === chatUser.id);
          return {
            ...chatUser,
            ...pronoUser,
          };
        });

        //console.log("Merged users data:", mergedUsers);
        setAllUsers(mergedUsers);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs:",
          error
        );
      }
    };

    fetchUsers();
  }, []);

  const generateRanking = () => {
    const userPoints = {};

    allPronostics.forEach((pronostic) => {
      const actualResult = predictionAnalysis.analyses.find(
        (result) =>
          result.match === `${pronostic.homeTeam} vs ${pronostic.awayTeam}` &&
          result.date === pronostic.matchDate
      );

      if (actualResult) {
        const points = calculatePoints(pronostic, actualResult);
        if (!userPoints[pronostic.userId]) {
          userPoints[pronostic.userId] = {
            id: pronostic.userId,
            points: 0,
            username: "",
          };
        }
        userPoints[pronostic.userId].points += points;
      }
    });

    allUsers.forEach((user) => {
      if (userPoints[user.id]) {
        userPoints[user.id].username = user.username;
      }
    });

    const ranking = Object.values(userPoints).sort(
      (a, b) => b.points - a.points
    );
    return ranking;
  };

  const saveRanking = async () => {
    const ranking = generateRanking();
    console.log("Generated Ranking:", ranking);

    try {
      const serverUrl = getServerPronoUrl();
      await Promise.all(
        ranking.map((user) =>
          fetch(`${serverUrl}/api/rankings`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              userId: user.id,
              username: user.username,
              points: user.points,
            }),
          })
        )
      );
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du classement:", error);
      setError("Erreur lors de la sauvegarde du classement");
    }
  };

  useEffect(() => {
    if (allPronostics.length > 0 && allUsers.length > 0) {
      saveRanking();
    }
  }, [allPronostics, allUsers]);

  const [rankings, setRankings] = useState([]);

  const loadRankings = async () => {
    try {
      const serverUrl = getServerPronoUrl();
      const response = await fetch(`${serverUrl}/api/rankings`);
      const data = await response.json();
      setRankings(data);
    } catch (error) {
      setError("Erreur lors du chargement du classement");
    }
  };

  useEffect(() => {
    loadRankings();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     saveRanking();
  //   }, 60000); // Mise à jour toutes les minutes

  //   return () => clearInterval(interval);
  // }, []);

  if (!isRegistered) {
    return (
      <div className="p-5 mb-5 bg-light d-flex align-items-center justify-content-center">
        <div
          className="card shadow-lg"
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <div className="card-header bg-light text-center py-4">
            <img
              src="/matchsynce_battle.png"
              alt="Pronostic Logo"
              className="mb-2"
              style={{ height: "90px", width: "70px" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://placehold.co/90x90?text=Pronostic";
              }}
            />
            <h2 className="h4 mb-0 text-dark">
              {isLogin ? "Connexion" : "Inscription"}
            </h2>
          </div>
          <div className="card-body p-4">
            {error && (
              <Alert variant="danger" className="mb-3">
                {error}
              </Alert>
            )}
            <Form onSubmit={handleAuth}>
              <Form.Group className="mb-3">
                <Form.Label>Nom d'utilisateur</Form.Label>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Entrez votre nom d'utilisateur"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Entrez votre mot de passe"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                {isLogin ? "Se connecter" : "S'inscrire"}
              </Button>
            </Form>
            <div className="text-center mt-3">
              <Button
                variant="link"
                onClick={() => {
                  setIsLogin(!isLogin);
                  setError("");
                  setUsername("");
                  setPassword("");
                }}
              >
                {isLogin
                  ? "Pas encore de compte ? S'inscrire"
                  : "Déjà un compte ? Se connecter"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="p-3 bg-white border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Pronostics des Matchs</h5>
          <div className="d-flex align-items-center gap-3">
            <span className="text-muted d-none d-sm-block">
              Connecté en tant que {username}
            </span>
            <span className="text-muted d-block d-sm-none">{username}</span>
            <Button
              variant="link"
              className="text-danger p-0"
              onClick={handleLogout}
            >
              Déconnexion
            </Button>
          </div>
        </div>

        {error && (
          <Alert
            variant="danger"
            onClose={() => setError("")}
            dismissible
            className="mb-3"
          >
            {error}
          </Alert>
        )}

        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="matches" title="Mes Pronostics">
            <div className="position-relative mb-3">
              <Form.Control
                type="text"
                placeholder="Rechercher une équipe..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pe-4"
              />
              <Search
                className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                size={18}
              />
            </div>

            <div
              className="bg-light p-3 rounded"
              style={{
                maxHeight: "calc(100vh - 380px)",
                minHeight: "300px",
                overflowY: "auto",
              }}
            >
              <Row>
                {filteredMatches.map((match) => (
                  <Col key={match.uniqueId} lg={4} md={6} className="mb-3">
                    <Card className="h-100 shadow-sm position-relative overflow-hidden">
                      <div
                        style={{
                          position: "absolute",
                          top: 20,
                          right: -35,
                          transform: "rotate(45deg)",
                          backgroundColor: "#f66242",
                          padding: "2px 0px",
                          color: "white",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          zIndex: 1,
                          boxShadow: "0 3px 10px rgba(0,0,0,0.1)",
                          textAlign: "center",
                          width: "120px",
                        }}
                      >
                        {match.flag} {match.leagueName}
                      </div>
                      <Card.Body style={{ paddingTop: "1.5rem" }}>
                        <div className="text-center mb-3">
                          <h6 className="mb-1">
                            {match.home_team} vs {match.away_team}
                          </h6>
                          <small className="text-muted">{match.date}</small>
                        </div>

                        <Row className="align-items-center mb-3">
                          <Col>
                            <Form.Control
                              type="number"
                              min="0"
                              size="sm"
                              value={
                                predictions[match.uniqueId]?.homeScore ?? ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  match.uniqueId,
                                  "homeScore",
                                  e.target.value
                                )
                              }
                              disabled={submittedPredictions[match.uniqueId]}
                            />
                          </Col>
                          <Col xs="auto">-</Col>
                          <Col>
                            <Form.Control
                              type="number"
                              min="0"
                              size="sm"
                              value={
                                predictions[match.uniqueId]?.awayScore ?? ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  match.uniqueId,
                                  "awayScore",
                                  e.target.value
                                )
                              }
                              disabled={submittedPredictions[match.uniqueId]}
                            />
                          </Col>
                        </Row>

                        <Button
                          variant={
                            submittedPredictions[match.uniqueId]
                              ? "outline-success"
                              : "primary"
                          }
                          size="sm"
                          className="w-100"
                          onClick={() => handleSubmitMatch(match.uniqueId)}
                          disabled={submittedPredictions[match.uniqueId]}
                        >
                          {submittedPredictions[match.uniqueId]
                            ? "Pronostic soumis ✓"
                            : "Soumettre"}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Tab>

          <Tab eventKey="all" title="Tous les Pronostics">
            <div className="d-flex gap-3 mb-3">
              <div className="position-relative flex-grow-1">
                <Form.Control
                  type="text"
                  placeholder="Rechercher une équipe..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pe-4"
                />
                <Search
                  className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                  size={18}
                />
              </div>
            </div>

            {/* Ajout des filtres de date */}
            <div className="d-flex gap-3 mb-3">
              <Form.Control
                type="date"
                placeholder="Date de début"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Form.Control
                type="date"
                placeholder="Date de fin"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div
              className="bg-light p-3 rounded"
              style={{ maxHeight: "calc(100vh - 400px)", overflowY: "auto" }}
            >
              <Row>
                {filteredPronostics.map((pronostic) => (
                  <Col key={pronostic.id} lg={4} md={6} className="mb-3">
                    <Card className="h-100 shadow-sm position-relative overflow-hidden">
                      <div
                        style={{
                          position: "absolute",
                          top: 20,
                          right: -35,
                          transform: "rotate(45deg)",
                          backgroundColor: "#f66242",
                          padding: "2px 0px",
                          color: "white",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          zIndex: 1,
                          boxShadow: "0 3px 10px rgba(0,0,0,0.1)",
                          textAlign: "center",
                          width: "120px",
                        }}
                      >
                        {pronostic.league}
                      </div>

                      <Card.Body style={{ paddingTop: "1.5rem" }}>
                        <div className="text-center mb-3">
                          <h6 className="mb-1">
                            {pronostic.homeTeam} vs {pronostic.awayTeam}
                          </h6>
                          <small className="text-muted">
                            {pronostic.matchDate}
                          </small>
                        </div>

                        <div className="text-center mb-3">
                          <h4>
                            {pronostic.homeScore} - {pronostic.awayScore}
                          </h4>
                        </div>

                        <div className="text-center">
                          <span className="badge bg-secondary">
                            <User size={14} className="me-1" />
                          </span>
                        </div>
                        <div className="text-center">
                          {isUserPronostic(pronostic) ? (
                            <span>{decodeToken(token).username}</span>
                          ) : (
                            <span style={{ color: "red" }}>Confidentiel</span>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Tab>

          <Tab eventKey="ranking" title="Classement">
            <div
              className="bg-light p-3 rounded"
              style={{ maxHeight: "calc(100vh - 350px)", overflowY: "auto" }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Utilisateur</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>
                  {rankings.map((ranking, index) => (
                    <tr key={`${ranking.username}-${index}`}>
                      <td>
                        {index === 0 ? (
                          <span className="badge bg-warning">🥇</span>
                        ) : index === 1 ? (
                          <span className="badge bg-secondary">🥈</span>
                        ) : index === 2 ? (
                          <span className="badge bg-danger">🥉</span>
                        ) : (
                          index + 1
                        )}
                      </td>
                      <td>{ranking.username}</td>
                      <td>{ranking.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab>
        </Tabs>
      </div>

      <style>
        {`
          .table {
            border-collapse: separate;
            border-spacing: 0 10px;
          }

          .table th,
          .table td {
            border: 1px solid #dee2e6;
            padding: 10px;
          }

          .table th {
            background-color: #f8f9fa;
          }

          .badge {
            font-size: 1.2em;
            padding: 0.5em 0.75em;
          }
        `}
      </style>
    </div>
  );
};

export default PronosticForm;
